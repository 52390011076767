import { useState } from 'react'
import {
  Table,
  TableColumnType,
  PreviewType,
  showSuccessMessage,
} from '@eltoro-ui/components'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  GoBackButton,
  LibraryHeader,
  PreviewPanel,
  MainWithSide,
  CreativeUpload,
  CreativeGenModal,
  EmptyField,
  LibraryTableWrapper,
  PageContent,
  LibraryTableAttachButton,
  LinkButton,
  Card,
  AdTagCreate,
  NativeBannerCreate,
  CreativeThumbnail,
  OrgNotes,
} from 'Components'
import { checkIfCreative } from 'Helpers'
import {
  LibraryContextProvider,
  useLibraryContext,
  useAppContext,
  useCampaignContext,
} from 'Contexts'
import {
  Creativeservicev1Creative,
  Creativeservicev1Type,
  Creativeservicev1AdType,
} from 'next-gen-sdk'
import { CreativesTableColumns } from './CreativesTableColumns'
import { ETIcon } from 'Assets'
import './CreativesLibraryPage.scss'

type CreativesLibraryPropsType = {
  className?: string
  selectedCreatives?: Creativeservicev1Creative[]
  addToCreativeSet?: (creative: Creativeservicev1Creative) => void
  removeFromCreativeSet?: (id: string) => void
  modalPreview?: boolean
  windowed?: boolean
  emptyState?: React.ReactNode
  filterByType?: Creativeservicev1Type[]
  filterByAdType?: Creativeservicev1AdType[]
}

export type PreviewFileWithId = {
  fileData: PreviewType
  id: string
}

export const typeGetter = (ty: string): Creativeservicev1Type => {
  if (ty === 'banner') {
    return 'CREATIVE_TYPE_BANNER'
  }
  if (ty === 'video') {
    return 'CREATIVE_TYPE_VIDEO'
  }
  if (ty === 'ott') {
    return 'CREATIVE_TYPE_OTT'
  }
  if (ty === 'html5') {
    return 'CREATIVE_TYPE_HTML5'
  }
  return 'CREATIVE_TYPE_UNSPECIFIED'
}

export const CreativesLibrary = ({
  className = '',
  selectedCreatives,
  addToCreativeSet,
  removeFromCreativeSet,
  modalPreview,
  windowed,
  emptyState,
  filterByType,
  filterByAdType,
}: CreativesLibraryPropsType) => {
  const [creativeGenIsOpen, setCreativeGenIsOpen] = useState(false)
  const [selected, setSelected] = useState<string>()

  const { currentOrg, isAdmin } = useAppContext()

  const {
    currentData,
    handleClearSelectedItem,
    selectedItem: selectedCreative,
    handleRowClick,
    pagination: { refetch, loading },
    setCurrentFolderPath,
    currentFolderPath,
    handleTableFilter,
    handleSort,
    childOrgsOnly,
  } = useLibraryContext()
  const { pathname } = useLocation()
  const isStandAlone = pathname.includes('/campaigns/creative-library')

  const { launchFreshCampaign } = useCampaignContext()

  const history = useNavigate()
  // const showPreviewAsModal = modalPreview || useWindowWidth() <= 1500

  const baseColumns = (): TableColumnType<Creativeservicev1Creative>[] => {
    let columns = CreativesTableColumns()
    if (addToCreativeSet && removeFromCreativeSet)
      columns = CreativesTableColumns().filter(
        (col) => col.path !== 'orderLines',
      )
    // If there is a strict type filter, remove the basic type filter
    if (
      (filterByType && filterByType.length > 0) ||
      (filterByAdType && filterByAdType.length > 0)
    ) {
      columns = columns.map((col) => {
        if (col.path === 'type') return { ...col, removeFilter: true }
        return col
      })
    }
    return columns
  }
  const childOrgsForFilter = currentOrg?.childOrgs
    ?.map((co) => {
      return { label: co.name || '', value: co.id || '' }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const columns: TableColumnType<Creativeservicev1Creative>[] = [
    ...baseColumns(),
    // Adding child org column
    ...(childOrgsOnly
      ? [
          {
            path: 'child_orgs',
            label: 'Child Org',
            filterOn: childOrgsForFilter || [],
            removeSort: true,
            RowCell: (row) => {
              const matchingChildOrg = currentOrg?.childOrgs?.find(
                (org) => row.orgId === org.id,
              )
              if (matchingChildOrg?.name && matchingChildOrg?.id)
                return (
                  row.orgId && (
                    <LinkButton
                      text={matchingChildOrg?.name}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (matchingChildOrg.id)
                          history(`orgs/${matchingChildOrg.id}/org-settings`)
                      }}
                      truncate
                    />
                  )
                )
              return null
            },
          } as TableColumnType<Creativeservicev1Creative>,
        ]
      : []),
    // Folder name
    ...(currentFolderPath.length === 1
      ? [
          {
            path: 'folder',
            label: 'Folder Name',
            filterOn: 'string',
            RowCell: (row) => {
              if (!row.folder) return <EmptyField />
              return (
                <LinkButton
                  truncate
                  text={row.folder}
                  onClick={(e) => {
                    e.stopPropagation()
                    setCurrentFolderPath(['', row.folder || ''])
                  }}
                />
              )
            },
          } as TableColumnType<Creativeservicev1Creative>,
        ]
      : []),
    // Adding buttons for create campaign view
    ...(addToCreativeSet || removeFromCreativeSet
      ? [
          {
            path: '',
            label: '',
            alignment: 'right',
            removeFilter: true,
            removeSort: true,
            RowCell: (row) => {
              const isAttached =
                row?.id &&
                selectedCreatives
                  ?.map((creative) => creative.id)
                  .includes(row.id)
              return (
                <LibraryTableAttachButton
                  isAttached={!!isAttached}
                  onClick={(e) => {
                    // Prevent row click
                    e?.stopPropagation()
                    if (row?.id) {
                      if (!isAttached && addToCreativeSet) addToCreativeSet(row)
                      if (isAttached && removeFromCreativeSet)
                        removeFromCreativeSet(row.id)
                    }
                  }}
                  allowDetach={!!removeFromCreativeSet}
                />
              )
            },
          } as TableColumnType<Creativeservicev1Creative>,
        ]
      : []),
  ]

  const tableProps = {
    columns,
    rows: currentData as Creativeservicev1Creative[],
    onClick: handleRowClick,
    clicked: selectedCreative as Creativeservicev1Creative,
    onSort: handleSort,
    onFilter: handleTableFilter,
  }

  // Allow the custom emptyState for in campaign creation
  if (!loading && currentData.length === 0 && emptyState) {
    return <div className="CreativesLibraryPage__not-found">{emptyState}</div>
  }

  const handleOnUseInCampaign = (creative: Creativeservicev1Creative) => {
    // if pathname contains creative library:
    if (isStandAlone && creative && checkIfCreative(creative)) {
      // clear+add to campaign context
      launchFreshCampaign({ creative })

      // redirect to create campaign
      const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
      history(`/audiences/create${query}`)
      showSuccessMessage(
        creative.name || 'SUCCESS',
        <div className="flex gap-4">
          {creative.type !== 'CREATIVE_TYPE_HTML5' &&
          creative.files &&
          creative.files?.length > 0 ? (
            creative.files?.map((file) => (
              <CreativeThumbnail key={file.id} file={file} size="s" />
            ))
          ) : (
            <>
              {(creative.type === 'CREATIVE_TYPE_AD_TAG' && (
                <ETIcon icon="ad-tag" />
              )) ||
                (creative.type === 'CREATIVE_TYPE_VAST_TAG' && (
                  <ETIcon icon="vast-tag" />
                )) ||
                (creative.type === 'CREATIVE_TYPE_HTML5' && (
                  <ETIcon icon="html5" />
                ))}
            </>
          )}
          Your creative will be added to your new campaign after set up
        </div>,
      )
      return
    }
    if (selectedCreative?.id && removeFromCreativeSet && addToCreativeSet) {
      const isAttached =
        selectedCreative?.id &&
        selectedCreatives
          ?.map((creative) => creative.id)
          .includes(selectedCreative.id)
      if (isAttached) removeFromCreativeSet(selectedCreative.id)
      if (!isAttached)
        addToCreativeSet(selectedCreative as Creativeservicev1Creative)
      handleClearSelectedItem()
    }
  }

  return (
    <>
      <PageContent className={`CreativesLibraryPage ${className}`}>
        <MainWithSide
          onSideClose={() => handleClearSelectedItem()}
          showAsModal={modalPreview}
          side={
            selectedCreative && (
              <PreviewPanel
                previewItem={selectedCreative as Creativeservicev1Creative}
                onUseInCampaign={() =>
                  checkIfCreative(selectedCreative) &&
                  handleOnUseInCampaign(selectedCreative)
                }
                isAttached={
                  !!(
                    selectedCreative?.id &&
                    selectedCreatives
                      ?.map((creative) => creative.id)
                      .includes(selectedCreative.id)
                  )
                }
              />
            )
          }
        >
          {isStandAlone && (
            <LibraryHeader
              type="creative"
              onCreateNew={() => setCreativeGenIsOpen(true)}
              setSelected={setSelected}
              closePreviewPanel={() => handleClearSelectedItem()}
            />
          )}
          {isAdmin && <OrgNotes />}

          {(selected === 'video' ||
            selected === 'banner' ||
            selected === 'ott' ||
            selected === 'html5') &&
            currentOrg?.id && (
              <CreativeUpload
                currentOrg={currentOrg?.id}
                onClose={() => {
                  setSelected(undefined)
                  refetch()
                }}
                uploadType={typeGetter(selected)}
              />
            )}
          {selected === 'native' && (
            <Card className="CardUploader nativeBanner relative mt-0 flex gap-2 !p-0">
              <GoBackButton
                onClick={() => setSelected(undefined)}
                position="top-right"
                classWrap="NativeBanner--goback"
              />
              <NativeBannerCreate
                onUseInNewCampaign={handleOnUseInCampaign}
                onBackToLibrary={() => {
                  setSelected('')
                  refetch()
                }}
              />
            </Card>
          )}
          {selected === 'vast-ad-tag' && (
            <Card className="CardUploader VastAdTag relative !mt-3 !px-0">
              <GoBackButton
                onClick={() => setSelected(undefined)}
                position="top-right"
                classWrap="VastAdTag--goback"
              />
              <AdTagCreate
                type="video"
                isInLibrary
                refreshLibrary={refetch}
                onUseInNewCampaign={handleOnUseInCampaign}
              />
            </Card>
          )}
          {selected === 'ad-tag' && (
            <Card className="AdTag relative !px-0 !pt-0">
              <GoBackButton
                onClick={() => setSelected(undefined)}
                position="top-right"
                classWrap="AdTag--goback"
              />
              <AdTagCreate
                type="banner"
                isInLibrary
                refreshLibrary={refetch}
                onUseInNewCampaign={handleOnUseInCampaign}
              />
            </Card>
          )}
          {!selected && (
            <LibraryTableWrapper type="creatives">
              {windowed ? (
                <div className="CreativesLibraryPage__table-view">
                  <Table {...tableProps} />
                </div>
              ) : (
                <Table {...tableProps} />
              )}
            </LibraryTableWrapper>
          )}
        </MainWithSide>
      </PageContent>
      {creativeGenIsOpen && currentOrg?.id && (
        <CreativeGenModal
          defaultType="banner"
          currentOrg={currentOrg.id}
          onCloseOrOffClick={(bool) => setCreativeGenIsOpen(bool)}
          onCreate={() => refetch()}
        />
      )}
    </>
  )
}

export const CreativesLibraryPage = (props: CreativesLibraryPropsType) => (
  <LibraryContextProvider
    type="creatives"
    filterByType={props.filterByType}
    filterByAdType={props.filterByAdType}
  >
    <CreativesLibrary {...props} />
  </LibraryContextProvider>
)
