import { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import {
  Campaignservicev1AdType,
  Campaignservicev1Creative,
  Creativeservicev1Creative,
  V1CreativeFile,
} from 'next-gen-sdk'
import { Fa, Popover } from '@eltoro-ui/components'
import { ETIcon } from 'Assets'
import { styleTailwind } from 'Helpers'
import { CreativePreview, CreativeThumbnail } from 'Components'
import { ApiFactory } from 'apiFactory'
import { useCampaignContext } from 'Contexts'
import { CreativePopover } from './CreativePopover'

export const IconThumbnailWrapper = styleTailwind(
  'div',
  'IconThumbnailWrapper h-full w-full flex items-center justify-center bg-primary-100 rounded',
)

export const CreativesCardItem = ({
  orgId,
  creatives,
  adType,
  readyToDeploy,
}: {
  orgId?: string
  creatives?: Campaignservicev1Creative[]
  adType?: Campaignservicev1AdType
  readyToDeploy?: boolean
}) => {
  const [showAllCreatives, setShowAllCreatives] = useState(false)
  // prettier-ignore
  const [
      previewedFile,
      setPreviewedFile,
    ] = useState<V1CreativeFile>()
  const contentRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([contentRef, buttonRef], () => setShowAllCreatives(false))
  const hasFetchedFirstCreative = useRef('')
  // prettier-ignore
  const [
    thumbnailCreative,
    setThumbnailCreative,
  ] = useState<Creativeservicev1Creative>()
  const { attachedCreatives } = useCampaignContext()

  useEffect(() => {
    const getFirstCreative = async () => {
      if (!creatives?.[0]?.id || !orgId) return
      const existing = attachedCreatives?.find((c) => c.id === creatives[0].id)
      if (existing) {
        setThumbnailCreative(existing)
        hasFetchedFirstCreative.current = existing?.id || ''
        return
      }
      const { creativeServiceAPI } = ApiFactory(
        localStorage.getItem('eltoro_token'),
      )
      const res = await creativeServiceAPI?.advertisingPlatformServiceGetCreative(
        creatives[0].id,
        orgId,
      )
      if (res) {
        setThumbnailCreative(res)
        hasFetchedFirstCreative.current = res.id || ''
      }
    }
    if (hasFetchedFirstCreative.current !== creatives?.[0]?.id) {
      getFirstCreative()
    }
  }, [creatives])

  const olCreativeCount = (creatives || []).length

  if (!olCreativeCount) {
    return (
      <div className="CreativesCardItem__missing-creative flex">
        <div
          className="CreativesCardItem__missing-creative-placeholder bg-warning-100 mx-2 flex flex-1 flex-col items-center py-2"
          data-tooltip="bottom"
          aria-label="You're missing creatives. Go back to add to all order lines or select 'Edit' checkbox."
        >
          <Fa className="text-warning" icon="exclamation" size={4} />
          <span className="text-xs">Missing creative</span>
        </div>
      </div>
    )
  }
  if (!thumbnailCreative && !hasFetchedFirstCreative.current)
    return (
      <div className="CreativesCardItem__loading-creatives flex h-full w-full animate-pulse flex-col items-center justify-start">
        <Fa
          className="CreativesCardItem__loading-icon !text-primary mr-6"
          icon="image"
          size={6}
        />
      </div>
    )

  const file = thumbnailCreative?.files?.[0]

  const getFileThumbnail = (
    file: V1CreativeFile,
    cover: boolean,
    onClick?: () => void,
  ) => (
    <CreativeThumbnail file={file} onClick={onClick} size="m" cover={cover} />
  )

  return (
    <div
      className="CreativesCardItem flex h-full flex-col items-center gap-1"
      ref={buttonRef}
      data-tooltip="right"
      aria-label="Click to view thumbnail, creative type"
    >
      <Popover
        className="CreativeCardItem__thumbs"
        position={['right', 'right']}
        align="center"
        attachId="MainAppContainer"
        content={
          <CreativePopover
            contentRef={contentRef}
            orgId={orgId}
            adType={adType}
            creatives={creatives}
            getFileThumbnail={getFileThumbnail}
            setPreviewedFile={setPreviewedFile}
            setShowAllCreatives={setShowAllCreatives}
          />
        }
        isOpen={showAllCreatives}
      >
        <button
          className="CreativesCardItem__thumbnail-wrapper relative max-w-max"
          onClick={() => setShowAllCreatives((prev) => !prev)}
        >
          {file && thumbnailCreative?.type !== 'CREATIVE_TYPE_HTML5'
            ? getFileThumbnail(file, true)
            : (() => {
                if (thumbnailCreative?.type === 'CREATIVE_TYPE_HTML5')
                  return (
                    <IconThumbnailWrapper>
                      <ETIcon className="HTML5Icon" icon="html5" />
                    </IconThumbnailWrapper>
                  )

                if (
                  thumbnailCreative?.type &&
                  (thumbnailCreative.type === 'CREATIVE_TYPE_AD_TAG' ||
                    thumbnailCreative.type === 'CREATIVE_TYPE_VAST_TAG')
                )
                  return (
                    <IconThumbnailWrapper>
                      <ETIcon icon="ad-tag" />
                    </IconThumbnailWrapper>
                  )
                return null
              })()}
          <div className="CreativesCardItem__count-badge text-l bg-gray absolute right-[-0.5rem] bottom-[-0.5rem] flex h-6 w-6 items-center justify-center rounded-full p-1 text-center font-bold shadow-lg">
            {olCreativeCount}
          </div>
        </button>
      </Popover>

      {readyToDeploy && (
        <div className="CreativesCardItem__deploy-badge bottom-ribbon">
          <div className="gradient flex items-center justify-center">
            <Fa icon="check" size={1} className="-ml-2 scale-75" />
            <p className="!text-m whitespace-nowrap font-normal">
              Ready to deploy
            </p>
          </div>
        </div>
      )}
      {previewedFile && (
        <CreativePreview
          file={previewedFile}
          offClick={() => setPreviewedFile(undefined)}
        />
      )}
    </div>
  )
}
