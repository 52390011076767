import { useEffect, useState, useRef } from 'react'
import { AutoScroller, Button, Fa, TextHeader } from '@eltoro-ui/components'
import { Campaignservicev1OrderLine, V1StatsResponse } from 'next-gen-sdk'
import { useNavigate } from 'react-router-dom'
import {
  getCreativeFaETIcons,
  getDaysBetween,
  getDurationHours,
  rate,
  rateCeil,
  styleTailwind,
} from 'Helpers'
import { LinkButton, OLTableUpdateRow } from 'Components'
import { useAppContext } from 'Contexts'
import { ETIcon } from 'Assets'
import { ExpansionHeader } from '../ExpansionHeader'
import { OrderlinePaceIndicator } from '../OrderlinePaceIndicator'
import { OrderlineStateIndicator } from '../OrderlineStateIndicator'
import { OrderlineStats } from '../OrderlineStats'
import { OrderlineCreativeDisplay, ServeStatsTable } from './components'
import { OLPauseNoteModal } from 'Components/OLCard/OLPauseNoteModal'
import './OrderlineContainer.scss'
import { dayjs } from 'Tools/dateUtils'

const DividerRule = styleTailwind(
  'hr',
  'divider-rule border-t-thin border-dotted border-tint-gray-400 my-3',
)

export const OrderlineContainer = ({
  orderline,
  stats,
  handleCollapse,
  handleOpenCampaign,
  handleUpdateRow,
  collapseIconName,
}: {
  stats?: V1StatsResponse
  orderline: Campaignservicev1OrderLine
  handleCollapse: () => void
  handleOpenCampaign?: (
    campaignId: string,
    orderLine: Campaignservicev1OrderLine,
  ) => void
  handleUpdateRow?: OLTableUpdateRow
  collapseIconName?: string
}) => {
  // setting order line in state so it can be updated by the children and changes are reflected
  // prettier-ignore
  const [
    currentOrderLine,
    setCurrentOrderLine,
  ] = useState<Campaignservicev1OrderLine>(orderline)
  const [refreshOL, setRefreshOL] = useState(false)
  const [showPauseModal, setShowPauseModal] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const {
    campaignServiceApi,
    roles,
    isOnHold,
    isAdmin,
    isReadOnly,
  } = useAppContext()

  const handlePause = (note: string) => {
    if (!currentOrderLine.id || !currentOrderLine.orgId || !campaignServiceApi)
      return
    setDisabled(true)
    campaignServiceApi
      .advertisingPlatformServicePauseOrderLine(currentOrderLine.id, {
        orgId: currentOrderLine.orgId,
        note: {
          content: `PAUSE: ${note}`,
        },
      })
      .then((updated) => {
        setShowPauseModal(false)
        setRefreshOL(true)
      })
  }
  const handlePlay = () => {
    if (!campaignServiceApi || !currentOrderLine.id || !currentOrderLine.orgId)
      return
    setDisabled(true)
    campaignServiceApi
      .advertisingPlatformServicePlayOrderLine(currentOrderLine.id, {
        note: {
          content: 'Played via Order Line Dashboard',
        },
        orgId: currentOrderLine.orgId,
      })
      .then((updated) => {
        setCurrentOrderLine(updated)
        setRefreshOL(true)
      })
  }
  const disabledByStatus =
    currentOrderLine.status === 'ORDERLINE_STATUS_DEPLOYING' ||
    currentOrderLine.status === 'ORDERLINE_STATUS_QUEUED'

  useEffect(() => {
    if (
      currentOrderLine.orgId &&
      currentOrderLine.id &&
      campaignServiceApi &&
      refreshOL
    ) {
      campaignServiceApi
        .advertisingPlatformServiceGetOrderLine(
          currentOrderLine.id,
          currentOrderLine.orgId,
        )
        .then(setCurrentOrderLine)
        .finally(() => setRefreshOL(false))
    }
  }, [
    currentOrderLine.id,
    currentOrderLine.orgId,
    campaignServiceApi,
    refreshOL,
  ])

  const creativeType = currentOrderLine.creatives?.[0]?.type

  const pastStartDate =
    currentOrderLine.startTime &&
    currentOrderLine.startTime.getTime() <= new Date().getTime()

  const hasDeployed = [
    'ORDERLINE_STATUS_DEPLOYED',
    'ORDERLINE_STATUS_SERVING',
    'ORDERLINE_STATUS_COMPLETED',
    'ORDERLINE_STATUS_ARCHIVED',
    'ORDERLINE_STATUS_ERRORED_AND_SERVING',
    'ORDERLINE_STATUS_ERRORED',
    'ORDERLINE_STATUS_PAUSED',
  ].includes(currentOrderLine.status || '')

  const PaceComponentSwitcher = () => {
    if (!isAdmin) {
      return <></>
    }
    if (!orderline.startTime || !orderline.endTime || !hasDeployed) {
      return (
        <OrderlinePaceIndicator
          className="whitespace-nowrap pl-3"
          pace="not started"
        />
      )
    }
    if (
      (orderline.status && orderline.status === 'ORDERLINE_STATUS_PAUSED') ||
      (orderline.state && orderline.state === 'ORDERLINE_STATE_PAUSED')
    ) {
      return (
        <OrderlinePaceIndicator
          className="whitespace-nowrap pl-3"
          pace="paused"
        />
      )
    }
    const isBeforeEndDate = dayjs().tz().isBefore(dayjs.tz(orderline.endTime))
    const durationDays = isBeforeEndDate
      ? getDaysBetween(orderline?.startTime, new Date())
      : getDaysBetween(orderline?.startTime, orderline.endTime)
    const durationHours = durationDays * 24
    const hoursServed = isBeforeEndDate
      ? getDurationHours(orderline?.startTime, new Date())
      : getDurationHours(orderline?.startTime, orderline?.endTime)

    const todayServedImps = 0
    const orderedImps = orderline.impressions || 0
    const servedImpressions = Number(stats?.totals?.imps || 0)

    const perHrImpServed =
      hoursServed > 0
        ? rateCeil(servedImpressions - todayServedImps, hoursServed)
        : 0
    const perHrImpTotal =
      durationHours > 0 ? rateCeil(orderedImps, durationHours) : 0

    const impServedRatio = rate(perHrImpServed, perHrImpTotal)
    //  over pace
    if (servedImpressions === 0 && impServedRatio === 0)
      return <OrderlinePaceIndicator pace="over" />
    //  on pace
    if (
      servedImpressions > 0 &&
      impServedRatio >= 0.9 &&
      impServedRatio <= 1.05
    )
      return <OrderlinePaceIndicator pace="on" />
    //  under pace
    if (servedImpressions > 0 && impServedRatio < 0.9)
      return <OrderlinePaceIndicator pace="under" />
    return (
      <OrderlinePaceIndicator
        className="whitespace-nowrap pl-3"
        pace="not started"
      />
    )
  }

  const history = useNavigate()
  return (
    <AutoScroller shouldScroll={!!handleCollapse} scrollToTop>
      <div className="OrderlineContainer border-t-thin border-tint-gray-200 pb-6">
        <ExpansionHeader
          type="orderLine"
          icon={creativeType ? getCreativeFaETIcons(creativeType) : undefined}
          name={currentOrderLine.name || ''}
          isCollapsed={false}
          handleCollapse={handleCollapse}
          orderline={currentOrderLine}
          collapseIconName={collapseIconName}
          setRefreshOL={setRefreshOL}
          handleUpdateRow={handleUpdateRow}
        />
        <div className="OrderlineContainer__layout-wrapper border-t-thin border-tint-gray-200 laptopsmall:flex-wrap laptopsmall:flex mt-2 items-start gap-6 py-6">
          {/* Left side */}
          <div className="OrderlineContainer__LeftColumn laptopsmall:flex-1">
            <div className="OrderlineContainer__details">
              <div className="OrderLine__Status-Pace desktop:justify-between laptopsmall:flex-wrap laptopsmall:gap-y-2 flex">
                {currentOrderLine.state !== undefined && (
                  <>
                    <div className="IndicatorRow desktop:justify-between laptopsmall:flex-wrap flex flex-1 items-center gap-y-2">
                      {(currentOrderLine.status ===
                        'ORDERLINE_STATUS_DEPLOYED' ||
                        currentOrderLine.status ===
                          'ORDERLINE_STATUS_PAUSED') &&
                      currentOrderLine.state === 'ORDERLINE_STATE_APPROVED' ? (
                        <OrderlineStateIndicator
                          state={
                            currentOrderLine.status ===
                            'ORDERLINE_STATUS_PAUSED'
                              ? 'ORDERLINE_STATE_PAUSED'
                              : 'ORDERLINE_STATE_ACTIVE'
                          }
                          reason={
                            currentOrderLine.status ===
                            'ORDERLINE_STATUS_PAUSED'
                              ? 'Order line is paused, and the start date is in the future'
                              : 'Order line has been deployed, but is not serving yet'
                          }
                        />
                      ) : (
                        <OrderlineStateIndicator
                          className="whitespace-nowrap"
                          state={currentOrderLine.state}
                          reason={currentOrderLine.reason}
                        />
                      )}
                      {PaceComponentSwitcher()}
                    </div>
                    {((!isReadOnly &&
                      !isOnHold &&
                      !hasDeployed &&
                      ![
                        'ORDERLINE_STATE_REJECTED',
                        'ORDERLINE_STATE_PAUSED',
                      ].includes(currentOrderLine.state) &&
                      !!currentOrderLine.campaign?.id) ||
                      (isAdmin &&
                        currentOrderLine.state !==
                          'ORDERLINE_STATE_REJECTED')) && (
                      <Button
                        className="ContinueEditButton"
                        rounded
                        onClick={() => {
                          const editable = !!currentOrderLine.campaign?.id
                          if (editable) {
                            return history(
                              `/campaigns/edit/${currentOrderLine.campaign?.id}/settings?org_id=${currentOrderLine.orgId}`,
                            )
                          }
                          return false
                        }}
                      >
                        Edit Order Line
                      </Button>
                    )}
                    {!isReadOnly &&
                      !isOnHold &&
                      ['ORDERLINE_STATE_REJECTED'].includes(
                        currentOrderLine.state,
                      ) && (
                        <Button
                          rounded
                          className="FixButton"
                          onClick={() => {
                            const editable = !!currentOrderLine.campaign?.id
                            if (editable) {
                              return history(
                                `/campaigns/edit/${currentOrderLine.campaign?.id}/settings?${currentOrderLine.orgId}`,
                              )
                            }
                            return false
                          }}
                        >
                          Fix Order Line
                        </Button>
                      )}
                    {(['ORDERLINE_STATE_ACTIVE'].includes(
                      currentOrderLine?.state,
                    ) ||
                      (currentOrderLine.status &&
                        ['ORDERLINE_STATUS_DEPLOYED'].includes(
                          currentOrderLine?.status,
                        ))) && (
                      <Button
                        iconLeft={<Fa icon="pause" size={1} />}
                        className="OrderlineContainer__pause-btn bg-warning border-warning text-base"
                        size="s"
                        onClick={() => setShowPauseModal(true)}
                        disabled={
                          disabled ||
                          orderline.locked ||
                          disabledByStatus ||
                          (roles && roles.includes('nextgen_read_only'))
                        }
                      >
                        Pause
                      </Button>
                    )}
                    {(['ORDERLINE_STATE_PAUSED'].includes(
                      currentOrderLine?.state,
                    ) ||
                      (currentOrderLine.status &&
                        ['ORDERLINE_STATUS_PAUSED'].includes(
                          currentOrderLine?.status,
                        ))) && (
                      <Button
                        iconLeft={<Fa icon="play" size={1} />}
                        size="s"
                        className="w-max"
                        onClick={handlePlay}
                        disabled={
                          disabled ||
                          orderline.locked ||
                          disabledByStatus ||
                          (roles && roles.includes('nextgen_read_only'))
                        }
                      >
                        Play
                      </Button>
                    )}
                  </>
                )}
                {showPauseModal && (
                  <OLPauseNoteModal
                    handlePause={handlePause}
                    cancelFunc={setShowPauseModal}
                  />
                )}
              </div>
              <div className="OrderLine__campaign-name-id flex flex-col justify-between pt-4 pl-1">
                {orderline?.campaign?.name && handleOpenCampaign && (
                  <div className="flex items-start gap-1 pb-1">
                    <div className="CampaignLabel font-bold leading-none">
                      Campaign:
                    </div>
                    <LinkButton
                      className="Campaign__text-input text-ml w-auto break-words text-right leading-none"
                      text={orderline?.campaign.name}
                      onClick={() =>
                        orderline?.campaign?.id &&
                        handleOpenCampaign(
                          orderline?.campaign.id,
                          currentOrderLine,
                        )
                      }
                    />
                  </div>
                )}
                <div
                  className={`Orderline__id ${
                    currentOrderLine &&
                    !currentOrderLine.freeImpressions &&
                    'pb-3'
                  }`}
                >
                  <strong>ID:</strong>
                  <span className="w-auto text-right">
                    {currentOrderLine.id}
                  </span>
                </div>
                {((currentOrderLine && currentOrderLine.freeImpressions) ||
                  (roles && roles.includes('nextgen_admin'))) && (
                  <div className="Orderline__freeImps flex gap-2 pb-3">
                    <strong>Free Impressions:</strong>
                    <span className="w-auto text-right">
                      {currentOrderLine.freeImpressions || 0}
                    </span>
                  </div>
                )}
                {currentOrderLine.jobId && (
                  <div className="Orderline__job-id">
                    <strong>Job ID:</strong>
                    <span className="w-auto text-right">
                      {currentOrderLine.jobId || '(None)'}
                    </span>
                  </div>
                )}
                {currentOrderLine.poId && (
                  <div className="Orderline__po-id">
                    <strong>P.O. #:</strong>
                    <span className="w-auto text-right">
                      {currentOrderLine.poId || '(None)'}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <DividerRule />
            {/* Budget/Days left/Impressions served/targets */}
            <>
              <OrderlineStats
                orderline={currentOrderLine}
                stats={stats}
                setCurrentOrderLine={setCurrentOrderLine}
                setRefreshOL={setRefreshOL}
              />
              <DividerRule />
            </>
            {!hasDeployed && pastStartDate && (
              <div className="OrderlineContainer__not-started">
                <ETIcon className="min-w-[4rem]" icon="chart" />
                <div className="flex flex-col leading-none">
                  <TextHeader type={5}>
                    <strong className="leading-none">
                      Performance Information Unavailable
                    </strong>
                  </TextHeader>
                  <p className="pt-2">
                    This order line hasn&apos;t been deployed yet.
                  </p>
                </div>
              </div>
            )}
            {!pastStartDate && !hasDeployed && (
              <div className="OrderlineContainer__not-started">
                <ETIcon className="min-w-[4rem]" icon="chart" />
                <div className="flex flex-col pt-3 leading-none">
                  <TextHeader type={5}>
                    <strong>Performance Information Unavailable</strong>
                  </TextHeader>
                  <p className="pt-2">
                    This order line hasn&apos;t been deployed yet.
                  </p>
                </div>
              </div>
            )}
            {!pastStartDate && hasDeployed && (
              <div className="OrderlineContainer__not-started">
                <ETIcon className="min-w-[4rem]" icon="chart" />
                <div className="flex flex-col pt-3 leading-none">
                  <TextHeader type={5}>
                    <strong>Performance Information Unavailable</strong>
                  </TextHeader>
                  <p className="pt-2">
                    Hang tight! Your order line starts on{' '}
                    {currentOrderLine.startTime?.toLocaleDateString()}.
                  </p>
                </div>
              </div>
            )}
            {/* Impressions/Clicks table */}
            {pastStartDate && hasDeployed && stats && (
              <ServeStatsTable orderline={orderline} stats={stats} />
            )}
          </div>
          {/* Right side */}
          <OrderlineCreativeDisplay
            orderline={currentOrderLine}
            setCurrentOrderLine={setCurrentOrderLine}
          />
        </div>
      </div>
    </AutoScroller>
  )
}
