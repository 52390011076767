import { Button, Fa, FormItem } from '@eltoro-ui/components'
import DatePicker from 'react-datepicker'
import { dayjs } from 'Tools/dateUtils'
import classNames from 'classnames'
import { useAppContext } from 'Contexts'
import { WarningMessageText, WarningMessage } from './PolygonMap'
import { TimeFrame } from 'types'

export const Timeframes = ({
  timeFrames,
  setTimeFrames,
  timeframeErrors,
}: {
  timeFrames: TimeFrame[]
  setTimeFrames: React.Dispatch<React.SetStateAction<TimeFrame[]>>
  timeframeErrors: {
    index: number
    error: string[]
  }[]
}) => {
  const { isAdmin, isExternalSales, isInternalSales } = useAppContext()
  const fullDayBuffer = dayjs().tz().subtract(2, 'day').toDate()
  const timeSpan = isAdmin || isExternalSales || isInternalSales ? 12 : 6
  const timeVR = dayjs().tz().subtract(timeSpan, 'month').toDate()

  return (
    <div className="TimeFrames flex flex-col gap-2">
      {timeFrames.map((_, rowIndex) => {
        const rowErrors = timeframeErrors.find((tf) => tf.index === rowIndex)
          ?.error
        const renderDayContents = (
          type: 'start' | 'stop',
          day: number,
          date?: Date,
        ) => {
          const tooltipText = () => {
            if (
              dayjs.tz(date).isBefore(dayjs().tz().subtract(timeSpan, 'month'))
            ) {
              return `Timeframe is limited to the past ${
                timeSpan === 12 ? 'year' : 'six months'
              }`
            }
            if (dayjs.tz(date).isAfter(dayjs().tz().subtract(2, 'day'))) {
              return 'Date must be at least one day ago'
            }
            if (
              type === 'start' &&
              timeFrames[rowIndex].stop &&
              dayjs.tz(date).isAfter(dayjs.tz(timeFrames[rowIndex].stop))
            ) {
              return 'Start date must be before end date'
            }
            if (
              type === 'stop' &&
              timeFrames[rowIndex].start &&
              dayjs.tz(date).isBefore(dayjs.tz(timeFrames[rowIndex].start))
            ) {
              return 'End date must be after start date'
            }
            return undefined
          }
          return (
            <span
              className={tooltipText() ? 'cursor-not-allowed' : undefined}
              aria-label={tooltipText()}
              data-tooltip="top"
            >
              {day}
            </span>
          )
        }
        return (
          <FormItem
            className="Timeframe__date pb-2"
            key={`timeframe-key-${rowIndex}`}
            htmlFor={`multi-start-${rowIndex}`}
            wrapperClassname="flex-col"
            label={
              <div className="Timeframe__label-wrap w-full">
                <div className="TimeFrames__label group flex items-center pt-1 font-bold">
                  Timeframe {rowIndex + 1}:
                  {timeFrames.length > 1 && (
                    <span data-tooltip="bottom" aria-label="Remove Timeframe">
                      <Fa
                        icon="times"
                        size={1}
                        className="invisible text-inherit transition-all duration-100 group-hover:visible"
                        onClick={() => {
                          setTimeFrames((oldArr) => {
                            // if removing last one, just clear it
                            if (oldArr.length === 1) {
                              return [{}]
                            } else {
                              // remove in place
                              return [
                                ...oldArr.slice(0, rowIndex),
                                ...oldArr.slice(rowIndex + 1),
                              ]
                            }
                          })
                        }}
                      />
                    </span>
                  )}
                </div>
                <div className="Timeframe__label-sub flex justify-between pt-1">
                  <p>Start</p>
                  <p>End</p>
                </div>
              </div>
            }
          >
            <div className="Timeframe__datepicker flex gap-2">
              {/* @ts-ignore */}
              <DatePicker
                id={`multi-start-${rowIndex}`}
                onChange={(e) => {
                  if (e) {
                    setTimeFrames((oldArr) => {
                      oldArr[rowIndex].start = e
                      return [...oldArr]
                    })
                  }
                }}
                showTimeSelect
                maxDate={timeFrames[rowIndex].stop || fullDayBuffer}
                minDate={timeVR}
                selected={timeFrames[rowIndex].start}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                placeholderText="Click to set start date"
                className={classNames('text-xs outline-none focus:ring', {
                  'animate-glowwarning text-warning-500':
                    rowErrors?.includes('start') ||
                    rowErrors?.includes('equal_dates'),
                })}
                renderDayContents={(day, date) =>
                  renderDayContents('start', day, date)
                }
              />
              {/* @ts-ignore */}
              <DatePicker
                id={`multi-start-${rowIndex}`}
                onChange={(e) => {
                  if (e) {
                    setTimeFrames((oldArr) => {
                      oldArr[rowIndex].stop = e
                      return [...oldArr]
                    })
                  }
                }}
                showTimeSelect
                maxDate={fullDayBuffer}
                minDate={timeFrames[rowIndex].start || timeVR}
                selected={timeFrames[rowIndex].stop}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                className={classNames('text-xs outline-none focus:ring', {
                  'animate-glowwarning text-warning-500':
                    rowErrors?.includes('stop') ||
                    rowErrors?.includes('equal_dates'),
                })}
                renderDayContents={(day, date) =>
                  renderDayContents('stop', day, date)
                }
              />
            </div>
            {(rowErrors?.includes('start') ||
              rowErrors?.includes('stop') ||
              rowErrors?.includes('equal_dates') ||
              rowErrors?.includes('start_after_stop')) && (
              <WarningMessage className="w-full">
                <Fa
                  icon="diamond-exclamation"
                  size={1}
                  className="text-inherit"
                />
                <WarningMessageText className="leading-none">
                  {(() => {
                    if (rowErrors.includes('start_after_stop'))
                      return 'Start time must be before end time.'
                    if (rowErrors.includes('equal_dates'))
                      return 'Start and stop date and time cannot be the same.'
                    if (
                      rowErrors?.includes('start') ||
                      rowErrors?.includes('stop')
                    ) {
                      return `A ${(() => {
                        if (
                          rowErrors?.includes('start') &&
                          rowErrors?.includes('stop')
                        )
                          return 'start and stop'
                        if (rowErrors?.includes('start')) return 'start'
                        if (rowErrors?.includes('stop')) return 'stop'
                      })()} date for your audience is required.`
                    }
                  })()}
                </WarningMessageText>
              </WarningMessage>
            )}
          </FormItem>
        )
      })}
      {timeFrames.length > 0 && timeFrames.every((tf) => tf.start && tf.stop) && (
        <Button
          className="laptopsmall:w-40 laptopsmall:mx-auto mt-4"
          onClick={() => {
            setTimeFrames((oldArray) => [...(oldArray || []), {}])
          }}
        >
          Add another timeframe
        </Button>
      )}
    </div>
  )
}
