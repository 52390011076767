import { dayjs } from 'Tools/dateUtils'
import { Fa, TextHeader } from '@eltoro-ui/components'
import { V1StatsResponse, Campaignservicev1OrderLine } from 'next-gen-sdk'
import { CreativeResType, StatsAccumulatorType } from 'types'
import {
  getOrderLineBudget,
  getOrderLineSpent,
  styleTailwind,
  truncateMiddle,
} from 'Helpers'
import { OrderlineStateIndicator } from 'Components/OrderlineTable/OrderlineStateIndicator'
import {
  CreativesDisplay,
  DailyBreakdown,
  DonutChartContainer,
  HighestPerformingCreative,
} from './components'
import './Stats.scss'
import { DailyBreakdownType } from '../ReportModal'

const FlightDate = styleTailwind('div', 'FlightDate flex font-semibold')
const DaysRemain = styleTailwind(
  'div',
  'DaysRemain relative z-10 flex items-center font-bold',
)

const DivBottomBorder = styleTailwind(
  'div',
  'DivBottomBorder border-tint-khaki-500 border-b-thin',
)

const TopLevelStat = ({
  title,
  stat,
  icon,
}: {
  title: string
  stat: string
  icon: string
}) => {
  /* useEffect(() => {
   *   movement === 'negative-high' && setIcon(faChevronUp)
   *   movement === 'positive' && setIcon(faCheck)
   *   movement === 'negative-low' && setIcon(faChevronDown)
   *   movement === 'neutral' && setIcon(faMinus)
   * }, [movement]) */

  return (
    <div className="TopLevelStat border-primary flex items-center justify-center border">
      <Fa className="TopLevelStat__icon" icon={icon} size={5} />
      <div className="details-TopLevelStat flex flex-col leading-none">
        <div className="stat-TopLevelStat font-bold">{stat}</div>
        <div className="title-type">{title}</div>
      </div>
    </div>
  )
}
export const daysRemaining = (remData: Campaignservicev1OrderLine) => {
  if (remData && dayjs().tz().isAfter(dayjs.tz(remData.endTime), 'day')) {
    return 0
  }
  if (remData && dayjs().tz().isAfter(dayjs.tz(remData.startTime), 'day')) {
    return (
      remData &&
      Math.ceil(dayjs.tz(remData.endTime).diff(dayjs().tz(), 'day', true))
    )
  }
  return (
    remData &&
    Math.ceil(
      dayjs.tz(remData.endTime).diff(dayjs.tz(remData.startTime), 'day', true),
    )
  )
}
export const Stats = ({
  olStatsData,
  creativeStatsData,
  sortedCreativeStats,
  highestPerformingCreative,
  className = '',
  orderline,
  creatives,
  showAsPDF,
  week,
  setWeek,
  item,
  setItem,
}: {
  olStatsData?: V1StatsResponse
  creativeStatsData: V1StatsResponse
  sortedCreativeStats: StatsAccumulatorType[]
  highestPerformingCreative?: CreativeResType
  className?: string
  orderline: Campaignservicev1OrderLine
  creatives?: CreativeResType[]
  showAsPDF: boolean
  week: number
  setWeek: (x: number) => void
  item: DailyBreakdownType
  setItem: (x: DailyBreakdownType) => void
}) => {
  const currentCreatives = creatives || []

  if (!olStatsData) return null
  const { results, totals } = olStatsData

  const progressWidth = (progressData: Campaignservicev1OrderLine) => {
    const totalDays =
      progressData &&
      dayjs
        .tz(progressData.endTime)
        .diff(dayjs.tz(progressData.startTime), 'day')

    const remaining = daysRemaining(orderline)
    if (remaining === 0) {
      return 100
    }
    return (100 - (remaining / totalDays) * 100).toFixed(2)
  }
  // this might be used at a later date
  /* const getCTRMovement = (ctr: number) => {
   *   if (ctr > 0.08) {
   *     return 'negative-high'
   *   }
   *   if (ctr < 0.06) {
   *     return 'negative-low'
   *   }
   *   if (Number.isNaN(ctr)) {
   *     return 'neutral'
   *   }
   *   return 'positive'
   * } */

  const resultsBreakdown = results ? results[0] : undefined

  const currentImps = () => Number(resultsBreakdown?.totals?.imps) || 0

  const currentClicks = () => Number(resultsBreakdown?.totals?.clicks) || 0
  const getCTR = () => {
    const ctr = (currentClicks() / currentImps()) * 100
    return Number.isNaN(ctr) || ctr === Infinity ? 'N/A' : `${ctr.toFixed(3)}%`
  }
  if (olStatsData === undefined) {
    return <div className="not-deployed">Order line not yet serving.</div>
  }

  return (
    <div
      className={`Stats pageContainer w-full ${
        showAsPDF ? 'isPDF' : 'isOnline'
      } ${className}`}
    >
      <div className="stats-header flex flex-col justify-between">
        <TextHeader
          type={3}
          className={`name-campaignInfo flex ${
            showAsPDF
              ? 'Stats__header--pdf border-primary border-t-2 pb-2 pt-3 !text-4xl'
              : 'Stats__header--online'
          }`}
        >
          <span
            className={`${
              orderline && orderline.name ? 'RightVerticalPipe' : ''
            } pb-2 font-normal uppercase tracking-tight`}
          >
            Order line report
          </span>
          {orderline && orderline.name ? (
            // prettier-ignore
            <span className="whitespace-nowrap pb-2">{truncateMiddle(orderline.name, 40)}</span>
          ) : null}
        </TextHeader>
        {!showAsPDF ? (
          <div className="today-Stats-date date-today text-s flex items-center justify-end gap-1">
            <span>Generated on </span>
            <span className="font-bold">
              {dayjs().tz().format('MM-DD-YYYY')}
            </span>
          </div>
        ) : null}
      </div>
      <div
        key={orderline.id}
        className="campaignInfo-Stats flex flex-col gap-1"
      >
        <div className="flightDates-campaignInfo bg-tint-khaki-100 grid w-full gap-2">
          <FlightDate className="start-flightDates text-center">
            {orderline && dayjs.tz(orderline.startTime).format('MM/DD/YY')}
          </FlightDate>
          <div className="progressBar-flightDates data relative">
            <div
              className="daysRemaining-progressBar absolute z-0"
              // prettier-ignore
              style={{ width: `${daysRemaining(orderline) > 0 ? (100 - Number(progressWidth(orderline))): 0}%` }}
            />
            {daysRemaining(orderline) === 0 ? (
              <DaysRemain className="Ended flex justify-center gap-1">
                <span>Order line ended</span>
                <span>{dayjs().tz().diff(orderline.endTime, 'day')}</span>
                <span>
                  day
                  {dayjs().tz().diff(orderline.endTime, 'day') <= 1
                    ? null
                    : 's'}
                </span>
                <span>ago</span>
              </DaysRemain>
            ) : (
              <DaysRemain
                className="Ongoing flex whitespace-nowrap"
                // add change if days are fewer than 5, Remaining-Days label doesn't overlap the ol start-date label, so doesn't go over 77% margin-right; if campaign is over, no margin, text will be centered
                style={{
                  marginRight: `${
                    daysRemaining(orderline) > 5
                      ? progressWidth(orderline)
                      : daysRemaining(orderline) !== 0
                      ? 77
                      : 0
                  }%`,
                }}
              >
                <span className="Days ml-auto">
                  {daysRemaining(orderline)} &nbsp;day
                  {daysRemaining(orderline) === 1 ? null : 's'} &nbsp;remaining
                </span>
              </DaysRemain>
            )}
          </div>
          <FlightDate className="stop-flightDates justify-self-end !pr-3">
            {orderline && dayjs.tz(orderline.endTime).format('MM/DD/YY')}
          </FlightDate>
        </div>

        {orderline?.state && (
          <div className="status-campaignInfo status-status bg-tint-khaki-100 p-1">
            <OrderlineStateIndicator
              state={orderline.state}
              reason={orderline.reason}
            />
          </div>
        )}
      </div>
      <DivBottomBorder className="topLevel-Stats grid justify-center gap-4">
        <TopLevelStat
          title="Clicks"
          stat={
            currentClicks() >= 0
              ? currentClicks()?.toString() || ''
              : 'not available'
          }
          icon="mouse-pointer"
        />
        <TopLevelStat
          title="Impressions"
          stat={
            currentImps() >= 0
              ? currentImps()?.toString() || ''
              : 'not available'
          }
          icon="home"
        />
        <TopLevelStat title="CTR" stat={getCTR()} icon="chart-line" />
      </DivBottomBorder>

      <DivBottomBorder className="impressionsSpend-Stats">
        <DonutChartContainer
          className="Impressions border-r-thin border-tint-khaki-500"
          data={
            orderline && orderline
              ? {
                  total: {
                    title: 'Total impressions',
                    value: (orderline?.impressions || 0) as number,
                    color: 'var(--base-info)',
                  },
                  used: {
                    title: 'Served',
                    value: currentImps(),
                    color: 'var(--base-info)',
                  },
                }
              : {
                  total: {
                    title: 'Total impressions',
                    value: 0,
                    color: 'var(--base-info)',
                  },
                  used: {
                    title: 'Served',
                    value: 0,
                    color: 'var(--base-info)',
                  },
                }
          }
          isMoney={false}
          isEmpty={!!totals?.imps && !!currentImps() === undefined}
        />

        <DonutChartContainer
          className="Budget"
          data={
            orderline
              ? {
                  total: {
                    title: 'Total budget',
                    value: Number(getOrderLineBudget(orderline).toFixed(2)),
                    color: 'var(--color-success)',
                  },
                  used: {
                    title: 'Total spent',
                    value: Number(
                      getOrderLineSpent(orderline, currentImps()).toFixed(2),
                    ),
                    color: 'var(--color-success)',
                  },
                }
              : {
                  total: {
                    title: 'Total budget',
                    value: 0,
                    color: 'var(--color-success)',
                  },
                  used: {
                    title: 'Total spent',
                    value: 0,
                    color: 'var(--color-success)',
                  },
                }
          }
          isMoney
          isEmpty={
            false
            /* !!campaign */
            /* && totalCost === 0 */
          }
        />
      </DivBottomBorder>
      {!showAsPDF && (
        <DivBottomBorder
          className={`CreativesInfo flex items-start justify-center gap-4`}
        >
          <div className="highestServed-Stats border-primary-200 border-r">
            {highestPerformingCreative && (
              <HighestPerformingCreative
                creative={highestPerformingCreative}
                stats={sortedCreativeStats.find(
                  (s) =>
                    s.creativeId === highestPerformingCreative.creative?.id,
                )}
              />
            )}
          </div>

          <CreativesDisplay
            orderline={orderline}
            creatives={currentCreatives}
            creativeStats={creativeStatsData}
            sortedStats={sortedCreativeStats}
            isPDF={showAsPDF}
          />
        </DivBottomBorder>
      )}
      <DailyBreakdown
        orderlineStats={olStatsData}
        isPDF={showAsPDF}
        downloadWeek={week}
        setDownloadWeek={setWeek}
        downloadItem={item}
        setDownloadItem={setItem}
      />
    </div>
  )
}
