import { useState } from 'react'
import {
  Fa,
  Text,
  showWarningMessage,
  showSuccessMessage,
  showErrorMessage,
} from '@eltoro-ui/components'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { EditingCancelUpdateButtons } from '../EditingCancelUpdateButtons'
import { useAppContext, useCampaignContext } from 'Contexts'
import {
  getHighestCPMProductType,
  getTypeMoneyCost,
  simplifyEnum,
  styleTailwind,
  useOrderLineCost,
} from 'Helpers'
import { PriceTextInput } from 'Components/PriceTextInput'
import {
  IMPRESSION_MAX,
  IMPRESSION_MIN,
  // LabelInputPair,
  NA,
  TabletLabel,
} from '../shared'

const EditOLRowItem = styleTailwind(Text, 'EditOLRowItem__item')

const BudgetGridRow = styleTailwind(
  'div',
  'BudgetEdit__GridRow grid grid-cols-3 gap-2',
)
const OLEditTableHeader = styleTailwind(Text, 'EditOLTable__header-text')

export type BudgetChangeType = {
  olId: string
  costRange: {
    estimate: {
      units: string
      nanos: number
    }
  }
}

export const BudgetEditOLRow = ({
  ol,
  setOrderLineChanges,
}: {
  ol: Campaignservicev1OrderLine
  setOrderLineChanges: React.Dispatch<React.SetStateAction<BudgetChangeType[]>>
}) => {
  const { isAdmin, currentOrg } = useAppContext()
  const [input, setInput] = useState<number>(
    ol.costRange?.estimate ? getTypeMoneyCost(ol.costRange?.estimate) || 0 : 0,
  )
  const { minCost, cpmCreativeGroup } = useOrderLineCost(
    ol,
    ol?.minimumImpressions || IMPRESSION_MIN,
    IMPRESSION_MAX,
    currentOrg || {},
  )
  const costSwitcher = isAdmin ? 0 : Number(minCost)
  const productCpmsArray =
    currentOrg && getHighestCPMProductType(ol, currentOrg)
  const premAuds = productCpmsArray?.products?.map(
    (currentAud) => currentAud.premiumAudieneces,
  )
  const uniquePremAudiences = [
    ...new Map(premAuds?.map((item) => [item?.dataSource, item])).values(),
  ]
  const uniqueUpcharges = uniquePremAudiences.map((c) => c?.upcharge)
  return (
    <BudgetGridRow className="EditOLRowItem">
      <EditOLRowItem className="pr-2">
        <TabletLabel>Order Line</TabletLabel>
        <Text>{ol.name}</Text>
      </EditOLRowItem>
      <EditOLRowItem className="OLCard__Budget-value relative w-full !gap-0">
        <TabletLabel>Adjust Subtotal</TabletLabel>
        {ol.costRange ? (
          <div className="flex gap-3">
            <PriceTextInput
              value={input}
              onChange={(cost) => {
                setInput(cost)
                const value = Number(cost)
                const newUnits: Number = value - (value % 1)
                const newNanos = Math.round(+(value % 1) * 10 ** 9)
                if (!ol.id || cost < costSwitcher || !cost) {
                  return showWarningMessage(
                    'Unable to update order line',
                    `Minimum budget for this order line: $${minCost}`,
                  )
                }
                if (isAdmin && cost < Number(minCost)) {
                  showWarningMessage(
                    'This is below the org minimum',
                    `Minimum budget for this order line: $${minCost}`,
                  )
                }
                const olId = ol.id
                const costRange = {
                  estimate: {
                    units: newUnits.toString(),
                    nanos: newNanos,
                  },
                }
                const change = { olId, costRange }
                setOrderLineChanges((prev) => {
                  const filtered = prev.filter((p) => p.olId !== change.olId)
                  return [...filtered, change]
                })
              }}
              min={isAdmin ? undefined : Number(minCost)}
              className="max-w-[7rem]"
            />
            {input < Number(minCost) && !isAdmin && (
              <span
                aria-label={`The minimum cost for this order line is $${minCost}`}
                data-tooltip="top"
                className="ImpressionsOLRow__min-imps-warning font-normal"
              >
                <Fa icon="circle-info" size={1} className="!text-warning" />
              </span>
            )}
            {isAdmin && input < Number(minCost) && (
              <span
                aria-label={`You are now below the minimum budget of $${minCost}`}
                data-tooltip="top"
                className="ImpressionsOLRow__min-imps-warning font-normal"
              >
                <Fa icon="circle-info" size={1} className="!text-warning" />
              </span>
            )}
          </div>
        ) : (
          <div>
            <div className="OLCard__Budget--tooltip text-warning group group-hover:text-warning-700 absolute right-0">
              <span
                className="Tooltip-css-wrap"
                data-tooltip="bottom"
                aria-label="Attach Creative(s) to calculate and adjust Budget."
              >
                <Fa icon="comment-exclamation" size={1} />
              </span>
            </div>
            $0.00
          </div>
        )}
      </EditOLRowItem>
      <EditOLRowItem>
        <TabletLabel>Order Line CPM</TabletLabel>
        {currentOrg && productCpmsArray?.highestCPM?.cpm ? (
          <Text>
            $
            {getTypeMoneyCost(
              productCpmsArray?.highestCPM?.cpm,
              uniqueUpcharges,
            )?.toFixed(2)}
            &nbsp;
            {simplifyEnum(2, cpmCreativeGroup, true)}
          </Text>
        ) : (
          NA
        )}
      </EditOLRowItem>
    </BudgetGridRow>
  )
}

export const BudgetEdit = ({
  className = '',
  selectedOls,
  onCloseModal,
  setRefreshCampaign,
}: {
  className?: string
  selectedOls: Campaignservicev1OrderLine[]
  onCloseModal: () => void
  setRefreshCampaign?: (x: boolean) => void
}) => {
  const [orderLineChanges, setOrderLineChanges] = useState<BudgetChangeType[]>(
    [],
  )
  const [disable, setDisabled] = useState(false)
  const { campaignServiceApi, currentOrg, roles } = useAppContext()
  const { setRefresh } = useCampaignContext()

  const handleUpdate = () => {
    setDisabled(true)
    Promise.all(
      orderLineChanges.map((change) => {
        if (
          !campaignServiceApi ||
          !currentOrg?.id ||
          !currentOrg.minimumImpressions
        )
          return Promise.resolve({})
        const { costRange, olId } = change
        const ol = selectedOls.find((c) => c.id === olId)

        const productsAndCosts =
          currentOrg && ol && getHighestCPMProductType(ol, currentOrg)
        const premAuds = productsAndCosts?.products?.map(
          (currentAud) => currentAud.premiumAudieneces,
        )
        const uniquePremAudiences = [
          ...new Map(
            premAuds?.map((item) => [item?.dataSource, item]),
          ).values(),
        ]
        const uniqueUpcharges = uniquePremAudiences.map((c) => c?.upcharge)
        const cpmCost =
          !ol?.costRange?.estimate || !productsAndCosts?.highestCPM?.cpm
            ? 0
            : getTypeMoneyCost(
                productsAndCosts.highestCPM?.cpm,
                uniqueUpcharges,
              )
        // cost = (imps/1000) * cpm
        const minImps =
          ol?.costRange?.estimate &&
          (getTypeMoneyCost(costRange?.estimate) / cpmCost) * 1000
        if (
          ol?.minimumImpressions &&
          minImps &&
          roles?.includes('nextgen_admin') &&
          minImps < ol?.minimumImpressions
        ) {
          return campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
            olId,
            currentOrg?.id,
            {
              minimumImpressions: Math.round(minImps),
              costRange,
            },
          )
        }
        return campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
          olId,
          currentOrg?.id,
          {
            costRange,
          },
        )
      }),
    )
      .then(() => {
        showSuccessMessage('Budget was updated successfully', '')
      })
      .catch(() => {
        setDisabled(false)
        showErrorMessage('Budget update failed', '')
      })
      .then(() => {
        onCloseModal()
        if (setRefreshCampaign) {
          setRefreshCampaign(true)
        }
        setRefresh(true)
      })
  }

  const hasUpdates = () => {
    // if a change has a different cost than the original
    if (!orderLineChanges.length) return false
    return !!selectedOls.some((originalOL) => {
      const { costRange } = originalOL

      const matchingChange = orderLineChanges.find(
        (change) => change.olId === originalOL.id,
      )
      if (!matchingChange) return false

      return matchingChange.costRange !== costRange
    })
  }

  return (
    <div className={`BudgetEdit ${className}`}>
      <BudgetGridRow className="EditOLTable__header StickyHeader laptopsmall:hidden">
        <OLEditTableHeader>Order Line Name</OLEditTableHeader>
        <OLEditTableHeader>Adjust Subtotal</OLEditTableHeader>
        <OLEditTableHeader>Order Line CPM</OLEditTableHeader>
      </BudgetGridRow>
      <div className="EditOLTable__body">
        {currentOrg &&
          selectedOls.map((ol, i) => (
            <BudgetEditOLRow
              ol={ol}
              setOrderLineChanges={setOrderLineChanges}
              key={`BudgetEditOLRow__${ol.id}_${i}`}
            />
          ))}
      </div>

      <EditingCancelUpdateButtons
        onCloseModal={onCloseModal}
        handleUpdate={handleUpdate}
        updateDisabled={!hasUpdates() || disable}
      />
    </div>
  )
}
