import { Modal, TextHeader, Checkbox, Button } from '@eltoro-ui/components'
import { useAppContext } from 'Contexts'
import {
  BuildAndDownloadCSV,
  getOrderLineBudget,
  getOrderLineSpent,
  simplifyEnum,
} from 'Helpers'
import { dayjs } from 'Tools/dateUtils'
import { Campaignservicev1OrderLine, V1StatsResponse } from 'next-gen-sdk'
import { useEffect, useState } from 'react'

export const CSVColumnPickerModal = ({
  oL,
  olStats,
  closeModal,
  includedAudiences,
  excludedAudiences,
}: {
  oL: Campaignservicev1OrderLine
  olStats: V1StatsResponse
  closeModal: (x: boolean) => void
  includedAudiences: number
  excludedAudiences?: number
}) => {
  const { orgServiceApi } = useAppContext()
  const [orgName, setOrgName] = useState('')
  useEffect(() => {
    const getName = () => {
      if (!oL.orgId) return
      return orgServiceApi
        ?.advertisingPlatformServiceGetOrg(oL.orgId)
        .then((res) => {
          if (res.name) {
            setOrgName(res.name)
          }
        })
    }
    getName()
  }, [oL.orgId])
  const [columns, setColumns] = useState<{ col: string; included: boolean }[]>([
    {
      col: 'Organization',
      included: true,
    },
    {
      col: 'Org Name',
      included: true,
    },
    {
      col: 'Campaign Name',
      included: false,
    },
    {
      col: 'Order Line Name',
      included: true,
    },
    {
      col: 'Order Line ID',
      included: true,
    },
    {
      col: 'Order Line Status',
      included: true,
    },
    {
      col: 'Start Date',
      included: true,
    },
    {
      col: 'End Date',
      included: true,
    },
    {
      col: 'Total Budget',
      included: true,
    },
    {
      col: 'Budget Spent',
      included: true,
    },
    {
      col: 'Budget Remaining',
      included: true,
    },
    {
      col: 'Impressions Served',
      included: true,
    },
    {
      col: 'Total Impressions',
      included: true,
    },
    {
      col: 'Impressions Remaining',
      included: true,
    },
    {
      col: 'Clicks',
      included: true,
    },
    {
      col: 'CTR',
      included: true,
    },
    {
      col: 'Creative Type',
      included: false,
    },
    {
      col: 'Audience Type',
      included: false,
    },
    {
      col: 'Total Matched Audience',
      included: false,
    },
    {
      col: 'Total Excluded Audience',
      included: false,
    },
  ])

  const possibleColumns = [
    'Organization',
    'Org Name',
    'Campaign Name',
    'Order Line Name',
    'Order Line ID',
    'Order Line Status',
    'Start Date',
    'End Date',
    'Total Budget',
    'Budget Spent',
    'Budget Remaining',
    'Impressions Served',
    'Total Impressions',
    'Impressions Remaining',
    'Clicks',
    'CTR',
    'Creative Type',
    'Audience Type',
    'Total Matched Audience',
    'Total Excluded Audience',
  ]
  const dataMaker = () => {
    const budget = getOrderLineBudget(oL)
    const spent = olStats.totals?.imps
      ? getOrderLineSpent(oL, olStats.totals?.imps)
      : 0
    const budgetRemaining =
      budget - Number(spent) >= 0 ? budget - Number(spent) : 0
    return columns
      .map(({ col: current, included }) => {
        if (!included) return undefined
        if (current === 'Organization') {
          return oL.orgId || 'No org id'
        }
        if (current === 'Org Name' && oL.orgId) {
          return orgName || 'No Org Name'
        }
        if (current === 'Campaign Name') {
          return oL.campaign?.id || 'No campaign id'
        }
        if (current === 'Order Line Name') {
          return oL.name || 'No Order Line name'
        }
        if (current === 'Order Line ID') {
          return oL.id || 'No Order Line ID'
        }
        if (current === 'Order Line Status') {
          return oL.status ? simplifyEnum(2, oL.status) : 'No Order Line status'
        }
        if (current === 'Start Date') {
          return oL.startTime
            ? `${dayjs.tz(oL.startTime).format('MM-DD-YYYY')}`
            : 'No start date'
        }
        if (current === 'End Date') {
          return oL.endTime
            ? `${dayjs.tz(oL.endTime).format('MM-DD-YYYY')}`
            : 'No end date'
        }
        if (current === 'Total Budget') {
          return budget.toFixed(2)
        }
        if (current === 'Budget Spent') {
          return spent.toFixed(2)
        }
        if (current === 'Budget Remaining') {
          return budgetRemaining.toFixed(2)
        }
        if (current === 'Total Impressions') {
          return oL.impressions || 'No data'
        }
        if (current === 'Impressions Served') {
          return olStats?.results?.[0].totals?.imps || 'No data'
        }
        if (current === 'Impressions Remaining') {
          return (
            (oL.impressions || 0) - (olStats?.results?.[0].totals?.imps || 0)
          )
        }
        if (current === 'Clicks') {
          return olStats?.results?.[0].totals?.clicks || 'No data'
        }
        if (current === 'CTR') {
          return (
            ((olStats?.results?.[0].totals?.clicks || 0) /
              (olStats?.results?.[0].totals?.imps || 0)) *
            100
          )
        }
        if (current === 'Creative Type') {
          return oL.creatives?.[0]?.type || 'No creative type'
        }
        if (current === 'Audience Type') {
          return oL.audiences?.[0]?.type || 'No audience type'
        }
        if (current === 'Total Matched Audience') {
          return includedAudiences
        }
        if (current === 'Total Excluded Audience') {
          return excludedAudiences || 0
        }
        return 'No data'
      })
      .filter((curr) => curr !== undefined)
  }
  return (
    <Modal
      className="CSVColumnPickerModal"
      offClick={() => {
        closeModal(false)
      }}
    >
      <TextHeader className="CSVColumnPickerModal__title-header" type={5}>
        Select columns for report
      </TextHeader>
      <div className="pt-2 pb-2">
        {possibleColumns &&
          possibleColumns.map((currentColumn) => {
            const included = !!columns.find(
              (col) => col.col === currentColumn && col.included,
            )
            return (
              <Checkbox
                label={currentColumn}
                checked={included}
                onChange={(checked) => {
                  const prevIndex = columns.findIndex(
                    (p) => p.col === currentColumn,
                  )
                  const newCols = [
                    ...columns.slice(0, prevIndex),
                    { col: currentColumn, included: checked },
                    ...columns.slice(prevIndex + 1),
                  ]
                  return setColumns(newCols)
                }}
              />
            )
          })}
      </div>
      <div className="CSVColumnPickerModal__buttons ButtonGroup flex justify-between pb-2 pt-4">
        <Button onClick={() => closeModal(false)}>Cancel</Button>
        <Button
          onClick={() => {
            if (
              olStats?.results &&
              olStats.results[0].totals &&
              oL.impressions
            ) {
              const finalColumns = columns
                .filter((col) => col.included)
                .map((col) => col.col)
              const statData = [finalColumns, dataMaker()]
              BuildAndDownloadCSV(
                statData,
                `${oL.name}.csv`,
                'text/csv;charset=utf-8;',
              )
              closeModal(false)
            }
          }}
        >
          Download
        </Button>
      </div>
    </Modal>
  )
}
