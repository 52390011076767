import { useEffect, useState } from 'react'
import {
  Button,
  PreviewType,
  showErrorMessage,
  showSuccessMessage,
  Table,
  Text,
  TextHeader,
} from '@eltoro-ui/components'
import { useDebounce, useTokenPagination } from '@eltoro-ui/hooks'
import { Campaignservicev1OrderLine, Orgmanagerv1Org } from 'next-gen-sdk'
import classNames from 'classnames'
import { truncateMiddle, styleTailwind, v2SyncPrompt } from 'Helpers'
import { TablePagination, PageContent, OrgNotes } from 'Components'
import { useAppContext } from 'Contexts'
import { createChildOrg, uploadOrgLogo } from 'Requests'
import { useSSEListener } from 'Hooks'
import { ChildOrgTableRow } from 'types'
import { AddChildOrgModal } from './AddChildOrgModal'
import { ChildOrgTableColumns } from './ChildOrgTableColumns'
import { EmptyChildOrgsSVG } from './EmptyChildOrgsSVG'
import './ChildOrgsPage.scss'

type SortType = { field: string; direction: 'asc' | 'desc' }
type FilterType = {
  [field: string]: Array<string | number>
}

const ChildButton = styleTailwind(
  Button,
  'ChildOrgTable__child-org-button mt-2 mb-4 mr-4 py-3',
)

export const ChildOrgsPage = () => {
  const [childOrgModalOpen, setChildOrgModalOpen] = useState(false)
  const [childOrgQueue, setChildOrgQueue] = useState<string[]>([])
  const [populatedRows, setPopulatedRows] = useState<ChildOrgTableRow[]>()
  const [sort, setSort] = useState<SortType>()
  const [filter, setFilter] = useState<FilterType>()
  const debouncedFilter = useDebounce(filter, 200)
  const filterString =
    debouncedFilter &&
    Object.entries(debouncedFilter)
      .reduce((acc: string[], [key, value]) => {
        if (!value.length) return acc
        const string = value
          .map(
            (val) => `${key} = ${typeof val === 'number' ? val : `"*${val}*"`}`,
          )
          .join(' OR ')

        return [...acc, string]
      }, [])
      .join(' AND ')
  const {
    tok,
    currentOrg,
    orgServiceApi,
    campaignServiceApi,
    roles,
    isOnHold,
    isAdmin,
  } = useAppContext()

  const handleFetchData = async ({
    pageSize,
    nextPageToken,
  }: {
    pageSize: number
    nextPageToken?: string
  }) => {
    const orderBy =
      sort?.field && sort?.direction
        ? `${sort.field}${sort.direction === 'desc' ? ' desc' : ''}`
        : undefined
    const childOrgs = await orgServiceApi?.advertisingPlatformServiceListOrgs(
      pageSize, // pageSize
      nextPageToken, // pageToken
      orderBy, // orderBy
      currentOrg?.id &&
        `parent_org_id = "${currentOrg.id}"${
          filterString ? ` AND ${filterString}` : ''
        } AND id != "${currentOrg.id}"`, // filter
    )
    if (!childOrgs?.orgs) return { data: [], totalItems: 0 }
    const newData = childOrgs.orgs.map((org) => {
      return { org, loading: true }
    }) as ChildOrgTableRow[]
    setPopulatedRows(undefined)
    return {
      data: newData,
      totalItems: childOrgs.totalSize || 0,
      nextPageToken: childOrgs.nextPageToken,
    }
  }

  const [orgUpdatedMsg, clearOrgUpdatedMsg] = useSSEListener('OrgUpdated')
  const [orgDeletedMsg] = useSSEListener('OrgDeleted')

  const pagination = useTokenPagination<ChildOrgTableRow>(
    {
      pageSize: 10,
      fetchData: handleFetchData,
    },
    [currentOrg, sort, filterString, orgUpdatedMsg, orgDeletedMsg],
  )

  useEffect(() => {
    let ignore = false
    // populate rows
    const populateRows = async (childOrgs: ChildOrgTableRow[]) => {
      if (currentOrg?.id) {
        const completedRows = await Promise.all(
          childOrgs.map(async (row) => {
            if (!currentOrg?.id || !campaignServiceApi || !row.org.id)
              return Promise.resolve(row)
            const orderBy = 'update_time desc'
            const filter = `status = 70 OR status = 110 AND org_id = ${row.org.id}` //  (70 === serving, 110 === errored and serving)
            const firstResult = await campaignServiceApi
              .advertisingPlatformServiceListOrderLines(
                1, // pageSize
                undefined, // token
                orderBy, // orderBy
                filter, // filter
              )
              .then((res) => res)
              .catch(() => undefined)

            const fetchOrderLines = async (
              token?: string,
            ): Promise<Campaignservicev1OrderLine[]> => {
              const result = await campaignServiceApi.advertisingPlatformServiceListOrderLines(
                100, // pageSize
                token, // token
                orderBy, // orderBy
                filter, // filter
              )
              let data =
                result.orderLines || ([] as Campaignservicev1OrderLine[])

              let pageToken = result.nextPageToken
              if (pageToken) {
                let tempData = await fetchOrderLines(pageToken)
                data = data?.concat(tempData)
              }

              return data
            }
            const servingCampaigns = await fetchOrderLines().then((ols) => {
              return [...new Set(ols.map((ol) => ol.campaign?.id))].length
            })
            const dateOfLastDeployedOrderLine =
              firstResult?.orderLines?.[0]?.updateTime
            return {
              org: row.org,
              dateOfLastDeployedOrderLine,
              servingCampaigns,
              servingOrderLines: firstResult?.totalSize,
              loading: false,
            }
          }),
        )
        return completedRows
      }
      return [] as ChildOrgTableRow[]
    }

    if (pagination.currentPageData) {
      populateRows(pagination.currentPageData).then((newRows) => {
        if (!ignore) setPopulatedRows(newRows)
      })
    }
    return () => {
      ignore = true
    }
  }, [pagination.currentPageData])

  useEffect(() => {
    if ((!orgUpdatedMsg && !orgDeletedMsg) || !currentOrg?.id) return
    if (orgUpdatedMsg || orgDeletedMsg) {
      if (
        orgUpdatedMsg &&
        !!childOrgQueue.find((id) => id === orgUpdatedMsg.message.org?.id)
      ) {
        clearOrgUpdatedMsg()
        showSuccessMessage(
          'Congrats!',
          `The child org, ${orgUpdatedMsg.message.org?.name}, has just been created. If you'd like to activate 'Web-To-Home' for this org, please contact support@eltoro.com.`,
        )
        setChildOrgQueue((prev) => {
          const filtered = prev.filter(
            (id) => id !== orgUpdatedMsg.message.org?.id,
          )
          return filtered
        })
      }
    }
  }, [
    childOrgQueue,
    clearOrgUpdatedMsg,
    currentOrg?.id,
    orgDeletedMsg,
    orgUpdatedMsg,
    pagination,
  ])

  if (!currentOrg?.id) return null

  const handleSubmit = async (
    childOrg: Orgmanagerv1Org,
    logo?: PreviewType,
  ) => {
    if (!orgServiceApi) return
    setChildOrgModalOpen(false)

    // Create the org
    await createChildOrg(childOrg)
      .then(async (newOrg) => {
        if (newOrg.id) {
          // create the child org's logo
          if (logo) await uploadOrgLogo(logo.name, logo, tok, newOrg.id)
          setChildOrgQueue((prev) => {
            return [...prev, ...(newOrg.id ? [newOrg.id] : [])]
          })
        }
      })
      .catch(() => {
        showErrorMessage(
          'Oh no!',
          `The child org, ${childOrg.name} could not be created, please try again. If you continue to see this error, please contact support@eltoro.com.`,
        )
      })
  }

  const AddChildOrgButton = (
    <ChildButton
      className="mt-2 mr-4"
      size="m"
      kind="primary"
      onClick={() => v2SyncPrompt(() => setChildOrgModalOpen(true), currentOrg)}
    >
      Add child org
    </ChildButton>
  )

  return (
    <>
      {childOrgModalOpen && (
        <AddChildOrgModal
          parentOrg={currentOrg}
          onClose={() => setChildOrgModalOpen(false)}
          onSubmit={handleSubmit}
        />
      )}
      <PageContent className="ChildOrgsPage flex flex-col gap-2">
        <TextHeader
          type={1}
          className="ChildOrgsPage__header flex items-center leading-none"
        >
          <span className="RightVerticalPipe font-light uppercase">
            Child Orgs
          </span>
          <span>{truncateMiddle(currentOrg?.name, 45)}</span>
        </TextHeader>
        {isAdmin && <OrgNotes />}

        <div className="ChildOrgs__section-wrapper pr-4">
          <div className="ChildOrgTable BlockWithBorder min-w-auto w-auto">
            {currentOrg?.childOrgs?.length ? (
              <>
                {roles && !roles.includes('nextgen_read_only') && !isOnHold && (
                  <div className="mb-2 flex justify-between">
                    <TextHeader
                      type={3}
                    >{`Child Orgs (${currentOrg?.childOrgs?.length})`}</TextHeader>
                    {AddChildOrgButton}
                  </div>
                )}
                <div
                  className={classNames('ChildOrgTable__table-wrapper', {
                    'pointer-events-none opacity-50': pagination.loading,
                  })}
                >
                  <Table
                    rows={
                      populatedRows && populatedRows.length
                        ? populatedRows
                        : pagination.currentPageData
                    }
                    columns={ChildOrgTableColumns}
                    onSort={(_, sort, path) => {
                      setSort(
                        path
                          ? {
                              field: path as string,
                              direction: sort || 'asc',
                            }
                          : undefined,
                      )
                    }}
                    onFilter={(_, path, filterOn) => {
                      const key =
                        typeof path === 'object' ? path.join('.') : path
                      setFilter((prev) => {
                        return { ...prev, [key]: filterOn }
                      })
                    }}
                  />
                </div>
                {currentOrg?.childOrgs?.length > 10 ? (
                  <div className="ChildOrgTable__pagination mt-2 flex justify-end">
                    <TablePagination pagination={pagination} />
                  </div>
                ) : null}
              </>
            ) : (
              <div className="ChildOrgsPage__empty-state flex w-2/3 flex-wrap gap-1 lg:flex-nowrap">
                <EmptyChildOrgsSVG />
                <div className="ChildOrgsPage__empty-state-info text-l flex flex-col justify-center gap-2 px-6 lg:pl-0">
                  <TextHeader className="py-2" type={3}>
                    About Child Orgs
                  </TextHeader>
                  <div className="ChildOrgs__chatter flex w-auto flex-col gap-4 leading-loose">
                    <Text tag="p">
                      Child Orgs can be used by a Parent Org, such as a reseller
                      or agency, to give portal access to a reseller&apos;s
                      clients.
                    </Text>
                    <Text tag="p">
                      Child Orgs are also a great tool for a Parent Org to
                      organize campaigns, order lines, and billing of their
                      clients. They can have their own CPM or the same as the
                      Parent Org - the Parent Org is responsible for the
                      invoices generated from the Child Org.
                    </Text>
                  </div>
                  <div className="ChildOrgTable__add-child-org-button-group mt-12 flex justify-end pb-4">
                    <ChildButton
                      kind="default"
                      onClick={() =>
                        window.open('https://eltoro.zendesk.com/hc/en-us')
                      }
                    >
                      Find Out More
                    </ChildButton>
                    {roles &&
                      !roles.includes('nextgen_read_only') &&
                      !isOnHold &&
                      AddChildOrgButton}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </PageContent>
    </>
  )
}
