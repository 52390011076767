import React from 'react'
import { ETIcon } from 'Assets'
import { ButtonConfigType } from 'Components'

export const audienceUploadConfigs: ButtonConfigType[] = [
  {
    id: 'address',
    title: 'IP Targeting',
    subTitle: 'Upload a list of physical addresses',
    icon: <ETIcon icon="ip-targeting" />,
    tagLabel: 'address',
  },
  {
    id: 'dc',
    title: 'Digital Canvassing',
    subTitle: 'Upload household addresses and match neighbor households',
    icon: <ETIcon icon="digital-canvassing" />,
    comingSoon: false,
    disabled: false,
    tagLabel: 'location',
  },
  {
    id: 'venue-replay',
    title: 'Venue Replay',
    subTitle:
      'Target prospects that have visited key locations at the household and device level',
    icon: <ETIcon icon="venue-replay" />,
    tagLabel: 'behavioral',
    comingSoon: false,
    disabled: false,
  },
  {
    id: 'new-mover',
    title: 'Digital New Mover',
    subTitle: 'Target pre, escrow, and/or new movers in selected ZIP codes',
    icon: <ETIcon icon="digital-new-movers" />,
    tagLabel: 'behavioral',
  },
  // {
  //   id: 'web-to-home',
  //   title: 'Web-To-Home',
  //   subTitle: 'Target web visitors with direct mail',
  //   icon: <ETIcon icon="web-to-home" />,
  //   tagLabel: 'behavioral',
  //   comingSoon: true,
  //   disabled: true,
  // },
  {
    id: 'polygon',
    title: 'Map Polygon',
    subTitle: 'Target in real-time at the IP level of mapped locations',
    icon: <ETIcon icon="map-polygon" />,
    tagLabel: 'geographic',
    comingSoon: false,
    disabled: false,
  },
  {
    id: 'zip',
    title: 'ZIP Codes list',
    subTitle: 'Target all matched locations within ZIP codes',
    icon: <ETIcon icon="locator" />,
    tagLabel: 'location',
    comingSoon: false,
    disabled: false,
  },
  {
    id: 'ipForMailing',
    title: 'IP List for Mailing',
    subTitle: 'Match IP addresses to physical addresses for mailing',
    icon: <ETIcon icon="map-data" />,
    tagLabel: 'address',
    comingSoon: false,
    disabled: false,
    adminOnly: true,
  },
  {
    id: 'ip',
    title: 'IP Address List',
    subTitle: 'Upload IP addresses and target devices connected to it',
    icon: <ETIcon icon="sale-doc" />,
    tagLabel: 'address',
  },
  {
    id: 'device',
    title: 'Device ID list',
    subTitle: 'Upload lists of IDs for device level targeting',
    icon: <ETIcon icon="maid" />,
    tagLabel: 'address',
    comingSoon: false,
    disabled: false,
    adminOnly: true,
  },
  {
    id: 'reverseIp',
    title: 'Reverse IP',
    subTitle: 'Upload lists of IPs to return physical Addresses',
    icon: <ETIcon icon="locator" />,
    tagLabel: 'address',
    comingSoon: false,
    disabled: false,
    adminOnly: true,
  },
  {
    id: 'retargeting',
    title: 'Retargeting',
    subTitle: 'Target website visitors with digital ads',
    icon: <ETIcon icon="retargeting" />,
    tagLabel: 'behavioral',
    comingSoon: false,
    disabled: false,
  },
  {
    id: 'custom',
    title: 'Custom Audience',
    subTitle: 'Generate audiences with 3rd party data',
    icon: <ETIcon icon="ip-plus" />,
    tagLabel: 'address',
    comingSoon: true,
    disabled: true,
    adminOnly: true,
  },
]
