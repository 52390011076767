import { useEffect, useState } from 'react'
import { TextHeader, showErrorMessage } from '@eltoro-ui/components'
import {
  PageContent,
  CreditCardManager,
  InfoPopover,
  V2SyncWarning,
  OrgNotes,
} from 'Components'
import { DetailItem } from 'Pages/OrgSettingsPage'
import { useAppContext } from 'Contexts'
import { isOrgBillable, truncateMiddle } from 'Helpers'
import { InvoiceTable, SetUpFee } from './components'
import { BillingDetails } from './components/BillingDetails'

export const BillingPage = () => {
  const [loading, setLoading] = useState(false)
  const [refetch, setRefetch] = useState<'credit-cards' | 'balance' | null>(
    'balance',
  )
  const [billingError, setBillingError] = useState<'error' | 'not-set-up'>()
  const [unpaidCount, setUnpaidCount] = useState<number>()

  const {
    billingServiceApi,
    currentOrg,
    currentBillingOrg,
    setCurrentBillingOrg,
    isAdmin,
  } = useAppContext()

  useEffect(() => {
    let ignore = false

    if (refetch && currentOrg?.id) {
      setLoading(true)
      // call list invoices to get total unpaid invoices
      billingServiceApi
        ?.advertisingPlatformServiceListInvoicesV2(
          currentOrg.id,
          1,
          undefined,
          undefined,
          `org_id = "${currentOrg.id}" AND status = 4 OR status = 6`, // payment due, past due
        )
        .then(({ totalSize }) => setUnpaidCount(totalSize))
      // fetches the billing org with credit cards/account balance
      // the AppContext billing org does not populate account balance because it takes longer to load,
      // so we populate it here if we are on this page.
      billingServiceApi
        ?.advertisingPlatformServiceGetBillingOrg(
          currentOrg.id,
          false, // includeInvoices
          refetch === 'balance', // includeBalance
        )
        .then((billingOrg) => {
          setCurrentBillingOrg(billingOrg)
        })
        .catch((err) => {
          if (err?.body?.message === 'Org not found.') {
            setBillingError('not-set-up')
          } else {
            showErrorMessage(
              'Error fetching billing org',
              err?.body?.message || '',
            )
            setBillingError('error')
          }
        })
        .finally(() => {
          if (ignore) return
          setLoading(false)
          setRefetch(null)
        })
    }

    return () => {
      ignore = true
    }
  }, [billingServiceApi, currentOrg?.id, refetch, setCurrentBillingOrg])

  // refetches billing org balance when org changes
  useEffect(() => {
    let ignore = false
    if (ignore || !currentOrg) return
    setRefetch('balance')
    setBillingError(undefined)

    return () => {
      ignore = true
    }
  }, [currentOrg])

  if (currentOrg && !isOrgBillable(currentOrg)) {
    return (
      <div className="BlockWithBorder m-4">
        This organization is non-billable.
      </div>
    )
  }

  const initialPrepayment =
    (currentOrg?.setupFeePaid && currentOrg?.initialSetupFee) || 0

  // flipping to match the way the UI wants to communicate this
  // account balance on billing org will be like this:
  //   -2000 -> 2000 has been paid and credited
  //    0 -> no credits, nothing owed
  //   +2000 -> user needs to pay/is invoiced 2000
  const accountBalance = -(currentBillingOrg?.balance || 0)

  const handleRefetch = (type: 'credit-cards' | 'balance') => {
    setRefetch(type)
  }

  const needsV2Confirmation =
    currentOrg?.lastSyncTime &&
    new Date(currentOrg.lastSyncTime).getFullYear() > 2023

  return (
    <PageContent className="BillingPage">
      <TextHeader
        className="BillingPage__header flex items-start gap-1 pb-4"
        type={1}
      >
        <span
          className={`text-3xl font-light uppercase ${
            currentOrg?.name ? 'RightVerticalPipe' : ''
          }`}
        >
          Billing
        </span>

        {currentOrg?.name ? (
          <span className="text-3xl">
            {truncateMiddle(currentOrg.name, 35)}
          </span>
        ) : null}
      </TextHeader>
      {isAdmin && <OrgNotes />}

      {currentOrg && (
        <div className="BillingPage__content laptop:flex-wrap flex gap-6">
          <div className="BillingPage__main-col">
            {needsV2Confirmation && <V2SyncWarning currentOrg={currentOrg} />}
            <div className="BillingPage__details mobile:justify-center mobile:flex bg-primary-50 border-primer border-thin flex flex-wrap items-center gap-2 rounded-lg">
              <DetailItem>
                <span className="RightVerticalPipe font-bold">
                  {currentOrg.prepay ? 'Pre-payment' : 'Billed Directly'}
                </span>
                <span>Payment plan</span>
              </DetailItem>
              <DetailItem className="BillingPage__details-balance">
                {(() => {
                  if (loading)
                    return (
                      <span className="RightVerticalPipe animate-pulse font-bold">
                        Loading
                      </span>
                    )
                  if (!loading && billingError)
                    return (
                      <span
                        className={`RightVerticalPipe font-bold ${
                          billingError === 'error' ? 'text-danger' : ''
                        }`}
                      >
                        {billingError === 'error' ? 'Error' : 'N/A'}
                      </span>
                    )
                  return (
                    <div className="animate-fadein flex items-center">
                      <span
                        className={`${
                          accountBalance > 0 ? 'text-success' : 'text-warning'
                        } RightVerticalPipe !mr-1 font-bold`}
                      >
                        {`$${
                          accountBalance > 0 ? '+' : ''
                        }${accountBalance.toFixed(2)}`}
                      </span>
                      <InfoPopover
                        content={
                          <div className="BillingPage__balance-info-popover flex flex-col gap-2">
                            <TextHeader type={5} className="mb-2">
                              Balance
                            </TextHeader>
                            <span>
                              +${initialPrepayment.toFixed(2)} Initial
                              prepayment
                            </span>
                            <span>
                              {accountBalance < 0 ? '-' : '+'}$
                              {accountBalance.toFixed(2)} Invoice Balance
                            </span>
                            <span className="mt-2 text-sm font-light italic">
                              May not include orders that are processing
                            </span>
                          </div>
                        }
                        className="text-grey-200"
                      />
                    </div>
                  )
                })()}
                <span>Balance</span>
              </DetailItem>
              <DetailItem>
                <span className="font-bold">
                  {(() => {
                    if (loading)
                      return (
                        <span className="RightVerticalPipe animate-pulse">
                          Loading
                        </span>
                      )
                    if (!loading && billingError)
                      return (
                        <span
                          className={`RightVerticalPipe font-bold ${
                            billingError === 'error' ? 'text-danger' : ''
                          }`}
                        >
                          {billingError === 'error' ? 'Error' : 'N/A'}
                        </span>
                      )
                    return (
                      <span className="animate-fadein RightVerticalPipe">
                        {unpaidCount}
                      </span>
                    )
                  })()}
                </span>
                <span>Unpaid invoices</span>
              </DetailItem>
            </div>
            {/* Pre-payment required */}
            {!currentOrg?.setupFeePaid && <SetUpFee />}
            {/* Invoices */}
            <div className="BillingPage__invoice-container BlockWithBorder">
              <h3>Invoices</h3>
              <InvoiceTable handleReset={handleRefetch} />
            </div>
          </div>
          <div className="BillingPage__right-col">
            {/* Billing contact */}
            <BillingDetails />
            {/* Credit cards */}
            <div className="BillingPage__cc BlockWithBorder flex flex-col gap-1">
              <CreditCardManager
                loading={loading}
                creditCards={currentBillingOrg?.creditCards}
                handleReset={handleRefetch}
                error={billingError === 'error'}
              />
            </div>
          </div>
        </div>
      )}
    </PageContent>
  )
}
