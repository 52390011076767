import { Fa, NotificationBar } from '@eltoro-ui/components'
import { useAppContext, useNotificationContext } from 'Contexts'
import { GetName } from 'Helpers'
import { Orgmanagerv1Note } from 'next-gen-sdk'
import React, { useEffect, useState } from 'react'

export const OrgNotes = () => {
  const [collapsed, setCollapsed] = useState(false)
  const { isAdmin } = useAppContext()
  const { orgsNotes } = useNotificationContext()
  return (
    <div className=" w-full">
      {isAdmin &&
        orgsNotes &&
        orgsNotes.length > 0 &&
        (collapsed ? [] : [orgsNotes[orgsNotes.length - 1]]).map(
          (currentNote) => {
            if (currentNote) {
              return (
                <NotificationBar
                  key={currentNote.id}
                  className="alert-banner pt-4 pb-2 !shadow-none"
                  messageType={'info'}
                  timer={30000}
                  title={undefined}
                  message={currentNote.content}
                  autoDismiss={false}
                  dismissMessage={false} // only allow dismissal of notification alerts (not hold+billing alerts)
                  alert
                  timeStamp={currentNote.createTime?.toLocaleString()}
                  user={<GetName userId={currentNote.userId || ''} />}
                />
              )
            }
            return null
          },
        )}
      {isAdmin && orgsNotes && orgsNotes.length > 0 && (
        <div className="GlobalAlert__button--wrap pt-4">
          <button
            type="button"
            onClick={() => setCollapsed(!collapsed)}
            className="Button bg-base group z-[5000] gap-2"
          >
            <p className={collapsed ? 'mt-1' : ''}>
              {!collapsed ? 'Collapse' : 'Show Org Note'}
            </p>
            {!collapsed ? (
              <div className="bg-primary group-hover:bg-base group-hover:text-primary flex min-h-[1rem] min-w-[1rem] items-center justify-center rounded-full text-xs font-bold text-white">
                <Fa icon="chevron-up" size={1} />
              </div>
            ) : (
              <div className="bg-primary group-hover:bg-base group-hover:text-primary flex min-h-[1rem] min-w-[1rem] items-center justify-center rounded-full text-xs font-bold text-white">
                <Fa icon="chevron-down" size={1} />
              </div>
            )}
          </button>
        </div>
      )}
    </div>
  )
}
