import { useEffect, useState } from 'react'
import {
  PreviewType,
  RadioButton,
  RadioButtonGroup,
  Select,
  Text,
  Uploader,
} from '@eltoro-ui/components'
import { formatByteString } from '@eltoro-ui/helpers'
import { useNavigate } from 'react-router-dom'
import { FileRejection } from 'react-dropzone'
import { Targetjobservicev1Audience } from 'next-gen-sdk'
import classNames from 'classnames'
import {
  AudienceColumnSelector,
  UploadSuccess,
  VerticalStepper,
  NewMover,
} from 'Components'
import { useAppContext, useCampaignContext } from 'Contexts'
import {
  AddressListExample,
  IPListExample,
  MaidExample,
  ZIPListExample,
} from './formatExamples'
import './AudienceUploadStepper.scss'

export const AudienceUploadStepper = ({
  audienceType,
  onCancel,
  onAddAudienceToAttach,
  useInCampaignButton = true,
}: {
  audienceType: string
  onCancel?: () => void
  onAddAudienceToAttach?: (audience: Targetjobservicev1Audience) => void
  useInCampaignButton?: boolean
}) => {
  const { currentOrg } = useAppContext()
  const handleClickStep = (id: string) => {
    setCurrentStep(id)
  }

  const baseSteps = [
    {
      id: 'upload',
      onClick: handleClickStep,
    },
    {
      id: 'preview',
      onClick: handleClickStep,
      disabled: true,
    },
  ]
  const NewMoverBaseSteps = [
    {
      id: 'identify',
      onClick: handleClickStep,
    },
    {
      id: 'select',
      onClick: handleClickStep,
      disabled: true,
    },
    {
      id: 'name',
      onClick: handleClickStep,
      disabled: true,
    },
  ]
  const [newMoverName, setNewMoverName] = useState('')
  const [currentStep, setCurrentStep] = useState('upload')
  const [steps, setSteps] = useState<
    {
      id: string
      onClick: (id: string) => void
      disabled?: boolean
      completed?: boolean
      errored?: boolean
    }[]
  >(baseSteps)
  const [jobType, setJobType] = useState(
    audienceType === 'address' || audienceType === 'custom' ? '' : audienceType,
  )
  const [droppedFile, setDroppedFile] = useState<PreviewType>()
  const [
    //
    uploadedAudience,
    setUploadedAudience,
  ] = useState<Targetjobservicev1Audience>()
  const [highlightOptions, setHighlightOptions] = useState(false)
  const [uploadError, setUploadError] = useState<string>()
  const { launchFreshCampaign } = useCampaignContext()
  const history = useNavigate()
  const maxGB = 1024 * 1024 * 1024
  const MAX_FILE_SIZE = maxGB
  const premiumAudienceOptions = [
    { label: 'Aristotle', value: 'DATA_SOURCE_ARISTOTLE' },
    { label: 'IP+', value: 'DATA_SOURCE_ASPIRE' },
    { label: 'L2', value: 'DATA_SOURCE_L2' },
    { label: 'Venue Replay+', value: 'DATA_SOURCE_ONSPOT' },
  ]
  const handleCompleteStep = (id: string, remove = false) => {
    if (remove) {
      setSteps((prev) => {
        return prev.map((current) => {
          if (current.id === id) {
            return { ...current, completed: false }
          }
          return current
        })
      })
      setCurrentStep(id)
    } else {
      setSteps((prev) => {
        let nextIndex: number
        return prev.map((current, index) => {
          if (current.id === id) {
            nextIndex = index + 1
            return { ...current, completed: true }
          }

          if (nextIndex === index) setCurrentStep(prev[index].id)
          return {
            ...current,
            disabled: nextIndex !== index && !current.completed,
          }
        })
      })
    }
  }
  const handleError = (id: string, isError: boolean) => {
    setSteps((prev) => {
      return prev.map((current) => {
        if (current.id === id) {
          return { ...current, errored: isError }
        }
        return current
      })
    })
  }

  const handleDrop = (
    dropped: PreviewType[],
    rejectedFiles?: FileRejection[],
  ) => {
    setUploadError(undefined)
    if (rejectedFiles?.length) {
      setUploadError('File is not a valid csv')
      return
    }
    if (dropped.length > 1) {
      setUploadError('Please only upload one file at a time')
      return
    }
    const [file] = dropped
    if (file) {
      setDroppedFile(file)
      handleCompleteStep('upload')
    }
  }

  const handleOnUseInCampaign = () => {
    // clear+add to campaign context
    if (uploadedAudience) launchFreshCampaign({ audience: uploadedAudience })

    // redirect to create campaign
    history(`/campaigns/create?org_id=${currentOrg?.id}`)
  }

  const uploadComplete = steps.find(
    (step) => step.id === 'preview' || step.id === 'name',
  )?.completed

  const handleHighlightOptions = () => {
    if (
      (audienceType === 'address' || audienceType === 'custom') &&
      jobType === '' &&
      !highlightOptions
    ) {
      setHighlightOptions(true)
    }
  }

  // If new audienceType is selected, reset stepper
  useEffect(() => {
    if (audienceType === 'new-mover') {
      setSteps(NewMoverBaseSteps)
      setCurrentStep('identify')
      setJobType(audienceType)
    } else {
      setSteps(baseSteps)
      setCurrentStep('upload')
      setJobType(
        audienceType === 'address' || audienceType === 'custom'
          ? ''
          : audienceType,
      )
    }
  }, [audienceType])

  return uploadComplete ? (
    <UploadSuccess
      jobType={jobType}
      fileName={
        audienceType === 'new-mover'
          ? newMoverName
          : uploadedAudience?.name || ''
      }
      onUseInCampaign={useInCampaignButton ? handleOnUseInCampaign : undefined}
      onUploadAnother={() => {
        if (audienceType === 'new-mover') {
          setNewMoverName('')
          setSteps(NewMoverBaseSteps)
          setCurrentStep('identify')
          setJobType(audienceType)
        } else {
          setSteps(baseSteps)
          setCurrentStep('upload')
          setJobType(audienceType === 'address' ? '' : audienceType)
        }
      }}
    />
  ) : (
    <div className="AudienceUploadStepper flex">
      <div className="AudienceUploadStepper__stepper-container">
        <VerticalStepper steps={steps} />
      </div>
      <div className="AudienceUploadStepper__content w-full flex-1">
        {(() => {
          if (audienceType === 'new-mover') {
            return (
              <NewMover
                setCurrentStep={setCurrentStep}
                handleCompleteStep={handleCompleteStep}
                newMoverName={newMoverName}
                setNewMoverName={setNewMoverName}
                onAddAudienceToAttach={onAddAudienceToAttach}
              />
            )
          }
          if (currentStep === 'upload')
            return (
              <div
                className={`AudienceUploadStepper__content--upload flex flex-wrap Upload--${audienceType}`}
              >
                <div className="AudienceUploadStepper__step-info-container flex flex-1 flex-col">
                  <div className="AudienceUploadStepper__step pb-1">
                    <Text
                      className="AudienceUploadStepper__step-header"
                      tag="h4"
                    >
                      {audienceType === 'address' ? (
                        <span className="AudienceUpload__text-header">
                          <span>Select type&nbsp;</span>
                          <span>&amp; upload file</span>
                        </span>
                      ) : (
                        <span>Upload file</span>
                      )}
                    </Text>
                  </div>
                  {/* job type options (if any) */}
                  {audienceType === 'address' && (
                    <div
                      className={classNames(
                        'AudienceUploadStepper__job-type-container',
                        {
                          'AudienceUploadStepper__job-type-container--highlight': highlightOptions,
                        },
                      )}
                    >
                      <Text
                        className="IdentifyListHeader align-end flex gap-1 normal-case"
                        tag="h6"
                      >
                        <span className="font-semibold">Required:</span>
                        <span className="identity-text relative">
                          Please identify list type
                          <span className="text-tertiary-500 absolute text-lg font-semibold">
                            *
                          </span>
                        </span>
                      </Text>
                      <RadioButtonGroup
                        valueSelected={jobType}
                        name="Job type selection"
                        onChange={(newJobType) => {
                          setHighlightOptions(false)
                          setJobType(newJobType as string)
                        }}
                      >
                        <RadioButton
                          value="b2c"
                          label="B2C: Target your audience at their residences"
                        />
                        <RadioButton
                          value="b2b"
                          label="B2B: Target your audience at their businesses"
                        />
                      </RadioButtonGroup>
                    </div>
                  )}
                  {audienceType === 'custom' && (
                    <div
                      className={classNames(
                        'AudienceUploadStepper__job-type-container',
                        {
                          'AudienceUploadStepper__job-type-container--highlight': highlightOptions,
                        },
                      )}
                    >
                      <Text
                        className="IdentifyListHeader align-end flex gap-1 normal-case"
                        tag="h6"
                      >
                        <span className="font-semibold">Required:</span>
                        <span className="identity-text relative">
                          Please identify which data provider you want to use to
                          enhance the audience.
                          <span className="text-tertiary-500 absolute text-lg font-semibold">
                            *
                          </span>
                        </span>
                      </Text>
                      <Select
                        placeholder="Select Data Provider"
                        options={premiumAudienceOptions}
                        onChange={(newDataProvider) => {
                          if (newDataProvider?.value) {
                            setHighlightOptions(false)
                            setJobType(newDataProvider.value)
                          }
                        }}
                        selectedOption={premiumAudienceOptions.find(
                          (c) => c.value === jobType,
                        )}
                        maxHeight={200}
                        className="pt-4"
                      ></Select>
                    </div>
                  )}
                </div>
                <div
                  role="button"
                  onClick={handleHighlightOptions}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleHighlightOptions()
                  }}
                  tabIndex={0}
                  className={`AudienceUploadStepper__upload-click-wrapper group AudienceUploadStepper__upload-click-wrapper${
                    (audienceType === 'address' || audienceType === 'custom') &&
                    jobType === ''
                      ? '--disabled'
                      : ''
                  }`}
                >
                  <div
                    className={classNames(
                      'AudienceUploadStepper__uploader-container',
                      {
                        'AudienceUploadStepper__uploader-container--disabled':
                          (audienceType === 'address' ||
                            audienceType === 'custom') &&
                          jobType === '',
                      },
                    )}
                  >
                    <Uploader
                      accepts="file-upload"
                      onDrop={handleDrop}
                      isStyled
                    >
                      <div className="AudienceUploadStepper__uploader-text flex flex-col p-4">
                        <Text
                          className="flex flex-col text-center normal-case"
                          tag="h5"
                        >
                          {(() => {
                            if (audienceType === 'address' && jobType === '')
                              return (
                                <span className="TargetUploader__header py-1 font-normal">
                                  <span className="desktop:text-4xl block">
                                    Select list type
                                  </span>
                                  <span className="block pt-2 text-lg">
                                    <span className="inline-block">
                                      <strong>B2C: </strong>Business to Customer
                                    </span>
                                    <span className="desktop:inline-block mobile:block mobile:py-2 pl-2">
                                      or
                                    </span>
                                    <span className="ml-2 inline-block">
                                      <strong> B2B: </strong>
                                      Business to Business
                                    </span>
                                  </span>
                                </span>
                              )
                            if (audienceType === 'custom' && jobType === '')
                              return (
                                <span className="TargetUploader__header py-1 font-normal">
                                  <span className="desktop:text-4xl block">
                                    Please Select Data Provider
                                  </span>
                                </span>
                              )
                            return (
                              <>
                                <span className="TargetUploader__header animate-slidedown py-1 font-medium">
                                  <span className="desktop:text-3xl block">
                                    Drag and drop file here
                                  </span>
                                  <span className="desktop:text-2xl font-light">
                                    or click to select a file
                                  </span>
                                </span>
                                <Text
                                  className="py-2 text-lg font-light uppercase"
                                  tag="p"
                                >
                                  Max file size:{' '}
                                  {formatByteString(MAX_FILE_SIZE)}
                                </Text>
                              </>
                            )
                          })()}
                        </Text>
                        {uploadError && (
                          <Text className="animate-slidedown text-m text-warning mt-2 font-bold">
                            {uploadError}
                          </Text>
                        )}
                        {jobType !== '' && audienceType.length > 0 && (
                          <div className="AudienceUploadStepper__format-example animate-slideup">
                            <Text
                              className="pb-2 normal-case leading-none"
                              tag="h6"
                            >
                              Formatting example:
                            </Text>
                            <code>
                              {((): React.ReactNode => {
                                if (
                                  audienceType === 'address' ||
                                  audienceType === 'custom' ||
                                  audienceType === 'dc'
                                )
                                  return <AddressListExample />
                                if (
                                  audienceType === 'ip' ||
                                  audienceType === 'reverseIp' ||
                                  audienceType === 'ipForMailing'
                                )
                                  return <IPListExample />
                                if (audienceType === 'device')
                                  return <MaidExample />
                                if (audienceType === 'zip')
                                  return <ZIPListExample />
                                return null
                              })()}
                            </code>
                          </div>
                        )}
                      </div>
                    </Uploader>
                  </div>
                </div>
              </div>
            )
          if (currentStep === 'preview')
            return (
              <div className="AudienceUploadStepper__preview flex flex-wrap">
                <div className="AudienceUploadStepper__step-info-container flex flex-col gap-2">
                  <Text className="AudienceUploadStepper__step-header" tag="h4">
                    Preview list
                  </Text>
                </div>
                <div className="AudienceUploadStepper__column-selector-container w-full">
                  {droppedFile && jobType && (
                    <AudienceColumnSelector
                      audienceType={audienceType}
                      jobType={jobType}
                      droppedFile={droppedFile}
                      onError={(isError) => {
                        handleError('preview', isError)
                      }}
                      onCancel={onCancel}
                      onBack={() => {
                        setCurrentStep('upload')
                        setSteps(baseSteps)
                      }}
                      onUploadComplete={(audience) => {
                        setUploadedAudience(audience)
                        handleCompleteStep('preview')
                        if (onAddAudienceToAttach && audience.id)
                          onAddAudienceToAttach(audience)
                      }}
                    />
                  )}
                </div>
              </div>
            )
          return null
        })()}
      </div>
    </div>
  )
}
