import React, { useState } from 'react'
import classNames from 'classnames'
import {
  Button,
  CollapsibleContainer,
  DateRangePicker,
  Fa,
  TextInput,
  Toggle,
  Tooltip,
} from '@eltoro-ui/components'
import { formatDate, styleTailwind } from 'Helpers'
import { saveCampaignFlightDates, saveCampaignName } from 'Requests'
import { useAppContext, useCampaignContext } from 'Contexts'
import { AssetHeader } from '../../ShoppingCart'

type CurrentlyEditingType = 'name' | 'dates' | 'political' | undefined

const CenterFlex = styleTailwind(
  'div',
  'CampaignDetails__center-flex flex items-center gap-1 mr-3',
)
const CamDetail = styleTailwind(
  'div',
  'CampaignDetails flex items-center pr-6 justify-end flex-1 font-bold',
)

const EditableDetailSection = ({
  children,
  editing,
}: {
  children: React.ReactNode
  editing: boolean
}) => (
  <div
    className={classNames(
      'CampaignDetails__editable-detail-section border-t-thin border-tint-gray-200 my-1 items-center pt-1',
      {
        'bg-tint-khaki-100 mb-3 pl-3 shadow-md': editing,
      },
    )}
  >
    {children}
  </div>
)

const CampaignDetailsEditButton = ({
  field,
  editing,
  isFocused,
  setEditing,
}: {
  field: CurrentlyEditingType
  editing: CurrentlyEditingType
  isFocused: boolean
  setEditing: React.Dispatch<React.SetStateAction<CurrentlyEditingType>>
}) => (
  <Tooltip
    className="CampaignDetails__lock-icon-wrap text-primary ml-auto"
    position="bottomLeft"
    content={`Click to ${editing && editing === field ? 'close' : 'edit'}`}
  >
    <Button
      iconOnly={
        <Fa icon={editing && editing === field ? 'times' : 'pencil'} size={1} />
      }
      onClick={() =>
        editing === field ? setEditing(undefined) : setEditing(field)
      }
      className={classNames(
        `CampaignDetails__edit-btn-${field} ml-auto font-bold uppercase`,
        {
          'opacity-100': isFocused,
          'opacity-30': !isFocused,
        },
      )}
    />
  </Tooltip>
)

export const CampaignDetails = () => {
  const {
    campaign,
    setCampaign,
    isPolitical,
    handlePolitical,
    start_date,
    end_date,
    orderLines,
  } = useCampaignContext()
  const { currentOrg } = useAppContext()
  const [editing, setEditing] = useState<CurrentlyEditingType>()
  const [isFocused, setIsFocused] = useState(false)
  const [loading, setLoading] = useState(false)
  const [campaignNameInput, setCampaignNameInput] = useState(
    campaign?.name || '',
  )
  const [flightDatesInput, setFlightDatesInput] = useState<{
    startDate?: Date
    endDate?: Date
  }>({
    startDate: start_date ? new Date(start_date) : undefined,
    endDate: end_date ? new Date(end_date) : undefined,
  })

  const editButtonProps = { editing, isFocused, setEditing }

  const hasLiveOrderLines =
    orderLines &&
    !orderLines.every((ol) => ol.status === 'ORDERLINE_STATUS_DRAFT')

  return (
    <div
      className="CampaignDetails"
      onFocus={() => setIsFocused(true)}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
    >
      <CenterFlex>
        <AssetHeader>Campaign Details</AssetHeader>
        <Tooltip
          className="CampaignDetails__lock-icon-wrap text-primary ml-auto -mr-1 -mb-1"
          position="bottomLeft"
          content="Editing is locked. Please edit eligible order lines
          using the edit menu."
        >
          <Fa
            icon="lock"
            size={1}
            className="CampaignDetails__lock-icon text-primary m-1"
          />
        </Tooltip>
      </CenterFlex>
      {/* Name */}
      <EditableDetailSection editing={editing === 'name'}>
        <CenterFlex>
          <span>Name:</span>
          <CamDetail>{campaign?.name || 'New Campaign'}</CamDetail>
          {/* {!hasLiveOrderLines && (
            <CampaignDetailsEditButton field="name" {...editButtonProps} />
          )} */}
        </CenterFlex>
        <CollapsibleContainer
          isOpen={editing === 'name'}
          className={classNames({
            hidden: editing !== 'name',
          })}
        >
          <CenterFlex className="pt-2 pb-4">
            <TextInput
              value={campaignNameInput}
              onChange={(e) => setCampaignNameInput(e.target.value)}
            />
            <Button
              iconOnly={
                <Fa
                  className={classNames('EditButton disabled:text-grey-600', {
                    'animate-spin': loading,
                  })}
                  icon={loading ? 'circle-notch' : 'save'}
                  size={1}
                />
              }
              disabled={
                campaignNameInput === '' ||
                loading ||
                campaignNameInput === campaign?.name
              }
              onClick={() => {
                if (!campaign?.id || !currentOrg?.id) return
                setLoading(true)
                saveCampaignName(campaignNameInput, campaign.id, currentOrg.id)
                  .then((campaign) => {
                    setEditing(undefined)
                    if (campaign) setCampaign(campaign)
                  })
                  .finally(() => {
                    setLoading(false)
                  })
              }}
            />
          </CenterFlex>
        </CollapsibleContainer>
      </EditableDetailSection>
      {/* Flight dates */}
      <EditableDetailSection editing={editing === 'dates'}>
        <CenterFlex>
          Flight Dates:
          <CamDetail>
            {start_date && formatDate(new Date(start_date))}
            -&nbsp;
            {end_date && formatDate(new Date(end_date))}
          </CamDetail>
        </CenterFlex>
        <CollapsibleContainer
          isOpen={editing === 'dates'}
          className={classNames({
            hidden: editing !== 'dates',
          })}
        >
          <CenterFlex className="pt-2 pb-4">
            <DateRangePicker
              min={new Date()}
              startDate={flightDatesInput.startDate || new Date()}
              endDate={flightDatesInput.endDate || new Date()}
              onStartDateChange={(start) => {
                setFlightDatesInput((prev) => {
                  return { ...prev, startDate: start }
                })
              }}
              onEndDateChange={(end) => {
                setFlightDatesInput((prev) => {
                  return { ...prev, endDate: end }
                })
              }}
            />
            {/* <Button
              className="disabled:text-grey-600"
              size="l"
              iconOnly={
                <Fa
                  className={classNames('EditButton disabled:text-grey-600', {
                    'animate-spin': loading,
                  })}
                  icon={loading ? 'circle-notch' : 'save'}
                  size={1}
                />
              }
              disabled={
                loading ||
                !flightDatesInput.startDate ||
                !flightDatesInput.endDate
              }
              onClick={() => {
                setLoading(true)
                if (
                  campaign?.id &&
                  flightDatesInput.startDate &&
                  flightDatesInput.endDate &&
                  currentOrg?.id
                )
                  saveCampaignFlightDates(
                    flightDatesInput.startDate,
                    flightDatesInput.endDate,
                    campaign?.orderLines || [],
                    campaign.id,
                    currentOrg.id,
                  )
                    .then((campaign) => {
                      setEditing(undefined)
                      if (campaign) setCampaign(campaign)
                    })
                    .finally(() => setLoading(false))
              }}
            /> */}
          </CenterFlex>
        </CollapsibleContainer>
      </EditableDetailSection>
      {/* Political */}
      <EditableDetailSection editing={editing === 'political'}>
        <CenterFlex className="pb-4">
          Political:
          <CamDetail>{isPolitical ? 'Yes' : 'No'}</CamDetail>
          {/* {!hasLiveOrderLines && (
            <CampaignDetailsEditButton field="political" {...editButtonProps} />
          )} */}
        </CenterFlex>
        <CollapsibleContainer
          isOpen={editing === 'political'}
          className={classNames({
            hidden: editing !== 'political',
          })}
        >
          <CenterFlex className="pt-2 pb-4">
            <div className="ml-auto -mt-3 flex flex-row-reverse items-center gap-2">
              <Toggle
                size="s"
                isChecked={isPolitical}
                onChange={handlePolitical}
              />
              This is {isPolitical ? 'a political ' : 'not a political '}
              campaign
            </div>
          </CenterFlex>
        </CollapsibleContainer>
      </EditableDetailSection>
    </div>
  )
}
