import {
  Campaignservicev1CPM,
  GoogletypeMoney,
  Orgmanagerv1CPM,
} from 'next-gen-sdk'
import { CPMObjectType } from 'types'

export const getTypeMoneyCost = (
  typeMoney:
    | Partial<Orgmanagerv1CPM>
    | Partial<GoogletypeMoney>
    | Partial<Campaignservicev1CPM>,
  upcharge?: Partial<GoogletypeMoney | undefined>[],
) => {
  if (upcharge?.length === 1) {
    return (
      Number(upcharge[0]?.units || 0) +
      Number(typeMoney?.units || 0) +
      ((typeMoney?.nanos || 0) + (upcharge[0]?.nanos || 0)) / 10 ** 9
    )
  }
  if (upcharge && upcharge?.length > 1) {
    const combinedUpcharges = upcharge.reduce((a, b) => {
      const newUnits = (Number(a?.units) || 0) + (Number(b?.units) || 0)
      const newNanos = (a?.nanos || 0) + (b?.nanos || 0)
      return {
        nanos: newNanos,
        units: newUnits.toString(),
      }
    })
    return (
      Number(combinedUpcharges?.units || 0) +
      Number(typeMoney?.units || 0) +
      ((typeMoney?.nanos || 0) + (combinedUpcharges?.nanos || 0)) / 10 ** 9
    )
  }
  // this is needed because prettier will make this a crazy number without the parens.
  // prettier-ignore
  return +(typeMoney?.units || 0) + ((typeMoney?.nanos || 0) / 10 ** 9)
}

export const separatePoliticalCPMs = (cpms: CPMObjectType) => {
  const politicalCPMs = Object.fromEntries(
    Object.entries(cpms).filter(([key]) => key.includes('political')),
  )
  const nonPoliticalCPMs = Object.fromEntries(
    Object.entries(cpms).filter(([key]) => !key.includes('political')),
  )
  return { nonPoliticalCPMs, politicalCPMs }
}
