import { useState } from 'react'
import {
  Avatar,
  TextInput,
  Fa,
  Button,
  Pagination,
} from '@eltoro-ui/components'
import {
  useDebounce,
  usePagination,
  useTokenPagination,
} from '@eltoro-ui/hooks'
import classNames from 'classnames'
import { Orgmanagerv1Org, V1OrgData } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import './OrgSelect.scss'
import { LinkButton } from 'Components/LinkButton'

type SplitSearchProps = {
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  error: boolean
  setError: React.Dispatch<React.SetStateAction<boolean>>
  debouncedSearch: string
  onOrgSelect: (newOrgId: string) => void
  setLoadingOrgChange: React.Dispatch<React.SetStateAction<string>>
  loadingOrgChange: string
}

const OrgListWithSearch = ({
  loading,
  error,
  orgs,
  setError,
  searchText,
  setSearchText,
  setLoadingOrgChange,
  onOrgSelect,
  loadingOrgChange,
  paginationFooter,
  refetch,
}: {
  loading: boolean
  orgs: V1OrgData[] | Orgmanagerv1Org[]
  paginationFooter: React.ReactNode
  refetch: () => void
} & SplitSearchProps) => {
  const { currentOrg, setCurrentOrg, orgServiceApi } = useAppContext()

  const padOrgs = (orgs: V1OrgData[] | Orgmanagerv1Org[]) => {
    if (orgs.length === 10) return orgs
    return [...orgs, ...Array(10 - orgs.length).fill({})]
  }
  return (
    <div
      className={classNames(
        'OrgSelect__org-list relative flex min-h-[4rem] flex-col overflow-x-hidden pt-2',
        { 'pointer-events-none': loading },
      )}
    >
      {loading && (
        <div className="OrgSelect__loading absolute top-0 bottom-0 right-0 left-0 z-40 overflow-clip p-2">
          <Fa
            className="text-tint-khaki"
            icon="spinner"
            size={2}
            animate="spin"
          />
        </div>
      )}
      {!orgs.length && error && (
        <div className="flex min-h-[41vh] flex-col items-center gap-4 py-3">
          <Fa icon="warning" size={3} />
          <p>Error getting results</p>
          <LinkButton
            text="Retry"
            onClick={() => {
              refetch()
              setError(false)
            }}
          />
        </div>
      )}
      {!error && orgs.length === 0 && !loading && searchText && (
        <div className="flex flex-col items-center gap-4 py-3">
          <Fa icon="magnifying-glass" size={3} />
          <p>No results for {searchText}</p>
          <LinkButton
            text="Clear search"
            onClick={() => {
              setSearchText('')
              setError(false)
            }}
          />
        </div>
      )}
      {!error &&
        padOrgs(orgs).map((org, index) => {
          return (
            <button
              type="button"
              onClick={async () => {
                if ((!org.id && !org.orgId) || !orgServiceApi) return
                setLoadingOrgChange(org.id || org.orgId)
                orgServiceApi
                  .advertisingPlatformServiceGetOrg(org.id || org.orgId)
                  .then((org) => {
                    setCurrentOrg(org)
                    setLoadingOrgChange('')
                    if (org.id) onOrgSelect(org.id)
                  })
              }}
              className={`OrgSelect__org-button flex-1 focus:ring-2 ${
                currentOrg?.id === org.id ? 'OrgSelect__org-button--active' : ''
              } ${!org.id && !org.orgId ? 'hidden opacity-0' : ''} relative`}
              key={`${org.id || org.orgId}_${index}`}
              disabled={!org.id && !org.orgId}
            >
              {(() => {
                if (org.logo?.key) {
                  return (
                    <Avatar
                      src={`https://s3.amazonaws.com/c.eltoro.com/${org.logo.key}`}
                      rounded
                      size="s"
                    />
                  )
                }
                return <Avatar name={org.name || ''} rounded size="s" />
              })()}
              <span className="OrgSelect__org-button-name text-s text-tint-gray-700 inline-block truncate">
                {org.name}
              </span>
              {loadingOrgChange === (org.id || org.orgId) && (
                <Fa
                  icon="spinner"
                  size={1}
                  className="absolute right-4 animate-spin"
                  color="var(--base-info)"
                />
              )}
            </button>
          )
        })}
      {paginationFooter}
    </div>
  )
}

const TokenPaginated = ({
  searchText,
  setSearchText,
  error,
  setError,
  onOrgSelect,
  setLoadingOrgChange,
  loadingOrgChange,
  debouncedSearch,
}: SplitSearchProps) => {
  const { orgServiceApi } = useAppContext()

  const handleFetchOrgs = async ({
    pageSize,
    nextPageToken,
  }: {
    pageSize: number
    nextPageToken?: string
  }) => {
    const empty = {
      data: [],
      totalItems: 0,
    }
    if (!orgServiceApi) return empty
    setError(false)
    const res = await orgServiceApi
      .advertisingPlatformServiceListOrgs(
        pageSize, // pageSize
        nextPageToken, // pageToken
        'create_time desc', // orderBy
        undefined, // filter
        true, // skipPreload
      )
      .catch(() => setError(true))

    if (res && res?.orgs) {
      return {
        data: res.orgs || [],
        totalItems: res.totalSize || 0,
        nextPageToken: res.nextPageToken,
      }
    }
    return empty
  }

  const pagination = useTokenPagination<Orgmanagerv1Org>(
    {
      pageSize: 10,
      fetchData: handleFetchOrgs,
    },
    [orgServiceApi],
  )

  const { currentPageData: orgs, loading, setPage, refetch } = pagination

  const props = {
    searchText,
    setSearchText,
    setError,
    error,
    onOrgSelect,
    setLoadingOrgChange,
    loadingOrgChange,
    loading,
    orgs,
    debouncedSearch,
    refetch,
  }

  return (
    <OrgListWithSearch
      {...props}
      paginationFooter={
        !error &&
        orgs.length > 0 && (
          <Pagination
            {...pagination}
            onChange={(pageInfo) => {
              setPage(pageInfo.page)
            }}
          />
        )
      }
    />
  )
}

const GlobalSearchPaginated = ({
  searchText,
  setSearchText,
  error,
  setError,
  debouncedSearch,
  onOrgSelect,
  setLoadingOrgChange,
  loadingOrgChange,
}: SplitSearchProps) => {
  const [allGlobalSearchOrgs, setAllGlobalSearchOrgs] = useState<{
    orgs: V1OrgData[]
    totalSize: number
    term: string
  }>()

  const { orgServiceApi, searchServiceApi } = useAppContext()

  const handleFetchOrgs = async ({
    page,
    pageSize,
  }: {
    page: number
    pageSize: number
  }) => {
    const empty = {
      data: [],
      totalItems: 0,
    }
    if (!orgServiceApi) return empty
    setError(false)
    if (!allGlobalSearchOrgs || debouncedSearch !== allGlobalSearchOrgs.term) {
      const res = await searchServiceApi
        ?.advertisingPlatformServiceGlobalSearch(
          undefined, // orgIds
          [debouncedSearch], // keywords
        )
        .catch(() => setError(true))

      if (res && res?.orgs) {
        setAllGlobalSearchOrgs({
          orgs: res.orgs || [],
          totalSize: res.orgs.length || 0,
          term: debouncedSearch,
        })
        return {
          data: res.orgs.slice(0, 10) || [],
          totalItems: res.orgs.length || 0,
          resetPage: 1,
        }
      }
    }

    if (
      debouncedSearch &&
      allGlobalSearchOrgs &&
      allGlobalSearchOrgs.term === debouncedSearch
    ) {
      // paginate the existing
      const start = pageSize * (page - 1)
      const offset = page * pageSize
      return {
        data: allGlobalSearchOrgs.orgs.slice(start, offset) || [],
        totalItems: allGlobalSearchOrgs.orgs.length || 0,
      }
    }
    return empty
  }

  const pagination = usePagination<V1OrgData>(
    {
      pageSize: 10,
      fetchData: handleFetchOrgs,
    },
    [debouncedSearch, orgServiceApi, searchServiceApi],
  )

  const { data: orgs, loading, setPage, refetch } = pagination

  const props = {
    searchText,
    setSearchText,
    setError,
    error,
    onOrgSelect,
    setLoadingOrgChange,
    loadingOrgChange,
    loading,
    orgs,
    debouncedSearch,
    refetch,
  }

  return (
    <OrgListWithSearch
      {...props}
      paginationFooter={
        !error &&
        orgs.length > 0 && (
          <Pagination
            {...pagination}
            onChange={(pageInfo) => {
              setPage(pageInfo.page)
            }}
          />
        )
      }
    />
  )
}

export const OrgSelect = ({
  onOrgSelect,
}: {
  onOrgSelect: (newOrgId: string) => void
}) => {
  const [searchText, setSearchText] = useState('')
  const [loadingOrgChange, setLoadingOrgChange] = useState('')
  const [error, setError] = useState(false)
  const debouncedSearch = useDebounce(searchText, 500)

  const props = {
    searchText,
    setSearchText,
    loadingOrgChange,
    setLoadingOrgChange,
    error,
    setError,
    debouncedSearch,
    onOrgSelect,
  }

  return (
    <div className="OrgSelect">
      <div className="OrgSelect__search flex items-center px-2">
        <TextInput
          iconLeft={<Fa icon="search" size={1} />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {searchText && (
          <>
            <Button
              iconOnly={<Fa icon="times" size={1} />}
              onClick={() => setSearchText('')}
              kind="text"
              size="s"
            />
          </>
        )}
      </div>
      {debouncedSearch ? (
        <GlobalSearchPaginated {...props} />
      ) : (
        <TokenPaginated {...props} />
      )}
    </div>
  )
}
