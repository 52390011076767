import { RefObject, useEffect, useState } from 'react'

// Taken from: https://usehooks.com/useOnScreen/
export const useOnScreen = (
  ref: RefObject<HTMLElement> | undefined | null,
  margin?: string,
) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState<boolean>(false)
  useEffect(() => {
    const rootMargin = margin || '0px'
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      },
    )
    if (ref?.current) {
      observer.observe(ref.current)
    }
    return () => {
      if (ref?.current) observer.unobserve(ref.current)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting
}
