export const uploaderContainerHeaderText = [
  {
    id: 'address',
    title: 'IP Targeting:',
    subTitle: 'Address to IP',
    description:
      'Upload your address list in the form of a .csv file with at least an address and a ZIP code column. If there are other columns, they will be ignored by our system.',
  },
  {
    id: 'dc',
    title: 'Digital Canvassing:',
    subTitle: 'Address to Neighboring IP Address',
    description:
      'Provide a list of addresses and generate new audience with nearby homes for targeting. Upload your address list in the form of a .csv file with at least an address and a zip code column. If there are other columns, they will be ignored by our system',
  },
  {
    id: 'ip',
    title: 'IP Address List:',
    subTitle: 'Advertise directly to IP addresses',
    description:
      'Provide a list of IP addresses and serve digital ads to the devices connected to it. The .csv file should contain a single column of IPv4 addresses',
  },
  {
    id: 'new-mover',
    title: 'Digital New Mover:',
    subTitle: 'Reach households on the move just as they need you most',
    description:
      'Provide a list of ZIP codes and identify households in one of three states of the moving cycle: pre mover (house just listed), escrow (home sold), post-movers (moved to new location)',
  },
  {
    id: 'zip',
    title: 'ZIP Code List:',
    subTitle: 'Advertise to IP addresses within selected ZIP codes',
    description:
      "Provide a list of ZIP codes you'd like to target. Our algorithm will match targetable IPs within the ZIP codes provided. The .csv file should contain a single column of ZIP Codes",
  },
  {
    id: 'reverseIp',
    title: 'Reverse IP:',
    subTitle: 'Get physical locations of IP Addresses',
    description:
      'Provide a list of IPs and our algorithm will return physical addresses of those that are in our ecosystem',
  },
  {
    id: 'ipForMailing',
    title: 'IP List for Mailing:',
    subTitle: 'Get physical locations of IP addresses for mailing',
    description:
      'Provide a list of IPs and our algorithm will return physical addresses of those that are in our ecosystem',
  },
  {
    id: 'device',
    title: 'Device ID List:',
    subTitle: 'Upload lists of IDs for device-level targeting',
    description:
      'Provide a list of MAIDs or Device IDs and our algorithm will allow you to target devices by MAID or device ID',
  },
  {
    id: 'retargeting',
    title: 'Retargeting Pixel:',
    subTitle: 'Re-engage website visitors with digital ads',
    description:
      'Retargeting website users who visited your website previously, but did not become your customers',
  },
  {
    id: 'custom',
    title: 'Custom Audiences',
    subTitle: 'Use 3rd Party data to enhance an Audience',
    description:
      'Using 3rd party data to enhance a client provided list using demographics and other qualifying attributes',
  },
]
