import { useState } from 'react'
import { V1CreditCard } from 'next-gen-sdk'
import {
  TextHeader,
  Text,
  Button,
  Fa,
  Loader,
  triggerPrompt,
} from '@eltoro-ui/components'
import { useAppContext } from 'Contexts'
import { CCModal } from 'Components'
import { CCListItem } from './Components'
import './CreditCardManager.scss'

export const CreditCardManager = ({
  loading,
  handleReset,
  creditCards,
  error,
}: {
  loading: boolean
  handleReset: (type: 'credit-cards' | 'balance') => void
  error: boolean
  creditCards?: V1CreditCard[]
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [editingCard, setEditingCard] = useState<V1CreditCard>()
  const { roles, billingServiceApi, currentOrg, isReadOnly } = useAppContext()

  const AddNewCardButton = ({ size }: { size: 's' | 'm' | 'l' | 'xl' }) => {
    const hasMaxCards = creditCards && creditCards.length >= 10
    return (
      <span
        className="AddNewCardButton mb-2"
        aria-label={(() => {
          if (hasMaxCards)
            return 'You currently have the maximum of 10 stored credit cards.'
          return 'Click to add a new credit card'
        })()}
        data-tooltip="top"
      >
        <Button
          type="button"
          disabled={hasMaxCards}
          size={size}
          onClick={() => setOpenModal(true)}
          className="px-4"
        >
          Add a card
        </Button>
      </span>
    )
  }

  const handleDeleteCard = (cc: V1CreditCard) => {
    triggerPrompt(
      'Delete credit card',
      <p>
        Are you sure you want to delete the <strong>{cc.type}</strong> credit
        card ending in <strong>{cc.cardNumber}</strong>?
      </p>,
      () => {
        if (currentOrg?.id && cc.id && billingServiceApi) {
          billingServiceApi
            .advertisingPlatformServiceDeleteCreditCard(currentOrg.id, cc.id)
            .then(handleReset)
        }
      },
    )
  }

  return (
    <>
      <div className="CreditCardManager CreditCardManager_title flex w-full justify-between gap-2">
        <div className="CreditCardManager__header flex w-full items-center justify-start gap-3">
          <TextHeader type={3}>Credit card</TextHeader>
          <span
            aria-label="You may store up to 10 credit cards. Please note it may take a moment
          to fully update your requests. If you have questions, please contact
          billing@eltoro.com."
            data-tooltip="bottom"
          >
            <Fa className="text-base-info" icon="question-circle" size={1} />
          </span>
        </div>
        {creditCards &&
          creditCards.length > 0 &&
          creditCards.length < 10 &&
          !loading &&
          !isReadOnly && <AddNewCardButton size="s" />}
      </div>
      {(() => {
        if (loading) return <Loader />
        if (!loading && error)
          return (
            <div className="CreditCardManager__error py-5 text-center text-lg leading-8">
              <div className="stack">
                <Fa
                  icon="credit-card"
                  size={9}
                  color="var(--color-primary-100)"
                  className="mb-4"
                />
                <Fa
                  icon="triangle-exclamation"
                  size={5}
                  type="duotone"
                  className="mb-4 opacity-70"
                />
              </div>
              <p className="text-danger font-bold">
                Error loading credit cards
              </p>
            </div>
          )
        return (
          <div
            className={`CreditCard__list-wrap flex flex-col ${
              creditCards && creditCards.length === 0 ? 'EmptyCCList' : ''
            }`}
          >
            {creditCards && creditCards.length > 0 ? (
              <>
                {creditCards.map((CC) => {
                  return (
                    <CCListItem
                      key={CC.id}
                      cc={CC}
                      onUpdateCard={() => {
                        setOpenModal(true)
                        // for loading the existing card info to the modal
                        setEditingCard(CC)
                      }}
                      onDeleteCard={() => handleDeleteCard(CC)}
                      disableEdit={roles?.includes('nextgen_read_only')}
                    />
                  )
                })}
              </>
            ) : (
              <div className="CreditCardManager__empty-state flex flex-col items-center gap-2">
                <Fa icon="credit-card" size={9} className="text-grey-200" />
                <TextHeader type={4}>You have no cards saved yet</TextHeader>
                {!isReadOnly && (
                  <>
                    <Text>
                      Add a payment method below for a speedy checkout
                    </Text>
                    <AddNewCardButton size="l" />
                  </>
                )}
              </div>
            )}
          </div>
        )
      })()}

      {openModal && (
        <CCModal
          handleClose={() => {
            setOpenModal(false)
            setEditingCard(undefined)
          }}
          onSave={() => handleReset('credit-cards')}
          currentlyEditingCard={editingCard}
        />
      )}
    </>
  )
}
