import React, { useEffect, useState } from 'react'
import {
  Button,
  Fa,
  showErrorMessage,
  showSuccessMessage,
  TextHeader,
} from '@eltoro-ui/components'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import ReactDatePicker from 'react-datepicker'
import { useAppContext } from 'Contexts'
import { dayjs } from 'Tools/dateUtils'

const Picker: React.FC<{
  type: 'start' | 'end'
  date: Date | null
  setDate: React.Dispatch<React.SetStateAction<Date | null>>
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
}> = ({ type, date, setDate, minDate, maxDate, disabled }) => (
  <div className="OrderLineFlightDatesEdit__picker flex w-full flex-col gap-2">
    <div className="mb-4 flex w-full items-center justify-center gap-4">
      <strong className="whitespace-nowrap">Adjust {type} date:</strong>
      <div className="OrderLineFlightDatesEdit__picker-wrapper relative flex-1">
        {/* @ts-ignore */}
        <ReactDatePicker
          wrapperClassName="w-full"
          className="border-grey-200 text-m w-full border-2 p-2 outline-none focus:ring"
          onChange={(e) => setDate(e)}
          maxDate={maxDate}
          minDate={minDate}
          selected={date}
          dateFormat="MM/dd/yyyy"
          portalId="root"
          popperPlacement="bottom"
          disabled={disabled}
          calendarClassName="OrderLineFlightDatesEdit__picker"
        />
        <Fa
          icon="calendar"
          size={1}
          className="pointer-events-none absolute right-1 top-0 hover:cursor-pointer"
          type="duotone"
        />
      </div>
    </div>
  </div>
)

export const OrderLineFlightDatesEdit: React.FC<{
  orderLine: Campaignservicev1OrderLine
  onUpdateOrderLine: (updated: Campaignservicev1OrderLine) => void
  onCancel: () => void
}> = ({ orderLine, onUpdateOrderLine, onCancel }) => {
  const dateFormatter = (date: Date | undefined) => {
    if (!date) return null
    return new Date(dayjs(date).tz().format('MM/DD/YYYY'))
  }
  const [start, setStart] = useState<Date | null>(
    dateFormatter(orderLine?.startTime),
  )
  const [end, setEnd] = useState<Date | null>(dateFormatter(orderLine.endTime))
  const [loading, setLoading] = useState(false)

  const { campaignServiceApi, isLeadershipOrDev } = useAppContext()

  const maximumStartDate = (() => {
    if (isLeadershipOrDev) return undefined
    return end && start && start.getTime() < end.getTime() ? end : new Date()
  })()
  const minimumEndDate = (() => {
    if (isLeadershipOrDev) return new Date()
    return start && start.getTime() > new Date().getTime() ? start : new Date()
  })()

  const handleSaveChanges = async () => {
    if (orderLine.id && start && end && campaignServiceApi && orderLine.orgId) {
      const flightBody =
        (orderLine.status !== 'ORDERLINE_STATUS_DEPLOYED' &&
          orderLine.status !== 'ORDERLINE_STATUS_SERVING' &&
          orderLine.status !== 'ORDERLINE_STATUS_PAUSED' &&
          orderLine.state !== 'ORDERLINE_STATE_ACTIVE') ||
        isLeadershipOrDev
          ? {
              startTime: dayjs.tz(start).startOf('day').utc().toDate(),
              endTime: dayjs.tz(end).endOf('day').utc().toDate(),
            }
          : {
              endTime: dayjs.tz(end).endOf('day').utc().toDate(),
            }
      setLoading(true)
      campaignServiceApi
        .advertisingPlatformServiceUpdateOrderLine(
          orderLine.id,
          orderLine.orgId,
          flightBody,
        )
        .then((updated) => {
          showSuccessMessage('Flight Date successfully updated', '')
          setLoading(false)
          onUpdateOrderLine(updated)
        })
        .catch((err) => {
          if (err) {
            showErrorMessage('Flight Date update has failed', '')
          }
        })
    }
  }
  return (
    <div className="OrderLineFlightDatesEdit">
      <div className="OrderLineFlightDatesEdit__date-pickers flex flex-wrap justify-center gap-2">
        <TextHeader
          type={4}
          className="border-b-thin border-primary mb-2 w-full pb-2"
        >
          Edit duration
        </TextHeader>
        {((orderLine.status === 'ORDERLINE_STATUS_DRAFT' &&
          orderLine.startTime &&
          orderLine.startTime.getTime() > new Date().getTime()) ||
          isLeadershipOrDev) && (
          <Picker
            type="start"
            date={start}
            setDate={setStart}
            minDate={new Date()}
            maxDate={maximumStartDate}
            disabled={loading}
          />
        )}
        <Picker
          type="end"
          date={end}
          setDate={setEnd}
          minDate={minimumEndDate}
          disabled={loading}
        />
      </div>
      <div className="OrderLineFlightDatesEdit__buttons flex items-center justify-end gap-2">
        <Button type="submit" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="button"
          kind="primary"
          onClick={() => handleSaveChanges()}
          disabled={loading}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
