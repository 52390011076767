import { useEffect, useState } from 'react'
import BWPic from 'Assets/building_websites.svg'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import {
  Button,
  FormItem,
  Fa,
  TextHeader,
  TextInput,
  RadioButton,
  RadioButtonGroup,
  CollapsibleContainer,
} from '@eltoro-ui/components'
import { useAppContext, useCampaignContext } from 'Contexts'
import {
  ErrorMessage,
  isValidCharLength,
  styleTailwind,
  validCheck,
} from 'Helpers'
import { ETIcon } from 'Assets'
import { PrePayMsg } from 'Components/PrePayMsg'
import { OrgNotes } from 'Components/OrgNotes'

const CampaignLabel = styleTailwind(
  'div',
  'Campaign__label flex flex-1 flex-col leading-none mobile:max-content mobile:w-full max-w-14 w-[14vw]',
)

const IconWrap = styleTailwind(
  'figure',
  'Campaign__icon-wrap flex items-center justify-center h-10 w-10',
)

const CampaignLabelWrap = styleTailwind(
  'div',
  'Campaign__label-wrap flex leading-none gap-3 mobile:w-52',
)

const CampaignInputWrap = styleTailwind(
  'div',
  'Campaign__input-wrap flex flex-1 leading-none items-start mobile:w-full w-2/3',
)

const CampaignHeader = styleTailwind(
  TextHeader,
  'CampaignSplash_Header-text w-full text-center leading-none',
)

const CampaignDetailRow = styleTailwind(
  'div',
  'Campaign__detail-row mobile:gap-0 mobile:flex-col mobile:items-center flex gap-[2vw] laptop:flex-1 justify-start w-full laptop:px-1 items-start',
)
// the initial campaign create and initial orderline create will be controlled through this component
export const CreateCampaignSplash = () => {
  const { currentOrg, roles, isAdmin } = useAppContext()
  const {
    campaign_name,
    setIsPolitical,
    handleCampaignCreation,
  } = useCampaignContext()
  const auth = useAuth()
  const navigate = useNavigate()
  const [campaignName, setCampName] = useState<string>('')
  const [political, setPolitical] = useState<boolean>(false)
  const [campaignJobId, setCampaignJobId] = useState('')
  const [campaignPO, setCampaignPO] = useState('')
  const [showOptionalFields, setShowOptionalFields] = useState(false)
  const isDisabled = campaignName?.length <= 1 || (!currentOrg && !campaignName)
  const lengthLimit = campaignName?.length > 255
  const user = auth.user?.profile
  useEffect(() => {
    if (roles && roles.includes('nextgen_read_only')) {
      const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
      navigate({ pathname: `/campaigns/dashboard`, search: query })
    }
  }, [currentOrg?.id, navigate, roles])
  return (
    <div className="CampaignSplash BlockWithBorder desktop:shadow-md desktop:grid-cols-[2vw_22vw_auto_2vw] laptop:flex laptop:flex-col desktop:grid-rows-[16vh_35rem] desktop:pt-0 desktopsmall:border-0 tablet:bg-transparent tablet:justify-start grid w-full gap-y-1 gap-x-4">
      <div className="CampaignSplash_Header laptop:flex laptop:flex-col desktop:col-span-4 w-full">
        <CampaignHeader className="mobile:mt-0" type={1}>
          Hey {user?.given_name}, let&#39;s create an ad campaign!
        </CampaignHeader>

        <CampaignHeader className="!font-light" type={3}>
          Enter some basic information to get started
        </CampaignHeader>
      </div>
      <div className="CampaignSplash_Campaign-Details desktop:col-span-4 mobile:flex-col laptop:items-center laptop:flex-wrap flex justify-center gap-[3vw] self-center">
        <figure className="CampaignSplash_image laptop:w-2/3 laptop:max-w-[22rem] w-5/12 items-center justify-center self-center">
          <img src={BWPic} alt="test" className="h-auto w-full object-cover" />
        </figure>
        <div className="CampaignSplash_name-details">
          <CampaignDetailRow>
            <CampaignLabelWrap>
              <IconWrap>
                <Fa
                  icon="fa-ad"
                  type="duotone"
                  size={5}
                  className="text-primary-400"
                />
              </IconWrap>
              <CampaignLabel className="justify-center">
                <TextHeader className="text-m normal-case" type={5}>
                  Campaign name
                </TextHeader>
              </CampaignLabel>
            </CampaignLabelWrap>
            <CampaignInputWrap>
              <FormItem
                className="CampaignSplash_Name-input w-full flex-col gap-1"
                htmlFor="campaignName"
                errorMessage={ErrorMessage({
                  fieldName: campaignName,
                  max: 255,
                  label: 'Campaign name',
                })}
                valid={
                  validCheck(campaignName) &&
                  isValidCharLength(campaignName, 255)
                }
                counter={campaignName.length > 0}
              >
                <TextInput
                  className={`CampaignSplash_name-input-field mobile:bg-base text-tint-gray-800 text-l py-2 ${
                    campaign_name ? 'bg-base' : 'bg-tint-gray-50'
                  }`}
                  value={campaignName || ''}
                  onChange={(e) => setCampName(e.target.value)}
                  placeholder="Title of your advertising campaign"
                  size="m"
                  maxLength={256}
                  hideValidIcon
                  valid={
                    !isDisabled && !lengthLimit && campaignName?.length > 2
                  }
                />
              </FormItem>
            </CampaignInputWrap>
          </CampaignDetailRow>
          <CampaignDetailRow className="PoliticalSelection__row">
            <CampaignLabelWrap>
              <IconWrap className="scale-125">
                <ETIcon icon="advocacy" />
              </IconWrap>
              <CampaignLabel>
                <TextHeader className="text-m normal-case" type={5}>
                  Political
                </TextHeader>
                <small className="leading-none">
                  Campaigns that support political candidates, initiatives, or
                  advocacy causes
                </small>
              </CampaignLabel>
            </CampaignLabelWrap>
            <CampaignInputWrap className="CampaignSplash_Political-checkbox mobile:w-auto gap-4">
              <FormItem htmlFor="political">
                <RadioButtonGroup
                  className="PoliticalCampaign-radio-group"
                  layout="horizontal"
                  name="Political campaign"
                  valueSelected={political === true ? 'yes' : 'no'}
                  onChange={() => setPolitical(!political)}
                >
                  <RadioButton
                    id="yes"
                    value="yes"
                    checked={political}
                    label="Yes"
                  />
                  <RadioButton
                    id="no"
                    value="no"
                    checked={!political}
                    label="No"
                  />
                </RadioButtonGroup>
              </FormItem>
            </CampaignInputWrap>
          </CampaignDetailRow>
          {political && (
            <div className="CampaignSplash__political-prepay-msg animate-slidedown flex w-full items-center justify-center">
              <PrePayMsg />
            </div>
          )}
          {!showOptionalFields && (
            <CampaignDetailRow className="CampaignSplash__add-optional-ids">
              <Button
                iconLeft={<Fa icon="plus" size={2} />}
                onClick={() => setShowOptionalFields(!showOptionalFields)}
              >
                Add optional IDs
              </Button>
            </CampaignDetailRow>
          )}
          <CollapsibleContainer isOpen={showOptionalFields}>
            <CampaignDetailRow className="PoliticalSelection__row mb-3">
              <CampaignLabelWrap>
                <CampaignLabel>
                  <TextHeader className="text-m normal-case" type={5}>
                    Campaign Job ID
                  </TextHeader>
                  <small className="leading-none">
                    Optionally store your own system's ID
                  </small>
                </CampaignLabel>
              </CampaignLabelWrap>
              <CampaignInputWrap className="CampaignSplash_Political-checkbox mobile:w-auto gap-4">
                <FormItem
                  className="CampaignSplash_JobId-input w-full flex-col gap-1"
                  htmlFor="jobId"
                  errorMessage={ErrorMessage({
                    fieldName: campaignJobId,
                    max: 255,
                    label: 'Campaign Job ID',
                  })}
                  valid={
                    validCheck(campaignJobId) &&
                    isValidCharLength(campaignJobId, 255)
                  }
                  counter={campaignJobId.length > 0}
                >
                  <TextInput
                    value={campaignJobId || ''}
                    onChange={(e) => setCampaignJobId(e.target.value)}
                    size="m"
                    maxLength={256}
                    hideValidIcon
                  />
                </FormItem>
              </CampaignInputWrap>
            </CampaignDetailRow>
            <CampaignDetailRow className="PoliticalSelection__row">
              <CampaignLabelWrap>
                <CampaignLabel>
                  <TextHeader className="text-m normal-case" type={5}>
                    Campaign P.O. #
                  </TextHeader>
                  <small className="leading-none">
                    Optionally store your own system's P.O. #
                  </small>
                </CampaignLabel>
              </CampaignLabelWrap>
              <CampaignInputWrap className="CampaignSplash_Political-checkbox mobile:w-auto gap-4">
                <FormItem
                  className="CampaignSplash_PO-input w-full flex-col gap-1"
                  htmlFor="campaignPO"
                  errorMessage={ErrorMessage({
                    fieldName: campaignPO,
                    max: 255,
                    label: 'Campaign Job ID',
                  })}
                  valid={
                    validCheck(campaignPO) && isValidCharLength(campaignPO, 255)
                  }
                  counter={campaignPO.length > 0}
                >
                  <TextInput
                    value={campaignPO || ''}
                    onChange={(e) => setCampaignPO(e.target.value)}
                    size="m"
                    maxLength={256}
                    hideValidIcon
                  />
                </FormItem>
              </CampaignInputWrap>
            </CampaignDetailRow>
          </CollapsibleContainer>

          <div className="CampaignSplash__support-link py-4">
            {/* using router-dom's Link doesn't work consistently */}
            <a
              className="text-base-info-600 flex items-center gap-1"
              href="https://support.eltoro.com/hc/en-us/articles/16580978718491-Creating-a-Campaign-and-Order-Lines"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Fa
                className="text-base-info pr-1"
                icon="question-circle"
                size={1}
              />
              <span className="text-text">For more information on how to</span>
              <span className="more-info-link">
                create a campaign, click here
              </span>
            </a>
          </div>
          <div className="CampaignSplash_Campaign-Buttons mobile:justify-center mobile:m-0 mb-2 ml-auto flex justify-end gap-8 pb-5">
            <Button type="button" onClick={() => navigate(-1)}>
              Cancel
            </Button>

            <Button
              className="Button--primary px-7"
              type="button"
              disabled={isDisabled || lengthLimit}
              onClick={() => {
                if (currentOrg && currentOrg.id && campaignName) {
                  setIsPolitical(political)
                  handleCampaignCreation(
                    campaignName,
                    currentOrg?.id,
                    political,
                    campaignJobId,
                    campaignPO,
                  )
                }
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
