import { useState, useEffect } from 'react'
import { useForm, useValidatedState, useValidation } from '@eltoro-ui/hooks'
import {
  Form,
  FormItem,
  Button,
  Toggle,
  TextHeader,
  TextInput,
} from '@eltoro-ui/components'
import { ErrorMessage, isValidCharLength, styleTailwind } from 'Helpers'
import { useAppContext } from 'Contexts'
import { SelectCountry, SelectState } from 'Components'
import { BillingInfoType } from '../CCModal'
import 'react-credit-cards/lib/styles.scss'

const BillInput = styleTailwind(TextInput, 'BillingInput relative')

export const BillingInfoInput = ({
  setComplete,
  billingInfo,
  setBillingInfo,
  navToStep,
}: {
  setComplete: () => void
  navToStep: (step: 1 | 2 | 3) => void
  billingInfo: BillingInfoType
  setBillingInfo: (info: BillingInfoType) => void
}) => {
  const { currentOrg, billingServiceApi } = useAppContext()
  const [uncheckedData, setUncheckedData] = useState<Partial<BillingInfoType>>({
    firstName: billingInfo?.firstName || '',
    lastName: billingInfo?.lastName || '',
    address: billingInfo?.address || '',
    address2: billingInfo.address2 || '',
    city: billingInfo?.city || '',
    state: billingInfo?.state || '',
    country: billingInfo?.country || '',
  })
  const [sameAsOrg, setSameAsOrg] = useState(false)
  const [zipcode, setZipcode] = useValidatedState(
    billingInfo?.zipcode || '',
    'zip-code' || 'canadian-zip',
  )
  const isValidZip = useValidation('zip-code')
  const isValidCanadianZip = useValidation('canadian-zip')
  const {
    firstName,
    lastName,
    address,
    address2,
    city,
    state,
    country,
  } = uncheckedData
  const { formData, isValidForm, touched } = useForm({
    firstName,
    lastName,
    address,
    address2,
    city,
    state,
    zipcode,
    country,
  })
  const required = [
    'firstName',
    'lastName',
    'address',
    'city',
    'state',
    'zipcode',
    'country',
  ]
  const { validForm, missingFields } = isValidForm([...required])
  useEffect(() => {
    if (uncheckedData.state || uncheckedData.zipcode) {
      setUncheckedData({ ...uncheckedData, state: '', zipcode: '' })
    }
  }, [country])

  const orgBillingContact = currentOrg?.contacts?.find(
    (contact) => contact.type === 'billing',
  )

  useEffect(() => {
    if (currentOrg?.id && sameAsOrg && billingServiceApi) {
      setUncheckedData({
        firstName: orgBillingContact?.firstName || '',
        lastName: orgBillingContact?.lastName || '',
        address: orgBillingContact?.address || '',
        address2: '',
        city: orgBillingContact?.city || '',
        state: orgBillingContact?.state || '',
        country: orgBillingContact?.country || '',
      })
      setZipcode(orgBillingContact?.zip || '')
    } else {
      setUncheckedData({
        firstName: billingInfo?.firstName || '',
        lastName: billingInfo?.lastName || '',
        address: billingInfo?.address || '',
        address2: billingInfo.address2 || '',
        city: billingInfo?.city || '',
        state: billingInfo?.state || '',
        country: billingInfo?.country || '',
      })
      setZipcode(billingInfo?.zipcode || '')
    }
  }, [sameAsOrg])

  return (
    <div className="BillingInfoInput flex flex-col gap-4">
      <TextHeader className="BillingInfoInput__step-header flex" type={4}>
        <span className="BillingInfoInput__step RightVerticalPipe font-light uppercase">
          Step 2
        </span>
        <span className="BillingInfoInput__subTitle text-l">
          Billing information
        </span>
      </TextHeader>

      <Form
        className="BillingInfoInputContainer__form"
        required={required}
        valid={validForm}
        touched={touched}
        missingFields={missingFields}
      >
        <div className="BillingInfoInputContainer my-2 flex flex-col gap-3">
          <span className="BillingInfoInputContainer__full-name flex gap-6">
            <FormItem
              className="grow"
              required
              htmlFor="firstName"
              label="First Name"
              errorMessage={ErrorMessage({
                fieldName: firstName || '',
                max: 255,
                label: "Cardholder's first name",
              })}
              valid={
                (firstName || '').length >= 2 &&
                isValidCharLength(firstName || '', 255)
              }
            >
              <BillInput
                value={firstName}
                maxLength={256}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUncheckedData({
                    ...uncheckedData,
                    firstName: e.target.value,
                  })
                }
              />
            </FormItem>
            <FormItem
              className="grow"
              required
              htmlFor="lastName"
              label="Last Name"
              errorMessage={ErrorMessage({
                fieldName: lastName || '',
                max: 255,
                label: "Cardholder's last name",
              })}
              valid={
                (lastName || '').length >= 2 &&
                isValidCharLength(lastName || '', 255)
              }
            >
              <BillInput
                value={lastName}
                maxLength={256}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUncheckedData({
                    ...uncheckedData,
                    lastName: e.target.value,
                  })
                }
              />
            </FormItem>
          </span>
          <FormItem
            required
            htmlFor="address"
            label="Address"
            errorMessage={ErrorMessage({
              fieldName: address || '',
              max: 255,
              label: 'Address',
            })}
            valid={
              (address || '').length >= 2 &&
              isValidCharLength(address || '', 255)
            }
          >
            <BillInput
              value={address}
              maxLength={256}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setUncheckedData({
                  ...uncheckedData,
                  address: e.target.value,
                })
              }
            />
          </FormItem>
          <FormItem
            htmlFor="address2"
            label="Address (line 2)"
            errorMessage={ErrorMessage({
              fieldName: address2 || '',
              max: 255,
              label: 'Address, line 2',
            })}
            valid={
              (address2 || '').length >= 2 &&
              isValidCharLength(address2 || '', 255)
            }
          >
            <BillInput
              value={address2}
              maxLength={256}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setUncheckedData({
                  ...uncheckedData,
                  address2: e.target.value,
                })
              }
            />
          </FormItem>
          <FormItem
            required
            htmlFor="country"
            label="Country"
            errorMessage="Please select a country"
            className="max-w-md"
          >
            <SelectCountry
              value={country}
              onChange={(country) =>
                setUncheckedData({
                  ...uncheckedData,
                  country,
                })
              }
            />
          </FormItem>
          <div className="BillingInfoInputContainer__city-state-zip flex gap-5">
            <FormItem
              className="flex-1"
              required
              htmlFor="city"
              label="City"
              errorMessage={ErrorMessage({
                fieldName: city || '',
                max: 100,
                label: 'City',
              })}
              valid={
                (city || '').length >= 2 && isValidCharLength(city || '', 100)
              }
            >
              <BillInput
                value={city}
                maxLength={101}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUncheckedData({
                    ...uncheckedData,
                    city: e.target.value,
                  })
                }
              />
            </FormItem>

            {country === 'United States of America' && (
              <FormItem
                required
                htmlFor="state"
                label="State"
                errorMessage="Please enter a valid state"
              >
                <SelectState
                  country={country}
                  value={state}
                  onChange={(state) =>
                    setUncheckedData({
                      ...uncheckedData,
                      state,
                    })
                  }
                />
              </FormItem>
            )}
            {country === 'Canada' && (
              <FormItem
                required
                htmlFor="state"
                label="Province"
                errorMessage="Please enter a valid province"
              >
                <SelectState
                  country={country}
                  value={state}
                  onChange={(state) =>
                    setUncheckedData({
                      ...uncheckedData,
                      state,
                    })
                  }
                />
              </FormItem>
            )}
            <FormItem
              required
              htmlFor="zipcode"
              label="ZIP code"
              errorMessage={ErrorMessage({
                fieldName: zipcode || '',
                max: 5,
                label: 'ZIP code',
              })}
              valid={
                isValidZip(zipcode || '') || isValidCanadianZip(zipcode || '')
              }
              counter={(zipcode || '').length > 0}
            >
              <BillInput
                // safari handles number as alphanumerical field change to tel so the FormItem's handler will prevent alpha chars.
                type="text"
                maxLength={7}
                value={zipcode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setZipcode(e.target.value)
                }
              />
            </FormItem>
          </div>
        </div>

        {orgBillingContact && (
          <Toggle
            className="BillingInfoInput__toggle my-2 ml-auto items-center"
            size="s"
            isChecked={sameAsOrg}
            onChange={setSameAsOrg}
            label="Same as Org's &#39;billing details&#39;"
          />
        )}
        <div className="ButtonGroup BillingInfo__credit-card-btns flex justify-end gap-6 pb-4 pt-6">
          <Button type="submit" onClick={() => navToStep(1)}>
            Back
          </Button>
          <Button
            className={validForm ? 'Button--primary ripples' : ''}
            disabled={!validForm}
            type="submit"
            onClick={() => {
              setComplete()
              setBillingInfo(formData as BillingInfoType)
              navToStep(3)
            }}
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  )
}
