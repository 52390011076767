import { useEffect, useState } from 'react'
import {
  Button,
  Fa,
  Loader,
  Select,
  SelectOptionType,
  Text,
} from '@eltoro-ui/components'
import { V1CreditCard } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { checkExp, isOrgBillable } from 'Helpers'
import { LinkButton, CCModal } from 'Components'

export const CreditCardSelect = ({
  setSelectedCard,
  selectedCard,
  disabled,
}: {
  setSelectedCard: (cardId?: string) => void
  selectedCard?: string
  disabled?: boolean
}) => {
  const [billingError, setBillingError] = useState(false)
  const [showCCModal, setShowCCModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    currentOrg,
    billingServiceApi,
    currentBillingOrg,
    setCurrentBillingOrg,
  } = useAppContext()

  useEffect(() => {
    if (currentBillingOrg?.isBillingReady && currentBillingOrg.creditCards) {
      setSelectedCard(
        currentBillingOrg.creditCards?.find(
          (cc) =>
            cc.isDefault &&
            cc.authorization &&
            cc.authorization.approved &&
            cc.authorization.signature,
        )?.id,
      )
    }
  }, [currentBillingOrg, setSelectedCard])

  const cards = currentBillingOrg?.creditCards?.filter(
    (cc) =>
      cc.authorization &&
      cc.authorization.approved &&
      cc.authorization.signature &&
      cc.expirationDate &&
      checkExp(cc.expirationDate),
  ) // authorized unexpired cards only

  const selectedCardDetail = cards?.find((card) => card.id === selectedCard)

  const icon = (cc: V1CreditCard) => {
    if (cc.type === 'American Express') return 'cc-amex'
    if (cc.type === 'Diners Club International') return 'cc-diners-club'
    return `cc-${(cc?.type || '').toLowerCase()}`
  }

  return (
    <>
      <div className="CreditCardSelect flex flex-col gap-2">
        {(() => {
          if (loading || !currentBillingOrg) return <Loader />
          if (currentOrg && isOrgBillable(currentOrg) && billingError)
            return (
              <div className="bg-danger-100 text-danger-500 border-danger-500 rounded border p-2">
                <p className="font-bold">Billing Error</p>
                <p className="text-sm">
                  There was an error getting your billing information. Please
                  reload the page and try again. If you continue to see this
                  error, please reach out to support.
                </p>
              </div>
            )
          if (currentOrg && !isOrgBillable(currentOrg)) return null
          if (cards && cards.length > 0)
            return (
              <>
                <Text>Choose a Credit Card on file:</Text>
                <div className="flex flex-col items-end gap-1">
                  <Select
                    disabled={disabled}
                    placeholder="Select credit card"
                    options={cards.map((cc) => {
                      return {
                        value: cc.id,
                        label: (
                          <div className="flex gap-1 text-sm">
                            <Fa
                              icon={icon(cc)}
                              type="branded"
                              size={1}
                              className="text-grey-400"
                            />
                            <div className="flex w-full flex-col">
                              <p>{cc.nickname}</p>
                              <p>
                                {cc.type?.replace('International', "Int'l")}{' '}
                                {cc.cardNumber}
                              </p>
                              <p className="text-grey-600 italic">
                                {cc.isDefault && '(Default)'}
                              </p>
                            </div>
                          </div>
                        ),
                      }
                    })}
                    selectedOption={
                      selectedCard && selectedCardDetail
                        ? ({
                            value: selectedCard,
                            label: (
                              <div className="flex items-center gap-1">
                                <Fa
                                  icon={icon(selectedCardDetail)}
                                  type="branded"
                                  size={2}
                                  className="text-grey-400"
                                />
                                {selectedCardDetail.nickname}
                              </div>
                            ),
                          } as SelectOptionType)
                        : undefined
                    }
                    onChange={(selected) => setSelectedCard(selected?.value)}
                    maxHeight={300}
                    className="w-full"
                    selectClassName="min-h-[2.5rem]"
                    clearButton={false}
                  />
                  {cards.length < 10 && (
                    <LinkButton
                      text="Add a card"
                      onClick={() => setShowCCModal(true)}
                      disabled={disabled}
                    />
                  )}
                </div>
              </>
            )

          return (
            <Button
              iconLeft={<Fa icon="add" size={1} />}
              onClick={() => setShowCCModal(true)}
              disabled={disabled}
            >
              Add card
            </Button>
          )
        })()}
      </div>
      {showCCModal && (
        <CCModal
          handleClose={() => setShowCCModal(false)}
          onSave={() => {
            if (!currentOrg?.id || !billingServiceApi) return
            setLoading(true)
            billingServiceApi
              .advertisingPlatformServiceGetBillingOrg(
                currentOrg.id, // orgId
              )
              .then((res) => {
                setCurrentBillingOrg(res)
                if (res.isBillingReady) {
                  const authorizedCards = res.creditCards?.filter(
                    (cc) =>
                      cc.authorization &&
                      cc.authorization.approved &&
                      cc.authorization.signature,
                  )
                  setSelectedCard(
                    authorizedCards?.find((cc) => cc.isDefault)?.id,
                  )
                }
              })
              .catch(() => setBillingError(true))
              .finally(() => setLoading(false))
          }}
        />
      )}
    </>
  )
}
