import { getApiUrl } from 'Helpers'
import axios from 'axios'

export const requestNameChange = async (name: string, orgId: string) => {
  const url = `${getApiUrl()}/v1`
  const token = localStorage.getItem('eltoro_token')
  const config = {
    Authorization: `Bearer ${token}`,
  }

  await axios
    .post(
      `${url}/notifications/org-name-change`,
      { org_id: orgId, name },
      {
        headers: config,
      },
    )
    .then((res) => {
      return res
    })
    .catch((err) => {
      if (err) {
        throw new Error(
          JSON.stringify({
            status: err?.response?.status,
            message: err?.response?.data,
          }),
        )
      }
    })
}
