import { useEffect, useState } from 'react'
import { Fa, Popover, Text, TextHeader } from '@eltoro-ui/components'
import { useAppContext, useCampaignContext } from 'Contexts'
import { getTypeMoneyCost, isOrgBillable, styleTailwind } from 'Helpers'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { CreditCardSelect, SubmitOrderForReview } from 'Components'
import { dayjs } from 'Tools/dateUtils'

const ReviewItem = styleTailwind('div', 'ReviewShoppingCart__item pt-1 pr-1')

export const ReviewShoppingCart = ({
  onSubmit,
}: {
  onSubmit: (selectedCardId?: string) => Promise<void>
}) => {
  const [showAddCostInfo, setShowAddCostInfo] = useState(false)
  const [selectedCard, setSelectedCard] = useState<string>()

  const [
    readyForSubmissionOrderLines,
    setReadyforSubmissionOrderLines,
  ] = useState<Campaignservicev1OrderLine[]>([])
  const [loading, setLoading] = useState(false)
  const {
    currentOrg,
    billingServiceApi,
    currentBillingOrg,
    setCurrentBillingOrg,
  } = useAppContext()
  const {
    orderLines,
    submissionRemovals,
    isPolitical,
    campaign,
  } = useCampaignContext()

  const [
    missingPoliticalTransparencyFields,
    setMissingPoliticalTransparencyFields,
  ] = useState<string[]>([])
  const additionalFees = 0

  useEffect(() => {
    const submissionOrderlines = orderLines
      ?.map((ol) => {
        if (
          !ol.paid &&
          !ol.locked &&
          ol.costRange &&
          ol.costRange.estimate &&
          ol.costRange.estimate.units &&
          (Number(ol.costRange.estimate.units) !== 0 ||
            // Allow cents
            (Number(ol.costRange.estimate.units) === 0 &&
              Number(ol.costRange.estimate.nanos !== 0))) &&
          ol.audiences &&
          ol.audiences.length > 0 &&
          ol.creatives &&
          ol.creatives.length > 0 &&
          ol.id &&
          ol.status &&
          ol.clickThroughUrl &&
          !submissionRemovals.includes(ol.id) &&
          ol.status === 'ORDERLINE_STATUS_DRAFT' &&
          !dayjs
            .tz(ol.startTime)
            .startOf('day')
            .isBefore(dayjs().tz().startOf('day'))
        ) {
          return ol
        }
        return undefined
      })
      .filter((c) => c !== undefined)
    setReadyforSubmissionOrderLines(
      submissionOrderlines as Campaignservicev1OrderLine[],
    )
  }, [campaign, orderLines])
  // Determine if the org is Billing Ready for submission of OLs
  const billingContact = !!currentOrg?.contacts?.find(
    (contact) => contact.type === 'billing',
  )

  const totalOrderlineCost = readyForSubmissionOrderLines.reduce(
    (acc, { costRange }) => {
      return acc + getTypeMoneyCost(costRange?.estimate || {})
    },
    0,
  )
  const isPrepay = () => {
    if (currentOrg?.overrideCreditCardRequirement) return false
    // Note: currently political and prepay are not linked on the order lines, so assuming all political ols are pre pay here
    // https://eltoroteam.slack.com/archives/G01CSAF0RJQ/p1685472712892579
    const prePayOrderLine = orderLines?.find((ol) => ol.prepay || ol.political)
    const orgIsPrePay = currentOrg?.prepay
    return orgIsPrePay || !!prePayOrderLine
  }
  const isOnHold = (): string[] | undefined => {
    if (currentOrg?.accountingHold) {
      return ['accountingHold']
    }
    if (currentOrg?.billingHold) {
      return ['billingHold']
    }
    if (currentOrg?.adminAccountingHold) {
      return ['adminAccountingHold']
    }
    return []
  }

  useEffect(() => {
    const politicalTransparencyCheck = () => {
      if (!isPolitical) {
        setMissingPoliticalTransparencyFields([])
      }
      if (!campaign || !campaign.id || !currentOrg || !currentOrg.id)
        return true
      const errors: string[] = []
      if (campaign) {
        if (campaign.politicalTransparency) {
          const { politicalTransparency } = campaign
          if (!politicalTransparency.paymentMethodType) {
            const missingField = 'Payment Type'
            if (!errors.includes(missingField)) {
              errors.push(missingField)
            }
          }
          if (!politicalTransparency.orgAddress1) {
            const missingField = 'Org Address'
            if (!errors.includes(missingField)) {
              errors.push(missingField)
            }
          }
          if (!politicalTransparency.orgCity) {
            const missingField = 'Org City'
            if (!errors.includes(missingField)) {
              errors.push(missingField)
            }
          }
          if (!politicalTransparency.orgName) {
            const missingField = 'Org Name'
            if (!errors.includes(missingField)) {
              errors.push(missingField)
            }
          }
          if (!politicalTransparency.orgRegion) {
            const missingField = 'Org State'
            if (!errors.includes(missingField)) {
              errors.push(missingField)
            }
          }
          if (!politicalTransparency.orgPostalCode) {
            const missingField = 'Org Zip Code'
            if (!errors.includes(missingField)) {
              errors.push(missingField)
            }
          }
          if (!politicalTransparency.orgPhone) {
            const missingField = 'Org Phone'
            if (!errors.includes(missingField)) {
              errors.push(missingField)
            }
          }
          if (!politicalTransparency.treasurerName) {
            const missingField = 'Treasurer Name'
            if (!errors.includes(missingField)) {
              errors.push(missingField)
            }
          }
          if (!politicalTransparency.isAccuracyAcknowledged) {
            const missingField = 'Is Accuracy Acknowledged'
            if (!errors.includes(missingField)) {
              errors.push(missingField)
            }
          }
        }
        if (
          readyForSubmissionOrderLines &&
          readyForSubmissionOrderLines.length > 0
        ) {
          readyForSubmissionOrderLines.map((currentOl) => {
            if (currentOl) {
              const { politicalFields } = currentOl
              if (!politicalFields?.orgZip) {
                const missingField = 'Org Zip'
                if (!errors.includes(missingField)) {
                  errors.push(missingField)
                }
              }
              if (!politicalFields?.paidForBy) {
                const missingField = 'Paid For By'
                if (!errors.includes(missingField)) {
                  errors.push(missingField)
                }
              }
              if (!politicalFields?.audienceDescription) {
                const missingField = 'Audience Description'
                if (!errors.includes(missingField)) {
                  errors.push(missingField)
                }
              }
              if (!politicalFields?.audienceIdsTargeted) {
                const missingField = 'Audiences targeted'
                if (!errors.includes(missingField)) {
                  errors.push(missingField)
                }
              }
              if (!politicalFields?.submitterAddress) {
                const missingField = 'Submitter Address'
                if (!errors.includes(missingField)) {
                  errors.push(missingField)
                }
              }
              if (!politicalFields?.submitterCity) {
                const missingField = 'Submitter City'
                if (!errors.includes(missingField)) {
                  errors.push(missingField)
                }
              }
              if (!politicalFields?.submitterName) {
                const missingField = 'Submitter Name'
                if (!errors.includes(missingField)) {
                  errors.push(missingField)
                }
              }
              if (!politicalFields?.submitterState) {
                const missingField = 'Submitter State'
                if (!errors.includes(missingField)) {
                  errors.push(missingField)
                }
              }
              if (!politicalFields?.submitterZip) {
                const missingField = 'Submitter Zip'
                if (!errors.includes(missingField)) {
                  errors.push(missingField)
                }
              }
              return undefined
            }
          })
        }
      }
      setMissingPoliticalTransparencyFields(errors)
    }
    politicalTransparencyCheck()
  }, [campaign, currentOrg, isPolitical, readyForSubmissionOrderLines])

  const creditCardRequired =
    isPrepay() && currentOrg && isOrgBillable(currentOrg)

  const hasCardOnFile =
    currentBillingOrg?.isBillingReady &&
    currentBillingOrg?.creditCards &&
    currentBillingOrg.creditCards?.length > 0

  const errorMessages = [
    ...(isPolitical &&
    missingPoliticalTransparencyFields &&
    missingPoliticalTransparencyFields.length > 0
      ? [
          <>
            You are missing the following fields on the Poltical Transparency
            form:
            <ul className="list-square">
              {missingPoliticalTransparencyFields.map((currentError, i) => {
                return (
                  <li key={i}>
                    <strong>{currentError}</strong>
                  </li>
                )
              })}
            </ul>
          </>,
        ]
      : []),
    ...(!readyForSubmissionOrderLines ||
    readyForSubmissionOrderLines.length === 0
      ? ['No order lines are selected for submission.']
      : []),
    // Per Mari- lots of prepay clients do not use credit cards. I'm assuming we will have to establish a pattern for that later
    ...(creditCardRequired && !selectedCard
      ? ['Please select or add a payment method.']
      : []),
    ...(!hasCardOnFile &&
    !currentOrg?.overrideCreditCardRequirement &&
    !currentOrg?.billableOrgId
      ? ['You need to add a credit card before you are able to submit.']
      : []),
    ...(!billingContact && !currentOrg?.billableOrgId
      ? [
          <>
            You need to update your{' '}
            {
              <a href={`/orgs/${currentOrg?.id}/billing`}>
                billing information
              </a>
            }
          </>,
        ]
      : []),
    ...(!currentBillingOrg?.isBillingReady &&
    !currentOrg?.billableOrgId &&
    !currentOrg?.overrideCreditCardRequirement
      ? [
          <>
            Your organization is not ready for billing. Please contact{' '}
            <a className="text-danger" href="mailto:billing@eltoro.com">
              billing@eltoro.com
            </a>{' '}
            if you think this is an error.
          </>,
        ]
      : []),
  ]

  if (!orderLines) return <p>No OLs</p>
  return (
    <div className="ReviewShoppingCart flex flex-col gap-4 py-4">
      <ReviewItem className="order-line">
        <TextHeader className="text-primary py-2" type={4}>
          Estimated Cost
        </TextHeader>
        <div className="OrderLines__item text-l flex justify-between gap-2">
          {readyForSubmissionOrderLines &&
            readyForSubmissionOrderLines.length >= 0 && (
              <span className="OrderLine__label-number">
                Order Line
                {`${readyForSubmissionOrderLines.length === 1 ? '' : 's'}`}
                {readyForSubmissionOrderLines.length > 1 &&
                  ` (${readyForSubmissionOrderLines.length}x)`}
              </span>
            )}
          <span className="OrderLine__total-cost pr-1">
            ${totalOrderlineCost.toFixed(2)}
          </span>
        </div>
        {!!additionalFees && (
          <ReviewItem className="additional-fee flex justify-between">
            <div className="additional-fee-wrap text-l desktop:flex desktop:gap-2 pr-1">
              <Text>Additional fee&nbsp;</Text>
              <Popover
                isOpen={showAddCostInfo}
                position={['left']}
                content={
                  <div className="ReviewShoppingCart__popover bg-base shadow-tint-gray-300 shadow-l animate-fadein rounded p-3">
                    Some info about the additional fee
                  </div>
                }
              >
                <div
                  onFocus={() => setShowAddCostInfo(true)}
                  onBlur={() => setShowAddCostInfo(false)}
                  onMouseEnter={() => setShowAddCostInfo(true)}
                  onMouseOut={() => setShowAddCostInfo(false)}
                  onClick={() => setShowAddCostInfo(!showAddCostInfo)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Space')
                      setShowAddCostInfo(!showAddCostInfo)
                    if (e.key === 'Escape') {
                      setShowAddCostInfo(false)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  className="ReviewShoppingCart__info-icon inline-block"
                >
                  <Fa
                    className="text-tint-gray-500"
                    icon="info-circle"
                    size="xs"
                  />
                </div>
              </Popover>
            </div>
            <div className="text-l pr-1">${additionalFees}</div>
          </ReviewItem>
        )}
        <TextHeader
          className="ReviewShoppingCart__total flex justify-between pt-3 font-semibold"
          type={4}
        >
          <span className="total-label">Total</span>
          <span className="total-cost pr-1">
            ${(totalOrderlineCost + additionalFees).toFixed(2)}
          </span>
        </TextHeader>
      </ReviewItem>

      {creditCardRequired && (
        <ReviewItem>
          <TextHeader className="text-primary pb-2" type={4}>
            Payment Method
          </TextHeader>
          <CreditCardSelect
            setSelectedCard={setSelectedCard}
            selectedCard={selectedCard}
          />
        </ReviewItem>
      )}
      <ReviewItem>
        <SubmitOrderForReview
          isReady={errorMessages.length === 0}
          errorMessages={errorMessages}
          rejectMessage={
            orderLines.some((cur) => cur.status === 'ORDERLINE_STATUS_REJECTED')
              ? 'This will not submit any rejected order line(s)'
              : ''
          }
          onSubmit={async () => {
            setLoading(true)
            await onSubmit(selectedCard).then(() => setLoading(false))
          }}
          buttonText={(() => {
            if (loading) return 'Submitting'
            if (errorMessages.length > 0) return 'Unable to submit'
            if (
              !currentOrg?.billableOrgId &&
              !hasCardOnFile &&
              !currentOrg?.overrideCreditCardRequirement
            )
              return 'No credit card on file'
            return !creditCardRequired ? 'Submit' : 'Pay & Submit'
          })()}
          isPrepay={isPrepay()}
          submittableOrderlines={readyForSubmissionOrderLines || []}
          onHold={isOnHold()}
          loading={loading}
          isACardOnFile={hasCardOnFile}
          onSaveCreditCard={() => {
            if (!currentBillingOrg?.id || !billingServiceApi) return
            billingServiceApi
              .advertisingPlatformServiceGetBillingOrg(currentBillingOrg.id)
              .then(setCurrentBillingOrg)
          }}
          isBillingReady={!billingContact && !currentOrg?.billableOrgId}
        />
      </ReviewItem>
    </div>
  )
}
