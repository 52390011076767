import { useEffect, useState } from 'react'
import { AutoScroller, Fa, Text, showErrorMessage } from '@eltoro-ui/components'
import classNames from 'classnames'
import {
  Campaignservicev1Campaign,
  Campaignservicev1OrderLine,
} from 'next-gen-sdk'
import { styleTailwind } from 'Helpers'
import { useNavigate } from 'react-router-dom'
import { LinkButton, OrderLinesByCategory } from 'Components'
import { useAppContext } from 'Contexts'
import { ExpansionHeader } from '../ExpansionHeader'
import { ETIcon } from 'Assets'

type CampaignContainerType = {
  campaignId: string
  orgId: string
  handleCollapse: () => void
  collapseIconName?: string
}

const CampaignDetail = styleTailwind(
  Text,
  'CampaignContainer__budget-item font-light flex items-center gap-2',
)

export const CampaignContainer = ({
  campaignId,
  orgId,
  handleCollapse,
  collapseIconName,
}: CampaignContainerType) => {
  const [campaign, setCampaign] = useState<Campaignservicev1Campaign>()
  const [orderlines, setOrderlines] = useState<Campaignservicev1OrderLine[]>([])
  const [loading, setLoading] = useState(true)
  const { campaignServiceApi } = useAppContext()
  const navigate = useNavigate()

  useEffect(() => {
    const getCampaign = async () => {
      // if there is no init, get campaign based on id
      if (campaignId && orgId) {
        const campaign = await campaignServiceApi?.advertisingPlatformServiceGetCampaign(
          campaignId,
          orgId,
        )
        return campaign
      }
    }
    // populate campaign's order lines
    getCampaign()
      .then(async (campaign) => {
        setCampaign(campaign)
        if (campaign?.orderLines && campaignServiceApi) {
          const olCount = (campaign.orderLines || []).length
          const currentOrderLines = await campaignServiceApi.advertisingPlatformServiceListOrderLines(
            olCount, // pageSize
            undefined, // pageToken
            undefined, // orderBy
            campaign.orderLines?.map(({ id }) => `id="${id}"`).join(' OR '), // filter
          )
          setOrderlines(currentOrderLines.orderLines || [])
        }
      })
      .catch(() => {
        setOrderlines([])
        showErrorMessage(
          'Error getting order lines',
          `Error getting order lines for campaign "${campaignId}"`,
        )
      })
      .finally(() => setLoading(false))
  }, [campaignId, campaignServiceApi, orgId])

  const budget = orderlines.reduce((arr, ol) => {
    if (
      ol.status !== 'ORDERLINE_STATUS_DEPLOYED' &&
      ol.status !== 'ORDERLINE_STATUS_SERVING'
    )
      return arr
    return arr + Number(ol.costRange?.estimate?.units || 0)
  }, 0)

  const isPolitical =
    !!campaign?.politicalTransparency || orderlines.some((ol) => ol.political)

  return (
    <AutoScroller
      shouldScroll
      scrollToTop
      className="CampaignContainer__autoscroller !pr-0"
    >
      <div className="CampaignContainer px-5 pb-6 shadow-inner">
        {campaign && (
          <ExpansionHeader
            type="campaign"
            campaign={campaign}
            icon={loading ? <Fa icon="circle-notch" size={1} /> : undefined}
            name={loading ? 'Getting campaign...' : campaign?.name || ''}
            isCollapsed={false} // user will not see the collapsed version in this case
            handleCollapse={handleCollapse}
            collapseIconName={collapseIconName}
          />
        )}
        <div
          className={classNames('CampaignContainer__layout-wrapper', {
            'CampaignContainer__layout-wrapper--loading': loading,
          })}
        >
          {/* Details */}
          <div className="CampaignContainer__details border-b-thin border-tint-gray-300 mb-4 flex flex-col justify-center gap-1 pb-3 pt-4 font-light">
            <div className="flex items-center gap-4">
              {isPolitical && (
                <CampaignDetail tag="h5" className="text-xl">
                  <ETIcon icon="advocacy" />
                  {orderlines.some(
                    (ol) => ol.status === 'ORDERLINE_STATUS_DRAFT',
                  ) ? (
                    <span className="RightVerticalPipe">
                      Political Campaign
                    </span>
                  ) : (
                    <LinkButton
                      className="PoliticalCampaign__link RightVerticalPipe !text-xl font-semibold"
                      text="Political Campaign"
                      onClick={() => {
                        const query = campaign?.orgId
                          ? `?org_id=${campaign.orgId}`
                          : ''

                        navigate(
                          `/campaigns/edit/${campaign?.id}/political-transparency${query}`,
                        )
                      }}
                    />
                  )}
                </CampaignDetail>
              )}
              <CampaignDetail tag="h5" className="text-xl">
                <span>Deployed budget: </span>
                <span className="font-semibold">{`$${budget.toLocaleString()}`}</span>
              </CampaignDetail>
              <CampaignDetail tag="h5" className="ml-auto">
                <span>ID: </span>
                <span className="!text-m font-semibold">{campaign?.id}</span>
              </CampaignDetail>
              {campaign?.jobId && (
                <CampaignDetail tag="h5" className="ml-auto">
                  <span>Job ID: </span>
                  <span className="!text-m font-semibold">
                    {campaign.jobId}
                  </span>
                </CampaignDetail>
              )}
              {campaign?.poId && (
                <CampaignDetail tag="h5" className="ml-auto">
                  <span>P.O. #: </span>
                  <span className="!text-m font-semibold">{campaign.poId}</span>
                </CampaignDetail>
              )}
            </div>
          </div>
          {/* OL list */}
          {campaign && (
            <div className="CampaignContainer__orderline-details--OLRowContainer">
              <OrderLinesByCategory
                orderLines={orderlines}
                campaign={campaign}
                defaultOpen={[
                  'ready',
                  'needs_attention',
                  'processed',
                  'rejected',
                ]}
                onDashboard
                setRefreshCampaign={() => {
                  if (campaignId && campaignServiceApi) {
                    campaignServiceApi
                      ?.advertisingPlatformServiceGetCampaign(campaignId, orgId)
                      .then(async (res) => {
                        setCampaign(res)
                        if (res?.orderLines) {
                          const olCount = (res.orderLines || []).length
                          const currentOrderLines = await campaignServiceApi.advertisingPlatformServiceListOrderLines(
                            olCount, // pageSize
                            undefined, // pageToken
                            undefined, // orderBy
                            res.orderLines
                              ?.map(({ id }) => `id="${id}"`)
                              .join(' OR '), // filter
                          )
                          setOrderlines(currentOrderLines.orderLines || [])
                        }
                      })
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
    </AutoScroller>
  )
}
