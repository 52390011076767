import { useState } from 'react'
import { Billingservicev2Invoice } from 'next-gen-sdk'
import {
  Text,
  Modal,
  Button,
  TextHeader,
  RadioButton,
  RadioButtonGroup,
  Fa,
} from '@eltoro-ui/components'
import { CopyButton, CreditCardSelect, PriceTextInput } from 'Components'
import { useAppContext } from 'Contexts'
import { styleTailwind } from 'Helpers'
import './InvoicePaymentModal.scss'
import classNames from 'classnames'
import { dayjs } from 'Tools/dateUtils'

const GridRow = styleTailwind('div', 'GridRow grid grid-cols-2 gap-2')

export const InvoicePaymentModal = ({
  invoice,
  handleClose,
  handleReset,
}: {
  invoice: Billingservicev2Invoice
  handleClose: () => void
  handleReset: () => void
}) => {
  const [selectedCard, setSelectedCard] = useState<string>()
  const [paymentAmount, setPaymentAmount] = useState(invoice.totalDue || 0)
  const [payPartial, setPayPartial] = useState('full') // partial or full
  const [submitState, setSubmitState] = useState<{
    loading: boolean
    error?: string
  }>()
  const { billingServiceApi, currentOrg } = useAppContext()
  const handlePayInvoice = async () => {
    if (
      !currentOrg?.id ||
      !invoice.id ||
      !paymentAmount ||
      !selectedCard ||
      submitState?.loading
    )
      return
    setSubmitState({ loading: true })
    await billingServiceApi
      ?.advertisingPlatformServicePayInvoice(currentOrg.id, invoice.id, {
        creditCardId: selectedCard,
        amount: paymentAmount,
      })
      .then((res) => {
        setSubmitState({ loading: false })
        handleClose()
        handleReset()
      })
      .catch((err) => {
        setSubmitState({
          loading: false,
          error:
            err?.body?.message ||
            'There was an error submitting your invoice payment.',
        })
      })
  }

  return (
    <Modal
      offClick={submitState?.loading ? () => {} : handleClose}
      header={<TextHeader type={3}>Pay Invoice</TextHeader>}
      className="min-w-[50%]"
    >
      <div
        className={classNames('flex w-full gap-6', {
          'pointer-events-none opacity-40': submitState?.loading,
        })}
      >
        {/* Details */}
        <div className="InvoicePaymentModal__details-section bg-grey-50 rounded-xs py-1 px-2">
          <TextHeader className="text-primary mb-4" type={4}>
            Invoice Details
          </TextHeader>

          <div className="InvoicePaymentModal__invoice-details-grid text-l">
            <GridRow className="items-center">
              <Text weight="semibold">Invoice Number</Text>
              <div className="flex items-center justify-between">
                <p>{invoice.id}</p>
                {invoice.id && (
                  <CopyButton
                    text={invoice.id}
                    icon="link"
                    iconCopied="copy"
                    type="invoice number"
                  />
                )}
              </div>
            </GridRow>
            <GridRow>
              <Text weight="semibold">Due Date</Text>
              <p>{dayjs.tz(invoice.whenDue).format('MM/DD/YYYY')}</p>
            </GridRow>
            <GridRow>
              <Text weight="semibold">Total Invoiced</Text>
              <p>${invoice.total}</p>
            </GridRow>
            <GridRow>
              <Text weight="semibold">Applied Amount</Text>
              <p>${invoice.appliedAmount}</p>
            </GridRow>
            <GridRow>
              <Text weight="bold">Total Due</Text>
              <p>
                <strong>${invoice.totalDue}</strong>
              </p>
            </GridRow>
          </div>
        </div>

        {/* Paid/or pay section */}
        <div className="InvoicePaymentModal__payment-section flex flex-grow flex-col gap-4 pr-6">
          <TextHeader className="text-primary pt-1" type={4}>
            Payment
          </TextHeader>
          <div className="InvoicePaymentModal__payment-type-select">
            <RadioButtonGroup
              valueSelected={payPartial}
              name="Payment type"
              onChange={(selection) => {
                setPayPartial(selection as string)
                if (selection === 'full')
                  setPaymentAmount(invoice.totalDue || 0)
              }}
              className="p-0"
            >
              <RadioButton
                value="full"
                label={
                  <span>
                    Pay Amount Due: <strong>${invoice.totalDue}</strong>
                  </span>
                }
              />
              <RadioButton value="partial" label="Partial Payment" />
            </RadioButtonGroup>
            {payPartial === 'partial' && (
              <div className="InvoicePaymentModal__payment-amount animate-slidedown flex flex-col gap-2 py-2">
                <Text>Enter payment amount:</Text>
                <PriceTextInput
                  min={0}
                  max={invoice.totalDue}
                  value={paymentAmount}
                  onChange={setPaymentAmount}
                  className="max-w-[10rem]"
                />
              </div>
            )}
          </div>
          <CreditCardSelect
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
          />
        </div>
      </div>
      {!submitState?.loading && submitState?.error && (
        <div className="InvoicePaymentModal__error-msg bg-danger-50 text-danger animate-slidedown mt-2 rounded p-2 text-center font-bold">
          {submitState.error}
        </div>
      )}
      <div className="InvoicePaymentModal__submit-btns mt-4 mb-1 flex justify-end gap-2">
        <Button disabled={submitState?.loading} onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          kind="primary"
          disabled={submitState?.loading}
          iconLeft={
            submitState?.loading ? (
              <Fa icon="spinner" animate="spin" size={1} />
            ) : undefined
          }
          onClick={handlePayInvoice}
        >
          {submitState?.loading ? 'Submitting' : 'Send Payment'}
        </Button>
      </div>
    </Modal>
  )
}
