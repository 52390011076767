import { useState, useRef } from 'react'
import { Popover, TableColumnType } from '@eltoro-ui/components'
import { dayjs } from 'Tools/dateUtils'
import {
  Campaignservicev1Audience,
  Campaignservicev1Creative,
} from 'next-gen-sdk'
import { EmptyField, InfoPopover, LinkButton } from 'Components'
import {
  getTypeMoneyCost,
  getCreativeFaETIcons,
  getDaysBetween,
  getDurationHours,
  rate,
  rateCeil,
  readableCreativeType,
  userFriendlyAudienceName,
  durationOrDaysLeft,
} from 'Helpers'
import { OrderLineTableRow } from 'types'
import {
  // creativeTypeOptions,
  // audienceTypeOptions,
  // orderLinePaceOptions,
  orderLineStatusOptions,
} from 'searchOptions'
import { OrderlineStateIndicator } from './OrderlineStateIndicator'
import { OrderlinePaceIndicator } from './OrderlinePaceIndicator'
import { OrderlineStatePopover } from './OrderlineStatePopover'
import { OrderlinePacePopover } from './OrderlinePacePopover'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { SpendCell } from './OrderlineTableRow/SpendCell'
import { ETIcon } from 'Assets'

// CellList is for listing audience/creative types in the tables / order line container
export const CellList = ({ content }: { content: string[] }) => {
  const [showList, setShowList] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([dropdownRef], () => setShowList(false))

  if (content.length <= 2)
    return (
      <div className="OrderlineTable__cell-list TableText">
        {content.map((text) => (
          <p key={text}>{text}</p>
        ))}
      </div>
    )
  return (
    <div className="OrderLineTable__cell-list-wrap flex items-center gap-1">
      <div className="OrderlineTable__cell-list TableText text-s py-1 leading-none">
        {content.map((text, index) => {
          if (index <= 1) return <p key={text}>{text}</p>
          return null
        })}
      </div>
      <Popover
        position={['bottom']}
        content={
          <div className="OrderlineTable__cell-list-tooltip" ref={dropdownRef}>
            {content.map((text, index) => {
              if (index > 1) return <p key={text}>{text}</p>
              return null
            })}
          </div>
        }
        isOpen={showList}
      >
        <div title="Show History" className="OrderlineTable__ellipsis">
          <LinkButton
            text={`+ ${content.length - 2}`}
            onClick={(e) => {
              e.stopPropagation()
              setShowList(!showList)
            }}
          />
        </div>
      </Popover>
    </div>
  )
}

export const OrderlineAudiences = ({
  olTargets,
  displayEmptyField = true,
}: {
  olTargets: Campaignservicev1Audience[]
  displayEmptyField?: boolean
}) => {
  const jobTypes = olTargets.reduce((acc: string[], job) => {
    if (!job.productType || (job.productType && acc.includes(job.productType)))
      return acc

    return [...new Set([...acc, userFriendlyAudienceName(job.productType)])]
  }, [])
  const emptyDisplay = displayEmptyField ? <EmptyField /> : null

  return jobTypes.length ? <CellList content={jobTypes} /> : emptyDisplay
}

const OrderlineCreatives = ({
  olCreatives,
}: {
  olCreatives: Campaignservicev1Creative[]
}) => {
  const creativeTypes = olCreatives.reduce((acc: string[], creative) => {
    if (!creative.type || (creative.type && acc.includes(creative.type)))
      return acc

    return [...new Set([...acc, readableCreativeType(creative.type)])]
  }, [])

  return creativeTypes.length ? (
    <CellList content={creativeTypes} />
  ) : (
    <EmptyField />
  )
}

export const OrderlineTableColumns = (
  isAdmin?: boolean,
): TableColumnType<OrderLineTableRow>[] => [
  {
    path: 'name',
    label: 'Order Line',
    filterOn: 'string',
    RowCell: ({ orderLine }) => {
      const creativeType = orderLine.creatives && orderLine.creatives[0]?.type
      return (
        <div className="CreativeType__icon-name-wrap">
          {getCreativeFaETIcons(creativeType)}
          {orderLine.political && (
            <i
              className="PoliticalIcon -ml-2"
              data-tooltip="right"
              aria-label="This is a political order line"
            >
              <ETIcon icon="advocacy" />
            </i>
          )}
          {orderLine.locked && (
            <InfoPopover
              icon="lock"
              content={
                <span className="text-xs">This order line is locked.</span>
              }
              className="!ml-[-0.25rem] !text-lg"
            />
          )}
          <span className="CreativeType__orderLine-name min-w-[15ch]">
            {orderLine.name}
          </span>
        </div>
      )
    },
  },
  {
    path: 'state',
    filterOn: orderLineStatusOptions(isAdmin),
    label: (
      <div className="InfoPopover__wrap flex flex-1 items-center">
        Status <InfoPopover content={<OrderlineStatePopover />} />
      </div>
    ),
    RowCell: (row) => {
      if (
        row?.orderLine.status === 'ORDERLINE_STATUS_PAUSED' &&
        row?.orderLine.state === 'ORDERLINE_STATE_APPROVED'
      ) {
        return (
          <OrderlineStateIndicator
            state={'ORDERLINE_STATE_PAUSED'}
            reason={'Order line is paused, and the start date is in the future'}
          />
        )
      }
      if (
        row?.orderLine.status === 'ORDERLINE_STATUS_DEPLOYED' &&
        row?.orderLine.state === 'ORDERLINE_STATE_APPROVED'
      ) {
        return (
          <OrderlineStateIndicator
            state={'ORDERLINE_STATE_ACTIVE'}
            reason={'Order line has been deployed, but is not serving yet'}
          />
        )
      }
      if (
        row?.orderLine.status === 'ORDERLINE_STATUS_ARCHIVED' &&
        row?.orderLine.state === 'ORDERLINE_STATE_COMPLETED'
      ) {
        return <OrderlineStateIndicator state="ORDERLINE_STATE_ARCHIVED" />
      }
      return row?.orderLine.state !== undefined ? (
        <OrderlineStateIndicator
          state={row.orderLine.state}
          reason={row.orderLine.reason}
        />
      ) : (
        <EmptyField />
      )
    },
  },
  {
    path: 'pace',
    // TODO: Pace isn't something we can filter on the API side
    // filterOn: orderLinePaceOptions,
    removeSort: true,
    removeFilter: true,
    label: (
      <div className="flex items-center">
        Pace <InfoPopover content={<OrderlinePacePopover />} />
      </div>
    ),
    RowCell: (row) => {
      const { status, state } = row.orderLine
      if (
        (row.orderLine.startTime &&
          dayjs.tz(row.orderLine.startTime).isAfter(dayjs().tz())) ||
        status === 'ORDERLINE_STATUS_DRAFT' ||
        status === 'ORDERLINE_STATUS_REVIEW_PENDING' ||
        status === 'ORDERLINE_STATUS_REVIEW_STARTED'
      )
        return <OrderlinePaceIndicator pace="not started" />
      if (
        status === 'ORDERLINE_STATUS_PAUSED' ||
        state === 'ORDERLINE_STATE_PAUSED'
      )
        return <OrderlinePaceIndicator pace="paused" />
      if (row.stats && row.stats.totals) {
        // help me
        const { startTime, endTime, impressions } = row.orderLine
        const { totals } = row.stats

        const orderedImps = impressions || 0
        const servedImpressions = Number(totals?.imps || 0)
        if (startTime && endTime) {
          const isBeforeEndDate = dayjs().tz().isBefore(dayjs.tz(endTime))

          const durationDays = isBeforeEndDate
            ? getDaysBetween(startTime, new Date())
            : getDaysBetween(startTime, endTime)
          const durationHours = durationDays * 24
          const hoursServed = isBeforeEndDate
            ? getDurationHours(startTime, new Date())
            : getDurationHours(startTime, endTime)

          const todayServedImps = 0

          const perHrImpServed =
            hoursServed > 0
              ? rateCeil(servedImpressions - todayServedImps, hoursServed)
              : 0
          const perHrImpTotal =
            durationHours > 0 ? rateCeil(orderedImps, durationHours) : 0

          const impServedRatio = rate(perHrImpServed, perHrImpTotal)

          //  over pace
          if (servedImpressions > 0 && impServedRatio > 1.05)
            return <OrderlinePaceIndicator pace="over" />
          //  on pace
          if (
            servedImpressions > 0 &&
            impServedRatio >= 0.9 &&
            impServedRatio <= 1.05
          )
            return <OrderlinePaceIndicator pace="on" />
          //  under pace
          if (servedImpressions > 0 && impServedRatio < 0.9)
            return <OrderlinePaceIndicator pace="under" />
        }
      }

      return <EmptyField />
    },
  },
  {
    path: 'cost',
    label: 'Budget',
    removeFilter: true,
    removeSort: true,
    RowCell: (row) => {
      if (!row.orderLine.costRange?.estimate?.units) return <EmptyField />
      const { estimate } = row.orderLine.costRange
      return `$${getTypeMoneyCost(estimate).toFixed(2)}`
    },
  },
  {
    path: 'spent',
    label: 'Spent',
    removeFilter: true,
    removeSort: true,
    RowCell: (row) => <SpendCell row={row} />,
  },
  {
    path: 'end_time',
    label: 'Days left',
    removeFilter: true,
    RowCell: (row) => {
      const daysLeft = durationOrDaysLeft(row.orderLine)
      return row?.orderLine.endTime ? (
        <div>
          {daysLeft} day{daysLeft === 1 ? '' : 's'}
        </div>
      ) : (
        <EmptyField />
      )
    },
  },
  {
    path: 'audience',
    label: 'Audience',
    removeSort: true,
    // TODO: How can we filter by order line's audience/creative types via API?
    // filterOn: audienceTypeOptions,
    removeFilter: true,
    RowCell: (row) => {
      return row.orderLine.audiences ? (
        <OrderlineAudiences olTargets={row.orderLine.audiences} />
      ) : null
    },
  },
  {
    path: 'creatives',
    label: 'Creative',
    removeSort: true,
    // filterOn: creativeTypeOptions,
    removeFilter: true,
    RowCell: (row) => {
      return row.orderLine.creatives ? (
        <OrderlineCreatives olCreatives={row.orderLine.creatives} />
      ) : null
    },
  },
]
