import { PreviewWithId } from 'Components'
import { getApiUrl } from 'Helpers'
import axios from 'axios'

export const supportRequest = async (
  message: string,
  title: string,
  orgId: string,
  messageType?: 'billing' | 'general',
  files?: PreviewWithId[],
) => {
  const url = `${getApiUrl()}/v1`
  const token = localStorage.getItem('eltoro_token')
  const config = { Authorization: `Bearer ${token}` }

  const groupedFiles = files?.reduce(
    (acc: { [key: string]: PreviewWithId[] }, file) => {
      // if filename is already found in the array, add it to the object
      if (acc[file.file.name]) {
        return { ...acc, [file.file.name]: [...acc[file.file.name], file] }
      }
      // if not, add new key to object
      return { ...acc, [file.file.name]: [file] }
    },
    {},
  )
  const data = new FormData()
  if (groupedFiles) {
    Object.entries(groupedFiles).forEach(([filename, files]) => {
      files.forEach((file, i) => {
        if (i > 0) {
          // add number to file name
          const split = file.file.name.split('.')
          const newFileName = `${split
            .slice(0, -1)
            .join('.')}(${i}).${split.slice(-1)}`
          data.append('chunk', file.file, newFileName)
        } else data.append('chunk', file.file)
      })
    })
  }
  data.append('org_id', orgId)
  /*
    Message type enums:
      MESSAGE_TYPE_UNSPECIFIED = 0;
      MESSAGE_TYPE_BILLING = 1;
      MESSAGE_TYPE_GENERAL = 2;
  */
  const getMsgType = () => {
    if (messageType === 'billing') return 1
    return 2
  }
  data.append('message', JSON.stringify({ message, title, type: getMsgType() }))

  await axios
    .post(`${url}/notifications/support?org_id=${orgId}`, data, {
      headers: config,
    })
    .then((res) => {
      return res
    })
    .catch((err) => {
      if (err) {
        throw new Error(
          JSON.stringify({
            status: err?.response?.status,
            message: err?.response?.data,
          }),
        )
      }
    })
}
