import { Fa, Text } from '@eltoro-ui/components'
import classNames from 'classnames'
import { TableRowLoadingIcon } from 'Components/TableRowLoadingIcon'
import { useAppContext } from 'Contexts'
import { capitalize, getDaysBetween, styleTailwind } from 'Helpers'
import { getJobQuotes } from 'Requests'
import { Creativeservicev1Type, Campaignservicev1OrderLine } from 'next-gen-sdk'
import { ChangeEvent, useEffect } from 'react'
import { useState } from 'react'
import {
  // LabelInputPair,
  NA,
  NumberInput,
  TabletLabel,
  IMPRESSION_MIN,
} from '../shared'
import {
  ImpressionsEditGridRow,
  ImpressionsChangeType,
} from './ImpressionsEdit'

const InputWithIcon = styleTailwind(
  'div',
  'ImpressionsOLRow__InputWithIcon flex items-center gap-4',
)
const EditOLRowItem = styleTailwind(Text, 'EditOLRowItem__item')

const AdminFrequencyIcon = ({
  frequency,
}: {
  frequency: 'high' | 'low' | null
}) => {
  if (!frequency) return null
  return (
    <span
      data-tooltip="top"
      aria-label={`${capitalize(frequency)} Frequency`}
      className="ImpressionsOLRow__AdminFrequencyIcon font-normal"
    >
      <Fa
        icon={`gauge-${frequency}`}
        size={1}
        className={frequency === 'high' ? 'text-warning' : 'text-danger'}
      />
    </span>
  )
}

const calculateImps = async (
  changed: number,
  type: 'imps' | 'frequency' | 'flight',
  totalDays: number,
  totalAudiences: number | 'Quoting',
): Promise<{
  impressions: number
  frequency: number
  flight: number
}> => {
  if (
    totalDays &&
    (totalAudiences || totalAudiences === 0) &&
    totalAudiences !== 'Quoting'
  ) {
    if (type === 'imps') {
      const frequency =
        Math.floor((changed / totalAudiences / totalDays) * 100) / 100
      const flight = Math.floor(changed / totalAudiences)
      return { impressions: changed, frequency, flight }
    }
    if (type === 'frequency') {
      const impressions = changed * totalAudiences * totalDays
      const flight = Math.floor(impressions / totalAudiences)
      return { impressions, frequency: Number(changed), flight }
    }
    if (type === 'flight') {
      const impressions = totalAudiences * changed
      const frequency =
        Math.floor((impressions / totalAudiences / totalDays) * 100) / 100
      return { impressions, frequency, flight: changed }
    }
  }
  return {
    impressions: 0,
    frequency: 0,
    flight: 0,
  }
}

export const ImpressionsOLRow = ({
  orderLine,
  onChange,
}: {
  orderLine: Campaignservicev1OrderLine
  onChange: (change: ImpressionsChangeType) => void
}) => {
  const { impressions: olOriginalImpressions, audiences } = orderLine
  const [editImps, setEditImps] = useState(
    olOriginalImpressions?.toString() || '',
  )
  const [editFrequency, setEditFrequency] = useState('')
  const [editPerFlight, setEditPerFlight] = useState('')
  const [totalAudiences, setTotalAudiences] = useState<number>()
  const { currentOrg, isAdmin, roles } = useAppContext()

  const totalDays =
    (orderLine.startTime &&
      orderLine.endTime &&
      getDaysBetween(orderLine.startTime, orderLine.endTime)) ||
    0
  const isRetargerting = !!audiences?.find(
    (aud) => aud.type === 'AUDIENCE_TYPE_RETARGETING',
  )
  const isMapPoly = !!audiences?.find(
    (aud) => aud.type === 'AUDIENCE_TYPE_IPSFORGEOJSON',
  )

  // Initial impression counts + get total audience matches
  useEffect(() => {
    if (audiences && currentOrg?.id) {
      const counter = async () => {
        if (!currentOrg?.id) return
        const jobsQuoted = await getJobQuotes(audiences, currentOrg.id)
        if (!jobsQuoted?.quoteStatus) return
        const allQuoted = jobsQuoted?.quoteStatus.every(
          (status) => status.quoted === true,
        )
        if (allQuoted) {
          setTotalAudiences(jobsQuoted?.count || 0)
          if (olOriginalImpressions === undefined) return
          calculateImps(
            olOriginalImpressions,
            'imps',
            totalDays,
            jobsQuoted?.count || 0,
          ).then(({ frequency, flight }) => {
            setEditPerFlight(flight.toString())
            setEditFrequency(frequency.toFixed(2).toString())
          })
        }
      }
      counter()
    }
  }, [audiences, currentOrg?.id, olOriginalImpressions, totalDays])

  const minChecker = orderLine?.minimumImpressions || IMPRESSION_MIN
  const minImpsError = Number(editImps) < minChecker

  const handleUpdateImpressions = (
    value: string,
    type: 'imps' | 'frequency' | 'flight',
  ) => {
    // if impressions changed, change frequency and flight
    if (type === 'imps') {
      setEditImps(value)
      calculateImps(Number(value), 'imps', totalDays, totalAudiences || 0).then(
        ({ impressions, frequency, flight }) => {
          setEditFrequency(frequency.toFixed(2).toString())
          setEditPerFlight(flight.toString())
          if (orderLine.id) onChange({ olId: orderLine.id, impressions })
        },
      )
    }
    // if frequency changed, change imps and flight
    if (type === 'frequency') {
      setEditFrequency(value)
      calculateImps(
        Number(value),
        'frequency',
        totalDays,
        totalAudiences || 0,
      ).then(({ impressions, flight }) => {
        setEditImps(impressions.toFixed().toString())
        setEditPerFlight(flight.toString())
        if (orderLine.id)
          onChange({
            olId: orderLine.id,
            impressions: Number(impressions.toFixed()),
          })
      })
    }
    // if flight changed, change imps and frequency
    if (type === 'flight') {
      setEditPerFlight(value)
      calculateImps(
        Number(value),
        'flight',
        totalDays,
        totalAudiences || 0,
      ).then(({ impressions, frequency }) => {
        setEditImps(impressions.toString())
        setEditFrequency(frequency.toString())
        if (orderLine.id) onChange({ olId: orderLine.id, impressions })
      })
    }
  }

  const handleInputKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === 'E') e.preventDefault()
  }
  const flightAndFreqCheck = roles?.includes('nextgen_admin') ? 1 : minChecker
  const minimumFrequency =
    Math.floor(
      (flightAndFreqCheck / (totalAudiences || 1) / (totalDays || 1)) * 100,
    ) / 100
  // const minimumFlight = Math.floor(flightAndFreqCheck / (totalAudiences || 1))

  const BANNER_FREQUENCY = 2
  const VIDEO_FREQUENCY = 1
  const OTT_FREQUENCY = 0.5

  const isFrequencyHighLow = () => {
    const orderLineCreativeType =
      (orderLine.creatives &&
        orderLine.creatives.length > 0 &&
        orderLine.creatives?.[0].type) ||
      'CREATIVE_TYPE_UNSPECIFIED'
    if (orderLineCreativeType) {
      if (
        ([
          'CREATIVE_TYPE_BANNER',
          'CREATIVE_TYPE_AD_TAG',
          'CREATIVE_TYPE_HTML5',
          'CREATIVE_TYPE_NATIVE_BANNER',
        ] as Creativeservicev1Type[]).includes(orderLineCreativeType)
      ) {
        if (Number(editFrequency) > BANNER_FREQUENCY) return 'high'
        if (Number(editFrequency) < BANNER_FREQUENCY) return 'low'
      }
      if (
        ([
          'CREATIVE_TYPE_VAST_TAG',
          'CREATIVE_TYPE_NATIVE_VIDEO',
          'CREATIVE_TYPE_VIDEO',
        ] as Creativeservicev1Type[]).includes(orderLineCreativeType)
      ) {
        if (Number(editFrequency) > VIDEO_FREQUENCY) return 'high'
        if (Number(editFrequency) < VIDEO_FREQUENCY) return 'low'
      }

      if (orderLineCreativeType === 'CREATIVE_TYPE_OTT') {
        if (Number(editFrequency) > OTT_FREQUENCY) return 'high'
        if (Number(editFrequency) < OTT_FREQUENCY) return 'low'
      }
    }
    return null
  }

  return (
    <ImpressionsEditGridRow className="ImpressionsOLRow EditOLRowItem items-center">
      <EditOLRowItem className="pr-3">
        <TabletLabel>Order Line Name</TabletLabel>
        <Text>{orderLine.name}</Text>
      </EditOLRowItem>
      <EditOLRowItem>
        <TabletLabel>Total Impressions</TabletLabel>
        <InputWithIcon>
          <NumberInput
            value={editImps}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target
              handleUpdateImpressions(value, 'imps')
            }}
            type="number"
            min={roles?.includes('nextgen_admin') ? 1 : minChecker}
            onKeyDown={handleInputKeydown}
            className={classNames('NumberInput__edit-impressions', {
              'animate-glowwarning': minImpsError,
            })}
          />
          {Number(editImps) <
          (orderLine.minimumImpressions || IMPRESSION_MIN) ? (
            <span
              data-tooltip="right"
              aria-label={`The minimum total impressions is ${
                orderLine?.minimumImpressions || IMPRESSION_MIN
              }`}
              className="ImpressionsOLRow__min-imps-warning font-normal"
            >
              <Fa icon="circle-info" size={1} className="text-warning" />
            </span>
          ) : null}
        </InputWithIcon>
      </EditOLRowItem>
      {(() => {
        if (totalAudiences === 0) return <>{NA}</>
        if (totalAudiences === undefined)
          return (
            <>
              <TableRowLoadingIcon className="max-w-min" />
              <TableRowLoadingIcon className="max-w-min" />
            </>
          )
        return (
          <>
            <EditOLRowItem>
              <TabletLabel>Frequency</TabletLabel>
              <InputWithIcon>
                {isRetargerting || isMapPoly ? (
                  <div>N/A</div>
                ) : (
                  <NumberInput
                    step=".01"
                    value={editFrequency}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleUpdateImpressions(e.target.value, 'frequency')
                    }
                    type="number"
                    min={minimumFrequency}
                    disabled={isRetargerting || isMapPoly}
                    onKeyDown={handleInputKeydown}
                    className={classNames('NumberInput__edit-frequency', {
                      'animate-glowwarning': minImpsError,
                    })}
                  />
                )}
                {isAdmin && !isRetargerting && !isMapPoly && (
                  <AdminFrequencyIcon frequency={isFrequencyHighLow()} />
                )}
              </InputWithIcon>
            </EditOLRowItem>
            {/* AdOps wants this removed, remove perm once it's finalized - https://eltoro.testrail.io/index.php?/tests/view/52935&group_by=cases:section_id&group_order=asc&group_id=1298 */}
            {/* <EditOLRowItem>
              <TabletLabel>Impressions/target</TabletLabel>
              <div className="flex items-center ImpressionOL__target laptopsmall:flex-wrap">
                <NumberInput
                  value={editPerFlight}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleUpdateImpressions(e.target.value, 'flight')
                  }
                  type="number"
                  min={minimumFlight}
                  disabled={loading}
                  onKeyDown={handleInputKeydown}
                  className={classNames('NumberInput__flight', {
                    'animate-glowwarning': minImpsError,
                  })}
                />
                <p className="font-semibold">&nbsp;/&nbsp;{totalDays} days</p>
              </div>
            </EditOLRowItem> */}
          </>
        )
      })()}
    </ImpressionsEditGridRow>
  )
}
