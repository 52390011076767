import { useState } from 'react'
import { TableColumnType, TableRow } from '@eltoro-ui/components'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { useNavigate } from 'react-router-dom'
import {
  CollapseButton,
  EmptyField,
  LinkButton,
  OLTableUpdateRow,
} from 'Components'
import { OrderLineTableRow } from 'types'
import { OrderlineContainer } from '../OrderlineContainer'
import { CampaignContainer } from '../CampaignContainer'
import { statMakerForEnvironment } from 'Components/Reports/Stats/statMockData'
import { getOrderLineStats } from 'Helpers/getOrderLineStats'

export const OrderlineTableRow = ({
  row,
  // selectingReports,
  // selectedOrderlines,
  // handleSelectOrderline,
  onOpen,
  onClose,
  opened,
  childOrgsOnly,
  columns,
  handleUpdateRow,
}: {
  row: OrderLineTableRow
  // selectingReports: boolean
  // selectedOrderlines: Campaignservicev1OrderLine[]
  // handleSelectOrderline: (ol: Campaignservicev1OrderLine, checked: boolean) => void
  onOpen: (o: {
    orderline: Campaignservicev1OrderLine
    type: 'campaign' | 'ol'
  }) => void
  onClose: () => void
  columns: TableColumnType<OrderLineTableRow>[]
  opened?: { orderline: Campaignservicev1OrderLine; type: 'campaign' | 'ol' }
  childOrgsOnly?: boolean
  handleUpdateRow: OLTableUpdateRow
}) => {
  const [olStats, setOlStats] = useState({})
  const history = useNavigate()

  const tableColumns = [
    ...columns,
    {
      path: 'campaignId',
      label: 'Campaign',
      RowCell: () => {
        return row.orderLine.campaign?.name ? (
          <LinkButton
            text={row.orderLine.campaign.name}
            onClick={(e) => {
              e.stopPropagation()
              if (row.orderLine.id && row.orderLine.campaign?.name) {
                onOpen({
                  orderline: row.orderLine,
                  type: 'campaign',
                })
              }
            }}
            truncate
          />
        ) : (
          <EmptyField />
        )
      },
    },
    // TODO: Check that this works when the order line table is receiving ALL order lines
    ...(childOrgsOnly
      ? [
          {
            path: '',
            label: 'Child Org',
            RowCell: () => {
              return row.childOrg && row.childOrg.name ? (
                <LinkButton
                  text={row.childOrg.name}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (row.childOrg?.id)
                      history(`/orgs/${row.childOrg.id}/org-settings`)
                  }}
                  truncate
                />
              ) : null
            },
          },
        ]
      : []),
    {
      path: '',
      RowCell: () => (
        <div className="OrderlineTableRow__collapse-btn-wrap">
          <CollapseButton
            classNameButton="OrderlineTableRow__collapse-btn flex-1 pr-3"
            className="m-0"
            isCollapsed
            handleClick={() => {}}
            iconOnly
          />
        </div>
      ),
    },
  ]

  return (
    <>
      {!opened || (opened && opened.orderline.id !== row.orderLine.id) ? (
        <TableRow
          key={`TableRow-${row.orderLine.id}`}
          row={row}
          columns={tableColumns}
          onClick={() => {
            if (row.orderLine.id && row.orderLine.orgId) {
              getOrderLineStats(row.orderLine, row.orderLine.orgId, 'day').then(
                (olStatsResp) => olStatsResp && setOlStats(olStatsResp),
              )
              onOpen({ orderline: row.orderLine, type: 'ol' })
            }
          }}
          // selected={
          //   selectingReports &&
          //   !!selectedOrderlines.find((ol) => ol.id === row.id)
          // }
          // handleSelectRow={selectingReports ? handleSelectOrderline : undefined}
          getColspan={() => tableColumns.length + 3}
        />
      ) : (
        <tr
          key={`OpenedContainer-${row.orderLine.id}`}
          className="OrderlineTableRow__expanded-row bg-base border-y-thin border-tint-gray-400 relative z-0 border-solid"
        >
          <td
            colSpan={tableColumns.length + 3}
            className="TableCell OrderlineTableRow__expanded-cell animate-slidedown z-0 !pr-0"
          >
            {opened?.type === 'campaign' &&
            row.orderLine.campaign?.id &&
            row.orderLine.orgId ? (
              <CampaignContainer
                campaignId={row.orderLine.campaign.id}
                handleCollapse={() => onClose()}
                orgId={row.orderLine.orgId}
              />
            ) : (
              <OrderlineContainer
                stats={
                  process.env.REACT_APP_ENV === 'production' &&
                  !/dev/.test(window.location.href)
                    ? olStats
                    : statMakerForEnvironment(row.orderLine)
                }
                orderline={row.orderLine}
                handleCollapse={() => {
                  onClose()
                }}
                handleOpenCampaign={() =>
                  row.orderLine.id &&
                  onOpen({
                    orderline: row.orderLine,
                    type: 'campaign',
                  })
                }
                handleUpdateRow={handleUpdateRow}
              />
            )}
          </td>
        </tr>
      )}
    </>
  )
}
