import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'
import { dayjs } from 'Tools/dateUtils'

export const saveCampaignFlightDates = async (
  startDate: Date,
  endDate: Date,
  orderLines: Campaignservicev1OrderLine[],
  campaignId: string,
  orgId: string,
) => {
  const token = localStorage.getItem('eltoro_token')
  const apis = ApiFactory(token)
  const { campaignServiceAPI } = apis
  const updateFlightDates = orderLines.reduce(
    (acc: Promise<Campaignservicev1OrderLine>[], currentOL) => {
      if (!currentOL?.id || !campaignServiceAPI) return acc
      return [
        ...acc,
        campaignServiceAPI.advertisingPlatformServiceUpdateOrderLine(
          currentOL.id,
          orgId,
          {
            startTime: dayjs.tz(startDate).startOf('day').utc().toDate(),
            endTime: dayjs.tz(endDate).endOf('day').utc().toDate(),
          },
          'start_time,end_time',
        ),
      ]
    },
    [],
  )

  const campaign = await Promise.all(updateFlightDates).then(async () => {
    const token = localStorage.getItem('eltoro_token')
    const apis = ApiFactory(token)
    const { campaignServiceAPI } = apis
    const updatedCampaign = await campaignServiceAPI?.advertisingPlatformServiceGetCampaign(
      campaignId,
      orgId,
    )
    return updatedCampaign
  })

  return campaign
}
