import { useState } from 'react'
import { Button, Fa, Text, TextHeader, Toggle } from '@eltoro-ui/components'
import { styleTailwind } from 'Helpers'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { CCModal } from 'Components/CCModal'
import { useAppContext, useCampaignContext } from 'Contexts'

const WarningMessageText = styleTailwind(
  Text,
  'WarningMessageText text-warning text-s border rounded bg-warning-50 p-2 w-full',
)
const WarningMessage = styleTailwind(
  'div',
  'WarningMessage flex flex-col mb-4 mt-1 items-center text-warning w-full gap-1 animate-slidedown',
)

export const SubmitOrderForReview = ({
  isReady,
  errorMessages,
  rejectMessage,
  buttonText,
  onSubmit,
  loading,
  isPrepay,
  submittableOrderlines,
  onHold,
  isACardOnFile,
  onSaveCreditCard,
  isBillingReady,
}: {
  isReady: boolean
  onSubmit: () => Promise<void>
  loading: boolean
  errorMessages?: Array<React.ReactNode>
  rejectMessage?: string
  buttonText?: string
  isPrepay?: boolean
  submittableOrderlines?: (Campaignservicev1OrderLine | undefined)[]
  onHold?: string[]
  isACardOnFile?: boolean
  onSaveCreditCard?: () => void
  isBillingReady?: boolean
}) => {
  const [agree, setAgree] = useState(false)
  const [prepayAgree, setPrepayAgree] = useState(false)
  const [showCCModal, setShowCCModal] = useState(false)
  const { currentOrg } = useAppContext()
  const { isPolitical } = useCampaignContext()
  const holdhelper = () => {
    if (onHold && onHold.length > 0) {
      if (onHold.includes('accountingHold')) {
        return 'Your account is on Accounting Hold, please contact your rep for more information.'
      }
      if (onHold.includes('billingHold')) {
        return 'Your account is on Billing Accounting Hold, please contact your rep for more information.'
      }
      if (onHold.includes('adminAccountingHold')) {
        return `Your account is on Administrative Accounting Hold, 
        please contact your rep for more information.`
      }
      if (onHold.includes('inCollections')) {
        return 'Your account is in collections, please contact your rep for more information.'
      }
    }
  }

  return (
    <div className="SubmitOrderForReview">
      <TextHeader className="text-primary" type={4}>
        Submit for Approval
      </TextHeader>

      {isPrepay && (
        <div className="SubmitOrderForReview__prepay-confirmation desktop:flex gap-3 pt-2">
          <Toggle
            size="s"
            isChecked={prepayAgree}
            onChange={(e) => setPrepayAgree(e)}
          />
          <div className="SubmitOrderForReview__Body-acknowledge text-s laptop:pt-2 pr-2 leading-tight">
            I acknowledge that I am required to pre-pay for this order.
          </div>
        </div>
      )}
      <div className="SubmitOrderForReview__confirmation desktop:flex gap-3 pt-2">
        <Toggle size="s" isChecked={agree} onChange={(e) => setAgree(e)} />

        <div className="SubmitOrderForReview__Body-acknowledge text-s laptop:pt-2 pr-2 leading-tight">
          I have reviewed this order and the costs and fees associated with it.
          By submitting this order, I hereby affirm that I am an authorized
          purchaser of the entity who provided my access to this Portal and
          agree to the payment terms as specified in the El Toro Terms of
          Service executed by that entity.
        </div>
      </div>
      <div className="SubmitOrderForReview__Footer flex flex-col items-center justify-center gap-1 py-4">
        <WarningMessage>
          {rejectMessage ? (
            <WarningMessageText tag="p">{rejectMessage}</WarningMessageText>
          ) : (
            ''
          )}
          {errorMessages?.map((msg, i) => (
            <WarningMessageText key={i} tag="p">
              {msg}
            </WarningMessageText>
          ))}
          {(onHold || []).length > 0 ? (
            <WarningMessageText>{holdhelper()}</WarningMessageText>
          ) : null}
          {isPolitical && (
            <WarningMessageText>
              If a creative does not have a legible "Paid for By" statement, it
              will be removed from the order line.
            </WarningMessageText>
          )}
        </WarningMessage>
        {!isACardOnFile &&
        !currentOrg?.overrideCreditCardRequirement &&
        !currentOrg?.billableOrgId ? (
          <Button
            kind="primary"
            className={`SubmitOrderForReview__Footer-button w-full font-semibold ${'text-l'}`}
            type="button"
            size="l"
            disabled={isBillingReady}
            onClick={() => {
              setShowCCModal(true)
            }}
            iconLeft={
              loading ? (
                <Fa icon="spinner" animate="spin" size={1} />
              ) : undefined
            }
          >
            {loading ? 'Loading' : 'Add credit card'}
          </Button>
        ) : (
          <Button
            kind="primary"
            className={`SubmitOrderForReview__Footer-button w-full font-semibold ${
              !agree || (isPrepay && !prepayAgree) ? 'text-s' : 'text-l'
            }`}
            type="submit"
            size="l"
            onClick={() => {
              isReady && onSubmit()
            }}
            disabled={
              !isReady ||
              !!(errorMessages && errorMessages.length > 0) ||
              !agree ||
              (isPrepay && !prepayAgree) ||
              (submittableOrderlines && submittableOrderlines.length === 0) ||
              (onHold && onHold.length > 0) ||
              loading ||
              (!isACardOnFile &&
                !currentOrg?.billableOrgId &&
                !currentOrg?.overrideCreditCardRequirement)
            }
            iconLeft={
              loading ? (
                <Fa icon="spinner" animate="spin" size={1} />
              ) : undefined
            }
          >
            {!agree || (isPrepay && !prepayAgree)
              ? 'You must agree to the above'
              : buttonText}
          </Button>
        )}
        <div className="SubmitOrderForReview__Footer-note flex gap-1 pt-4">
          <Fa className="text-primary" icon="lock" size="sm" />
          <small>Secure &amp; Encrypted</small>
        </div>
        {showCCModal && (
          <CCModal
            handleClose={() => setShowCCModal(false)}
            onSave={onSaveCreditCard}
          />
        )}
      </div>
    </div>
  )
}
