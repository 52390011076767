import { useState } from 'react'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import {
  Loader,
  Text,
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from '@eltoro-ui/components'
import { styleTailwind } from 'Helpers'
import { useAppContext, useCampaignContext } from 'Contexts'
import { ImpressionsOLRow } from './ImpressionsOLRow'
import { EditingCancelUpdateButtons } from '../EditingCancelUpdateButtons'
import { IMPRESSION_MIN } from '../shared'
import classNames from 'classnames'

export const ImpressionsEditGridRow = styleTailwind(
  'div',
  'ImpressionsEdit__GridRow grid grid-cols-3 laptopsmall:flex laptopsmall:flex-wrap laptopsmall:justify-between',
)
const TableHeader = styleTailwind(
  'div',
  'EditOLTable__header-text flex flex-col',
)
const TableSubHeader = styleTailwind(
  Text,
  'text-s tint-gray-700 font-regular normal-case',
)
export type ImpressionsChangeType = { olId: string; impressions: number }

export const ImpressionsEdit = ({
  className = '',
  selectedOls,
  onCloseModal,
  setRefreshCampaign,
}: {
  className?: string
  selectedOls: Campaignservicev1OrderLine[]
  onCloseModal: () => void
  setRefreshCampaign?: (x: boolean) => void
}) => {
  const [orderLineChanges, setOrderLineChanges] = useState<
    ImpressionsChangeType[]
  >([])
  const [submitting, setSubmitting] = useState(false)
  const { campaignServiceApi, currentOrg, isAdmin } = useAppContext()
  const { setRefresh } = useCampaignContext()

  const handleUpdate = async () => {
    setSubmitting(true)

    let succeeded: { ol: Campaignservicev1OrderLine; note?: string }[] = []
    let failed: { ol: Campaignservicev1OrderLine; note?: string }[] = []

    await Promise.all(
      orderLineChanges.map(async (change) => {
        const prevOl = selectedOls.find((c) => c.id === change.olId)
        if (!campaignServiceApi || !currentOrg?.id || !prevOl) return

        const minChecker = prevOl?.minimumImpressions || IMPRESSION_MIN
        const minImpsError = Number(change.impressions) < minChecker
        if (minImpsError && !isAdmin) {
          failed.push({ ol: prevOl, note: 'minImpsError' })
          return
        }
        let ol
        if (minImpsError && isAdmin) {
          ol = await campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
            change.olId,
            currentOrg?.id || '',
            {
              impressions: change.impressions,
              minimumImpressions: change.impressions,
            },
          )
          succeeded = [...succeeded, { ol, note: 'impsBelowMinimum' }]
          return
        }
        ol = await campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
          change.olId,
          currentOrg?.id,
          {
            impressions: change.impressions,
          },
          'impressions',
        )
        succeeded = [...succeeded, { ol }]
      }),
    )
      .catch((e) => {
        // general API error catch
        showErrorMessage(
          'Impressions update failed',
          e?.message || e?.body?.message || '',
        )
      })
      .finally(() => setSubmitting(false))

    const impErrored = failed.filter((f) => f.note === 'minImpsError')

    if (impErrored.length > 0) {
      const ResultMsg = () =>
        impErrored.length === 1 ? (
          <>{`You may not set total impressions to lower than ${
            impErrored[0]?.ol?.minimumImpressions || IMPRESSION_MIN
          }`}</>
        ) : (
          <>
            <Text className="text-sm">
              Some order lines were not updated due to minimum impressions
              constraints.
              <ul className="pt-2">
                {impErrored.map((r) => (
                  <ul key={r.ol.id}>
                    <li className="font-bold">{r?.ol?.name}</li>
                    <ul className="list-disc">
                      <li>{`You may not set total impressions to lower than ${
                        r?.ol?.minimumImpressions || IMPRESSION_MIN
                      }`}</li>
                    </ul>
                  </ul>
                ))}
              </ul>
            </Text>
          </>
        )

      showWarningMessage(
        `Unable to update order line${impErrored.length > 1 ? 's' : ''}`,
        <ResultMsg />,
      )
      return
    }

    if (succeeded.length > 0) {
      const successesWithBelowMinImps = succeeded.filter(
        (r) => r.note === 'impsBelowMinimum',
      )

      if (successesWithBelowMinImps.length > 0) {
        const ResultMsg = () =>
          successesWithBelowMinImps.length === 1 ? (
            <>
              {successesWithBelowMinImps[0]?.ol?.minimumImpressions &&
              currentOrg?.minimumImpressions &&
              successesWithBelowMinImps[0]?.ol?.minimumImpressions <
                currentOrg.minimumImpressions
                ? `You are now going lower than the current order line minimum of ${successesWithBelowMinImps[0]?.ol.minimumImpressions}.`
                : `You are now going lower than the current org minimum of ${
                    currentOrg?.minimumImpressions || 'N/A'
                  }.`}
            </>
          ) : (
            <>
              <Text className="text-sm">
                Some order lines were updated and below the minimum impressions.
                <ul className="pt-2">
                  {successesWithBelowMinImps.map((r) => (
                    <ul>
                      <li className="font-bold">{r?.ol?.name}</li>
                      <ul className="list-disc">
                        <li>
                          {r?.ol?.minimumImpressions &&
                          currentOrg?.minimumImpressions &&
                          r?.ol?.minimumImpressions <
                            currentOrg.minimumImpressions
                            ? `You are now going lower than the current order line minimum of ${r?.ol.minimumImpressions}.`
                            : `You are now going lower than the current org minimum of ${
                                currentOrg?.minimumImpressions || 'N/A'
                              }.`}
                        </li>
                      </ul>
                    </ul>
                  ))}
                </ul>
              </Text>
            </>
          )
        showWarningMessage('Total impressions is below minimum!', <ResultMsg />)
      } else {
        showSuccessMessage('Impressions updated successfully', '')
      }
      onCloseModal()
      if (setRefreshCampaign) setRefreshCampaign(true)
      setRefresh(true)
    }
  }

  return (
    <div className={`ImpressionsEdit ${className}`}>
      <ImpressionsEditGridRow className="ImpressionsEdit__grid-header EditOLTable__header StickyHeader laptopsmall:hidden">
        <TableHeader>Order Line Name</TableHeader>
        <TableHeader>
          Total Impressions
          <TableSubHeader></TableSubHeader>
        </TableHeader>
        <TableHeader>
          Frequency
          <TableSubHeader>Impressions per target per day</TableSubHeader>
        </TableHeader>
        {/* <TableHeader>
          Impressions
          <TableSubHeader>Impressions per target per flight</TableSubHeader>
        </TableHeader> */}
      </ImpressionsEditGridRow>
      <div
        className={classNames('EditOLTable__body', {
          'pointer-events-none relative opacity-30': submitting,
        })}
      >
        {submitting && (
          <div className="t-0 l-0 absolute h-full w-full">
            <Loader />
          </div>
        )}
        {selectedOls.map((ol, i) => (
          <ImpressionsOLRow
            orderLine={ol}
            key={`ImpressionsOLRow__${ol.id}_${i}`}
            onChange={(change) =>
              setOrderLineChanges((prev) => {
                const filtered = prev.filter((p) => p.olId !== change.olId)
                return [...filtered, change]
              })
            }
          />
        ))}
      </div>
      <EditingCancelUpdateButtons
        onCloseModal={onCloseModal}
        handleUpdate={handleUpdate}
        updateDisabled={!orderLineChanges.length || submitting}
      />
    </div>
  )
}
