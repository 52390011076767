import { useState, useEffect } from 'react'
import { Fa, Text } from '@eltoro-ui/components'
import { useLocation } from 'react-router-dom'
import { Campaignservicev1Audience } from 'next-gen-sdk'
import { useAppContext, useCampaignContext } from 'Contexts'
import { styleTailwind } from 'Helpers'
import { AudienceCard, CampaignDetails, CreativeCard } from './components'
import './ShoppingCart.scss'

export const AssetHeader = styleTailwind(
  'div',
  'ShoppingCart__asset-header text-l text-grey-700 font-bold uppercase pt-4 pb-2 flex',
)

const AssetHeaderWithSkipBadge = ({
  skipped,
  children,
}: {
  skipped: boolean
  children: React.ReactNode
}) => (
  <AssetHeader>
    {children}
    {skipped && (
      <div className="ShoppingCart__asset-header-skip-badge bg-secondary-100 text-secondary-600 mx-2 flex items-center rounded-full px-2 text-xs uppercase">
        Skipped
      </div>
    )}
  </AssetHeader>
)

const AssetContainer = styleTailwind(
  'div',
  'ShoppingCart__asset-container divide-grey-100 divide-y',
)

export const ShoppingCart = () => {
  const {
    campaign,
    attachedCreatives: creatives,
    attachedAudiences: audiences,
    handleRemoveAudienceFromCampaign,
    handleRemoveCreativeFromCampaign,
  } = useCampaignContext()
  const [currentAudiences, setCurrentAudiences] = useState<
    Campaignservicev1Audience[] | undefined
  >()
  const { audienceServiceApi, currentOrg } = useAppContext()
  const { pathname } = useLocation()

  const ipTargetCount = () => {
    return (
      audiences?.reduce((acc, audience) => {
        if (
          audience.type !== 'AUDIENCE_TYPE_VR' &&
          audience.type !== 'AUDIENCE_TYPE_DC' &&
          audience.type !== 'AUDIENCE_TYPE_MAID' &&
          audience.type !== 'AUDIENCE_TYPE_DEVICE'
        ) {
          return acc + (audience.matched || 0)
        }
        return acc
      }, 0) || 0
    )
  }

  const deviceCount = () => {
    return (
      audiences?.reduce((acc, audience) => {
        if (
          audience.type === 'AUDIENCE_TYPE_MAID' ||
          audience.type === 'AUDIENCE_TYPE_DEVICE'
        ) {
          return acc + (audience.matched || 0)
        }
        return acc
      }, 0) || 0
    )
  }

  const neighborCount = () => {
    return (
      audiences?.reduce((acc, audience) => {
        if (audience.type === 'AUDIENCE_TYPE_DC') {
          return acc + (audience.matched || 0)
        }
        return acc
      }, 0) || 0
    )
  }

  // When audiences change:
  // - Recount when audiences changes (for when a target is removed using library table)
  // - Get parent VR jobs for the children attached
  useEffect(() => {
    if (audiences && audiences.length) {
      const tempAudiences = audiences.reduce(
        (acc: Campaignservicev1Audience[], curr: Campaignservicev1Audience) => {
          if (
            curr.productType === 'PRODUCT_TYPE_VENUE_REPLAY' &&
            curr.audienceId &&
            acc.find((aud) => aud.audienceId === curr.audienceId)
          )
            return acc
          return [...acc, curr]
        },
        [],
      )
      Promise.all(
        tempAudiences.reduce(
          (
            acc: Promise<Campaignservicev1Audience>[],
            currentAudience: Campaignservicev1Audience,
          ) => {
            const { audienceId, productType } = currentAudience
            const orgId = currentOrg?.id
            // If there are VR sub jobs in audience list, need to replace them with the parent
            if (
              productType === 'PRODUCT_TYPE_VENUE_REPLAY' &&
              audienceServiceApi &&
              audienceId &&
              orgId
            ) {
              return [
                ...acc,
                audienceServiceApi?.advertisingPlatformServiceGetAudience(
                  audienceId,
                  orgId,
                ),
              ]
            }
            return [...acc, Promise.resolve(currentAudience)]
          },
          [],
        ),
      ).then(setCurrentAudiences)
    } else {
      setCurrentAudiences([])
    }
  }, [audienceServiceApi, audiences, currentOrg?.id])

  const isEmpty = !campaign || !(campaign && Object.keys(campaign).length)

  const isInCampaignCreation =
    pathname.includes('campaigns/create') || pathname.includes('campaigns/edit')

  const isPastFirstStep =
    isInCampaignCreation &&
    (pathname.includes('/audiences') ||
      pathname.includes('/settings') ||
      pathname.includes('/ads'))

  const isAudiencesSkipped =
    isInCampaignCreation &&
    (pathname.includes('/ads') || pathname.includes('/settings')) &&
    !!audiences &&
    audiences.length === 0

  const isCreativesSkipped =
    isInCampaignCreation &&
    pathname.includes('/settings') &&
    !!creatives &&
    creatives.length === 0

  return (
    <div className="ShoppingCart">
      <header className="ShoppingCart__header flex flex-col">
        <h2 className="ShoppingCart__title font-bold">Shopping Cart</h2>
      </header>
      {isEmpty ? (
        <div className="ShoppingCart__empty flex flex-col items-center gap-1 pt-4 pb-2">
          <Fa
            className="text-tint-gray-300 py-2"
            icon="shopping-cart"
            size={6}
          />
          <Text kind="subdued" size="xl" weight="bold">
            Your cart is empty
          </Text>
          <Text kind="subdued" size="m" textAlign="center" lineHeight="none">
            Your campaign details will appear here
          </Text>
        </div>
      ) : (
        <>
          {campaign && <CampaignDetails />}
          {/* Audiences */}
          <AssetHeaderWithSkipBadge skipped={isAudiencesSkipped}>
            Audiences
          </AssetHeaderWithSkipBadge>
          {currentAudiences && currentAudiences.length > 0 && isPastFirstStep && (
            <AssetContainer>
              {currentAudiences.map((aud) => {
                return (
                  aud.id && (
                    <AudienceCard
                      key={aud.id}
                      audience={aud}
                      onRemove={(audIds) => {
                        audIds.forEach((audId) => {
                          handleRemoveAudienceFromCampaign(audId)
                        })
                      }}
                    />
                  )
                )
              })}
            </AssetContainer>
          )}
          {/* Audience total */}
          {currentAudiences && currentAudiences.length > 0 && (
            <div className="ShoppingCart__sub-total border-t-thin border-tint-gray-300 mt-3">
              <span>AUDIENCES SUB-TOTAL</span>
              <div className="flex-col">
                {ipTargetCount() ? (
                  <span>{ipTargetCount().toLocaleString()} IP Addresses</span>
                ) : null}
                {deviceCount() ? (
                  <span>{deviceCount().toLocaleString()} Device IDs</span>
                ) : null}
                {neighborCount() ? (
                  <span>{neighborCount().toLocaleString()} Neighbors</span>
                ) : null}
              </div>
            </div>
          )}
          {!pathname.includes('/audiences') &&
            (pathname.includes('campaigns/create') ||
              pathname.includes('campaigns/edit')) && (
              <AssetHeaderWithSkipBadge skipped={isCreativesSkipped}>
                Creatives
              </AssetHeaderWithSkipBadge>
            )}
          {/* Creatives */}
          {creatives && creatives.length > 0 && (
            <AssetContainer>
              {creatives?.map((creative) => {
                return (
                  <CreativeCard
                    key={creative.id}
                    creative={creative}
                    onRemove={() => {
                      if (creative.id === undefined) return
                      handleRemoveCreativeFromCampaign(creative.id)
                    }}
                  />
                )
              })}
            </AssetContainer>
          )}
          {/* Creative total */}
          {creatives && creatives.length > 0 && (
            <div className="ShoppingCart__sub-total border-t-thin border-tint-gray-300 mt-3">
              <span>CREATIVES SUB-TOTAL</span>
              <span>{creatives.length}</span>
            </div>
          )}
        </>
      )}
    </div>
  )
}
